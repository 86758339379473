<div layout-gt-sm="row" tdMediaToggle="gt-xs" [mediaClasses]="['push-sm']">
  <div flex-gt-sm>
    <mat-card tdMediaToggle="gt-xs" [mediaClasses]="['push']">
      <mat-card-title>
        <span class="mat-headline">Messages</span>
      </mat-card-title>
      <mat-divider></mat-divider>
      <mat-divider></mat-divider>

      <mat-card-content>
        <ng-template tdLoading="message.list">
          <mat-nav-list class="will-load">
            <div class="mat-padding" *ngIf="!messages || messages.length === 0" layout="row"
                 layout-align="center center">
              <h3>No messages to display.</h3>
            </div>
            <ng-template let-last="last" ngFor [ngForOf]="messages" let-m>
              <a mat-list-item (click)="selectMessage(m)" class="link-item"
                 [ngClass]="{'active': (message && m.id===message.id)}">
                <mat-icon mat-list-avatar class="icon">message</mat-icon>
                <h4 mat-line><span class="text-wrap"
                                   [ngClass]="{'unread-message': m.to[0].status==false}">{{m.object}}</span></h4>
                <p mat-line *ngIf="!m.from">Automatic Alert System</p>
                <p mat-line *ngIf="m.from">{{m.from?.name}} ({{m.createdBy?.firstName}} {{m.createdBy?.lastName}})</p>
                <p mat-line>{{m.sentDate | timeAgo}}</p>
              </a>
              <mat-divider *ngIf="!last"></mat-divider>
            </ng-template>
          </mat-nav-list>
        </ng-template>
      </mat-card-content>
      <mat-divider></mat-divider>
      <mat-card-actions layout="row" layout-align="end center">

      </mat-card-actions>
    </mat-card>
    <mat-card tdMediaToggle="gt-xs" [mediaClasses]="['push']" *ngIf="message">
      <mat-card-title>
        Message detail
      </mat-card-title>
      <mat-divider></mat-divider>
      <mat-card-content>
        <mat-list>
          <mat-list-item>
            <mat-icon mat-list-avatar>account_box</mat-icon>
            <h4 mat-line *ngIf="message.from">{{message.from.name}}
              <span>({{message.createdBy?.firstName}} {{message.createdBy?.lastName}})</span></h4>
            <h4 mat-line *ngIf="!message.from">Automatic Alert System</h4>
            <p mat-line>From</p>
          </mat-list-item>
          <mat-divider mat-inset></mat-divider>
          <mat-list-item>
            <mat-icon mat-list-avatar>subject</mat-icon>
            <h4 mat-line><span class="text-wrap">{{message.object}}</span></h4>
            <p mat-line>Object</p>
          </mat-list-item>
          <mat-list-item>
            <mat-icon mat-list-avatar>access_time</mat-icon>
            <h4 mat-line>{{message.sentDate | timeAgo}}</h4>
            <p mat-line>Sent Date</p>
          </mat-list-item>
          <mat-list-item *ngIf="message.to[0].status===true">
            <mat-icon mat-list-avatar>access_time</mat-icon>
            <h4 mat-line>{{message.to[0].readDate | timeAgo}}</h4>
            <p mat-line>Read Date</p>
          </mat-list-item>
        </mat-list>
        <mat-divider></mat-divider>
        <div class="text-wrap" [innerHTML]="message.message"></div>
      </mat-card-content>
      <mat-card-actions align="end">
      </mat-card-actions>
    </mat-card>
  </div>
</div>

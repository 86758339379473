import { Company } from './../../models/company';
import { ConfigurationDialogComponent } from './../configuration-dialog/configuration-dialog.component';
import { environment } from './../../../environments/environment';
import { CompareKey } from './../../models/chart-data';
import { StatisticsService } from 'app/services/statistics.service';
import { SnackService } from './../../services/snack.service';
import { Component, Input, OnChanges } from '@angular/core';
import { ChartData } from 'app/models/chart-data';
import {TdLoadingService} from '@covalent/core/loading';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'compare-chart',
    templateUrl: './compare-chart.component.html',
    styleUrls: ['./compare-chart.component.css']
})
export class CompareChartComponent implements OnChanges {
    @Input() company: Company;
    view: any[] = [700, 400];

    // options
    showXAxis = true;
    showYAxis = true;
    gradient = false;
    showLegend = true;
    showXAxisLabel = true;
    xAxisLabel = 'Country';
    showYAxisLabel = true;
    yAxisLabel = 'Population';

    colorScheme = {
        domain: environment.color_scheme
    };
    colorScheme2 = {
      domain: environment.color_scheme_2
    };
    legend: any[];

    comparesData: ChartData[];
    compareKeys: CompareKey[];
    constructor(
        private statisticsService: StatisticsService,
        private snackService: SnackService,
        private _loadingService: TdLoadingService,
        private mdDialog: MatDialog
    ) {}


    ngOnChanges(changes: any) {
        this.compareKeys = null;
        this.comparesData = null;
    }

    listCompares(keys: CompareKey[]) {
        this._loadingService.register('load.compares');
        this.statisticsService.getCompares(this.compareKeys).subscribe(
            res => {
                this.comparesData = res;
                this.legend = [];
                res.forEach(r => r.series.forEach(s => (s.value = !s.value ? 0 : s.value)));
                res[0].series.forEach((s, i) => {
                    this.legend.push({
                        name: s.name,
                        color: this.colorScheme2.domain[i]
                    });
                });
            },
            err => this.snackService.notifyErr(err),
            () => this._loadingService.resolve('load.compares')
        );
    }

    openDialog() {
        this.mdDialog
            .open(ConfigurationDialogComponent, {
                data: this.company,
                width: '70%'
            })
            .afterClosed()
            .subscribe(
                res => {
                    if (res) {
                        this.compareKeys = res;
                        this.listCompares(res);
                    }
                },
                err => this.snackService.notifyErr(err)
            );
    }
}

const GetAppVersion = '1.2.5';
export const environment = {
  production: true,
  api_endpoint: 'https://prod-appo-acimit-be.azurewebsites.net/api/',
  // base_url: 'https://prod-appo-acimit-fe.azurewebsites.net',
  base_url: 'https://tool.green-label.it',
  version: GetAppVersion + '-az', // '1.0.2-remote',
  auth: {
    issuer: 'https://login.microsoftonline.com/76e3e3ff-fce0-45ec-a946-bc44d69a9b7e/v2.0',
    client: '1fb25107-5a00-4cce-b9d6-f7c37c85864c',
    // secret: '3ZWNUrmc.RjFHy76i2Q4F.we-aIEtD_ehS',
    scope: 'openid email profile api://1fb25107-5a00-4cce-b9d6-f7c37c85864c/RINAappoACIMIT'
  },
  cookie_domain: 'prod-appo-acimit-fe.azurewebsites.net',
  pop_timeout: 60000, // 900000, //15 minutes
  color_scheme: [
    '#e53935',
    '#d81b60',
    '#8e24aa',
    '#3949ab',
    '#039be5',
    '#00897b',
    '#7cb342',
    '#fdd835',
    '#fb8c00',
    '#f4511e',
    '#8d6e63',
    '#78909c'
  ],
  color_scheme_2: [
    '#71f781',
    '#cdc9ff',
    '#8decef',
    '#f9d9a4',
    '#f4de9c',
    '#7ce3ea',
    '#f2817b',
    '#f9bdf7',
    '#cab1f9',
    '#fcc7e9',
    '#fcdfb0',
    '#c9f970',
    '#ef7aaf',
    '#8abafc',
    '#d0abfc',
    '#a07bfc',
    '#f9bbdd',
    '#6df2ad',
    '#7381dd',
    '#f9b6b9',
    '#9ef7f1',
    '#b7b4f7',
    '#77efa1',
    '#bbccf9',
    '#fff1c9',
    '#96adff',
    '#f3abfc'
  ]
};

import { TitleService } from 'app/services/title.service';

import { SnackService } from 'app/services/snack.service';
import { TrashService } from '../../services/trash.service';
import { Component, OnInit } from '@angular/core';
import { Trash } from 'app/models/trash';
import {TdLoadingService} from '@covalent/core/loading';
import {TdMediaService} from '@covalent/core/media';
import {TdDialogService} from '@covalent/core/dialogs';

@Component({
  selector: 'app-trash',
  templateUrl: './trash.component.html',
  styleUrls: ['./trash.component.css']
})
export class TrashComponent implements OnInit {
  trashList: Trash[];
  trashType = 'company';

  constructor(
    private trashService: TrashService,
    private titleService: TitleService,
    private snackService: SnackService,
    private loadingService: TdLoadingService,
    public media: TdMediaService,
    private _dialogService: TdDialogService
  ) {
    this.titleService.setTitle('Trash');
  }

  ngOnInit() {
    this.loadElements();
  }

  loadElements() {
    this.loadingService.register('loading.trash');
    this.trashService
      .findAll(this.trashType)
      .subscribe(
        res => (this.trashList = res),
        err => this.snackService.notifyErr(err),
        () => this.loadingService.resolve('loading.trash')
      );
  }

  emptyTrash() {
    this._dialogService
      .openConfirm({ message: 'Are you sure you want to empty trash?' })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.loadingService.register('loading.trash');
          this.trashService.empty().subscribe(
            res => this.snackService.notifyInfo(res),
            err => this.snackService.notifyErr(err),
            () => {
              this.loadingService.resolve('loading.trash');
              this.loadElements();
            }
          );
        }
      });
  }

  restore(t: Trash) {
    this.loadingService.register('loading.trash');
    this.trashService.restoreTrash(t).subscribe(
      res => {
        this.trashList = this.trashList.filter(tt => tt.id !== res);
        this.snackService.notifyInfo('Element restored');
      },
      err => {
        this.snackService.notifyErr(err);
      },
      () => {
        this.loadingService.resolve('loading.trash');
      }
    );
  }
  delete(t: Trash) {
    this._dialogService
      .openConfirm({
        message: 'Are you sure you want to delete forever this element?'
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.loadingService.register('loading.trash');
          this.trashService.delete(t).subscribe(
            res => {
              this.trashList = this.trashList.filter(tt => tt.id !== res);
              this.snackService.notifyInfo('Element deleted');
            },
            err => {
              this.snackService.notifyErr(err);
            },
            () => {
              this.loadingService.resolve('loading.trash');
            }
          );
        }
      });
  }
}

import {CompanyService} from './../../services/company.service';
import {Company} from './../../models/company';
import {CountryMap, Labels, TrendCluster, TrendCompanyCluster} from './../../models/chart-data';
import {SecureStorageService} from './../../services/secure-storage.service';
import {User} from './../../models/user';
import {environment} from './../../../environments/environment';
import {SnackService} from './../../services/snack.service';
import {Component, OnInit} from '@angular/core';
import {StatisticsService} from 'app/services/statistics.service';
import {TitleService} from 'app/services/title.service';
import {TdLoadingService} from '@covalent/core/loading';

import {MatSelectChange} from '@angular/material/select';
import { filterLabels } from 'app/shared/utils';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  colorScheme = {
    domain: environment.color_scheme
  };
  colorScheme2 = {
    domain: environment.color_scheme_2
  };

  user: User;
  trendDataForCompaniesSet: any[];
  trendCluster: TrendCluster[];
  trendCompanyCluster: TrendCompanyCluster[];
  countryMapData: CountryMap[];
  labelsData: Labels[];
  filteredLabelsData: Labels[];
  company: Company;
  companies: Company[];
  isAdministrator = false;

  constructor(
    private statisticsService: StatisticsService,
    private snackService: SnackService,
    private secureStorage: SecureStorageService,
    private _loadingService: TdLoadingService,
    // private matDialog: MatDialog,
    private companyService: CompanyService,
    private titleService: TitleService
  ) {
    this.titleService.setTitle('Dashboard');
  }

  ngOnInit() {
    this.user = JSON.parse(this.secureStorage.retrieve('user'));
    this.isAdministrator = this.user.role === 'administrator';
    if (this.isAdministrator) {
      this._loadingService.register('loading.companies');
      this.companyService
        .findAll()
        .subscribe(
          res => (this.companies = res),
          err => this.snackService.notifyErr(err),
          () => this._loadingService.resolve('loading.companies')
        );
    }
    this.company = this.user.company;
    this.loadData();
  }

  selectCompany(event: MatSelectChange) {
    this.loadData();
  }

  loadData() {
    this.loadLabelTrendForCompaniesSet([this.company.id]);
    this.loadCountryMap(this.company.id);
    this.listLabels(this.company.id);
  }

  filterLabels(code: string) {
    this.filteredLabelsData = filterLabels(code, this.labelsData);
  }

  listLabels(id: string) {
    this._loadingService.register('load.labels');
    this.statisticsService.getLabels(id).subscribe(
      res => {
        this.labelsData = res;
        this.filteredLabelsData = res;
      },
      err => this.snackService.notifyErr(err),
      () => this._loadingService.resolve('load.labels')
    );
  }

  loadLabelTrendForCompaniesSet(ids: string[]) {
    if (ids && ids.length > 0) {

      this._loadingService.register('load.trend.chart');
      this.statisticsService.getTrendClusterForCompaniesSet(ids).subscribe(
        res => {
          this.trendCompanyCluster = res;
          this.trendCluster = [];
          res.forEach(r => {
            const t: TrendCluster = new TrendCluster();
            t.id = r.id.year;
            t.count = r.count;
            t.month = r.month;
            this.trendCluster.push(t);
          });
        },
        err => this.snackService.notifyErr(err),
        () => this._loadingService.resolve('load.trend.chart')
      );
    }
  }

  loadCountryMap(id: string) {
    this._loadingService.register('load.country.map');
    this.statisticsService.getCountryMap(id).subscribe(
      res => {
        this.countryMapData = res;
      },
      err => this.snackService.notifyErr(err),
      () => this._loadingService.resolve('load.country.map')
    );
  }
}

<div layout-gt-sm="row" tdMediaToggle="gt-xs" [mediaClasses]="['push-sm']">
  <div flex-gt-sm="50">
    <mat-card tdMediaToggle="gt-xs" [mediaClasses]="['push']">
      <mat-card-title>
        <span class="mat-headline">Machine configuration for
          <span *ngIf="machine">{{machine.commercialName}}</span>
        </span>
      </mat-card-title>
      <mat-divider></mat-divider>
      <mat-card-content>
        <ng-template tdLoading="machine.load">
          <div *ngIf="machine">
            <mat-list class="will-load" dense>
              <h3 mat-subheader>Machine Details</h3>
              <mat-list-item>
                {{machine.commercialName}}
              </mat-list-item>
              <mat-list-item>
                {{machine.description}}
              </mat-list-item>
              <mat-divider></mat-divider>
              <h3 mat-subheader>Configuration available</h3>
              <mat-list-item *ngFor="let c of configurationsPage" layout-align="row" class="link-item" [ngClass]="{'active': c===configuration}"
                (click)="loadConfiguration(c)">
                <mat-icon mat-list-avatar class="icon bgc-amber-800">extension</mat-icon>
                <h3 mat-line> {{c.revision}} {{c.year}} </h3>
                <p mat-line> {{c.creationDate | timeAgo}} </p>
                <p mat-line>{{c.params.processDescriptor.materialProcessed}}</p>
                <span flex></span>
                <span hide-xs hide-sm hide-md flex-gt-xs="70" flex-xs="60" layout-gt-xs="row">
                  <div class="mat-caption mat-caption-right tc-grey-500" flex>
                    kg {{ c.footprint.value | scientificNotation }} of CO
                    <sub>2</sub> eq/kg mp
                  </div>
                </span>
                <span flex>
                  <a *ngIf="validateUrl(c.url)" rel=noopener mat-button target="_blank" matTooltip="Open url" matTooltipPosition="below" href="{{c.url}}">
                    <mat-icon>open_in_new</mat-icon>
                  </a>
                </span>
              </mat-list-item>
              <mat-divider></mat-divider>
            </mat-list>
            <td-paging-bar #pagingConfigurations pageLinkCount="5" [initialPage]="1" [firstLast]="true" [pageSize]="pageSizeHigh" [total]="machine.configuration.length"
              (change)="changePageConfigurations($event)">
            </td-paging-bar>
          </div>
        </ng-template>
      </mat-card-content>
      <mat-divider></mat-divider>
      <mat-card-actions layout-align="end">
        <a mat-button color="accent" class="text-upper" [routerLink]="['/machines', machine.id, 'conf']" matTooltip="Create new configuration"
          matTooltipPosition="below">New</a>
        <a mat-button color="accent" class="text-upper" (click)="duplicateConfiguration()" *ngIf="configuration" matTooltip="Duplicate selected configuration"
          matTooltipPosition="below">Duplicate</a>
        <a mat-button color="warn" class="text-upper" (click)="deleteConfiguration()" *ngIf="configuration" matTooltip="Delete selected configuration"
          matTooltipPosition="below">Delete</a>
        <a mat-button color="warn" class="text-upper" (click)="deleteMachine()" matTooltip="Delete the machine" matTooltipPosition="below">Delete Machine</a>
      </mat-card-actions>
    </mat-card>


    <mat-card tdMediaToggle="gt-xs" [mediaClasses]="['push']" *ngIf="configuration">
      <mat-card-title>
        <span class="mat-headline">Installation detail for
          <span *ngIf="configuration">{{configuration.revision}}.{{configuration.year}}</span>
        </span>
      </mat-card-title>
      <mat-divider></mat-divider>
      <mat-card-content>
        <div *ngIf="installations">
          <mat-list class="will-load installation-list">
            <ng-template let-item let-last="last" ngFor [ngForOf]="installationsPage">
              <mat-list-item layout-align="row" class="link-item" [ngClass]="{'active': item===installation}" (click)="selectInstallation(item)">
                <img mat-list-avatar class="bgc-amber-800" alt="{{item.location.code}} flag" src="assets/images/flags/{{item.location.code | lowercase}}.png" />
                <h3 mat-line> {{item.location.name}} </h3>
                <p mat-line> {{item.generationDate | timeAgo}} </p>
                <span flex></span>
                <span hide-xs hide-sm hide-md flex-gt-xs="70" flex-xs="60" layout-gt-xs="row">
                  <div class="mat-caption tc-grey-500" flex>kg {{ item.footprint.value | scientificNotation }} of CO
                    <sub>2</sub> eq/kg mp</div>
                </span>
                <span flex>
                  <a mat-button matTooltip="Open Green Label" matTooltipPosition="below" [routerLink]="['/machines',machine.id, 'green-label', item.targaCode]">
                    <mat-icon>open_in_new</mat-icon>
                  </a>
                </span>
              </mat-list-item>
              <mat-divider *ngIf="!last" mat-inset></mat-divider>
            </ng-template>
          </mat-list>
          <td-paging-bar #pagingInstallations pageLinkCount="5" [initialPage]="1" [firstLast]="true" [pageSize]="pageSizeHigh" [total]="installations.length"
            (change)="changePageInstallations($event)">
          </td-paging-bar>
        </div>
      </mat-card-content>
      <mat-divider></mat-divider>
      <mat-card-actions layout-align="end">
        <a mat-button color="accent" class="text-upper" (click)="newInstallation(machine.id,configuration.revision,configuration.year)">New Installation</a>
        <a mat-button color="warn" class="text-upper" *ngIf="installation" (click)="deleteInstallation()">Delete Installation</a>
      </mat-card-actions>
    </mat-card>
  </div>

  <div flex-gt-sm="50">
    <mat-card tdMediaToggle="gt-xs" [mediaClasses]="['push']" *ngIf="configuration">
      <mat-card-title>
        <span class="mat-headline">Configuration detail for
          <span *ngIf="configuration">{{configuration.revision}}.{{configuration.year}}</span>
        </span>
      </mat-card-title>
      <mat-divider></mat-divider>
      <mat-card-content>

        <div *ngIf="configurationParams">
          <mat-list dense>

            <ng-template let-cp let-cp_last="last" let-cp_index="index" ngFor [ngForOf]="configurationParamsPage">
              <mat-list-item>
                <h3 matLine>{{cp.name | async}}</h3>
                <p matLine *ngIf="cp.type">{{cp.type}}</p>
                <span flex="70" layout-gt-xs="row">
                  <div class="mat-caption mat-caption-right tc-grey-500" flex>{{cp.value}}</div>
                  <div class="mat-caption tc-grey-500" flex>{{cp.unit | async}}</div>
                </span>
                <!-- <span> -->
                <button *ngIf="cp.key" mat-icon-button color="primary" (click)="downloadFile(cp.key)">
                  <mat-icon class="mat-24">file_download</mat-icon>
                </button>
              </mat-list-item>
              <mat-divider *ngIf="!cp_last" mat-inset></mat-divider>
            </ng-template>
          </mat-list>
          <div layout="row" flex>
            <button mat-icon-button color="accent" (click)="downloadFile('OPERATIVE_INSTRUCTION')">
              <mat-icon class="mat-24">file_download</mat-icon>
              Operative Instruction
            </button>
            <span flex></span>
            <td-paging-bar #pagingCP pageLinkCount="5" [initialPage]="1" [firstLast]="true" [pageSize]="pageSize" [total]="configurationParams.length"
              (change)="changePageCP($event)">
            </td-paging-bar>
          </div>
        </div>

      </mat-card-content>
    </mat-card>
    <mat-card tdMediaToggle="gt-xs" [mediaClasses]="['push']" *ngIf="installation">
      <mat-card-title>
        <span class="mat-headline">Footprint Contribute</span>
      </mat-card-title>
      <mat-divider></mat-divider>
      <mat-card-content>
        <div style="height:250px;">
          <label-contribute-chart [footprint]="installation.footprint"></label-contribute-chart>
        </div>
      </mat-card-content>
    </mat-card>

  </div>
</div>

import { MessageService } from './../../services/message.service';
import { SnackService } from './../../services/snack.service';
import { Component, OnInit } from '@angular/core';
import { Company } from './../../models/company';
import { CompanyService } from './../../services/company.service';
import {MessageExchange} from '../../models';
import {TdLoadingService} from '@covalent/core/loading';
import {MatCheckboxChange} from '@angular/material/checkbox';

@Component({
  selector: 'app-message-form',
  templateUrl: './message-form.component.html',
  styleUrls: ['./message-form.component.css']
})
export class MessageFormComponent implements OnInit {

  checkAllMachine: boolean;
  companies: Company[];
  filteredCompanies: Company[] = [];
  selectedCompanies: Company[] = [];
  message: MessageExchange;
  constructor(private _loadingService: TdLoadingService, private companyService: CompanyService,
    private snackService: SnackService, private messageService: MessageService) { }







  ngOnInit(): void {
    this.message = new MessageExchange();
    this._loadingService.register('loading.companies');
    this.companyService.findAll().subscribe(
      res => {
        this.companies = res;
        this.filteredCompanies = res;
      },
      err => this.snackService.notifyErr(err),
      () => this._loadingService.resolve('loading.companies')
    )

  }

  filterCompanies(value: string): void {
    this.filteredCompanies = this.companies.filter((obj: any) => {
      if (value) {
        return obj.city.toLowerCase().indexOf(value.toLowerCase()) > -1;
      } else {
        return false;
      }
    });
  }

  deb(value: any) {
    if (!value || value.length === 0) {
      this.filteredCompanies = this.companies;
    } else {
      this.filteredCompanies = this.companies.filter((obj: any) => {
        if (value) {
          return obj.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
        } else {
          return false;
        }
      });
    }
  }

  checkAll(evt: MatCheckboxChange) {
    this.filteredCompanies.forEach(c => {
      c['msg'] = evt.checked;
    });

  }

  send() {
    this.message.to = this.companies.filter(c => c['msg']).map(c => c.id);
    this.messageService.send(this.message).subscribe(
      res => {
        this.snackService.notifyInfo('Message Sent');
      },
      err => this.snackService.notifyErr(err),

    )

  }
}

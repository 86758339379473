<mat-card flex tdMediaToggle="gt-xs" [mediaClasses]="['push']">
  <mat-card-content>
    <div class="form-group" layout="row" flex>
      <ng-template flex="50" *ngIf="user.role == 'administrator'" tdLoading="company.loading">
        <mat-form-field flex="50">
          <mat-select #companySelect placeholder="Select the company" (selectionChange)="selectCompany($event)" [(ngModel)]="company" name="company">
            <mat-option *ngFor="let c of companyList" [value]="c">
              {{c.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-template>

      <ng-template flex="50" tdLoading="machine.loading">
        <mat-form-field flex="50">
          <mat-select #machineSelect placeholder="Select the machine" (selectionChange)="selectMachine($event)" [(ngModel)]="machineId" name="machine">
            <mat-option *ngFor="let m of machineList" [value]="m.id">
              {{m.commercialName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-template>
    </div>
  </mat-card-content>
</mat-card>

<ng-template tdLoading="dashboard.machine.load">
  <div *ngIf="machine">
    <machine-dashboard [machine]="machine" [revision]="revision" [year]="year"></machine-dashboard>
  </div>
</ng-template>

<a mat-fab class="mat-fab-position-bottom-right position-bottom-right" matTooltip="Create new machine" matTooltipPosition="above" color="accent" [routerLink]="['/machines/add']">
  <mat-icon>add</mat-icon>
</a>

<div layout-gt-sm="row" tdMediaToggle="gt-xs" [mediaClasses]="['push-sm']">
  <div flex-gt-sm>
    <mat-card tdMediaToggle="gt-xs" [mediaClasses]="['push']">
      <mat-card-title>
        <span class="mat-headline">Maintenance</span>
      </mat-card-title>
      <mat-divider></mat-divider>

      <mat-card-content>

        <div *ngIf="batch">
          {{batch.status}}
        </div>
        <mat-divider *ngIf="batch"></mat-divider>

        <h3>Global</h3>
        <div>
          <h4>Rebuild all generated labels</h4>
          <button [disabled]="running" color="warn" mat-flat mat-button *ngIf="isAdministrator" (click)="rebuildLabels()">Rebuild</button>
        </div>
        <div>
          <h4>Fill empty configurations with EU Installation</h4>
          <button [disabled]="running" color="warn" mat-flat mat-button *ngIf="isAdministrator" (click)="fillInstallation()">Fill Installation</button>
        </div>

        <mat-divider></mat-divider>
        <h3>By Company</h3>
        <div>
          <h4>Rebuild labels for selected company</h4>
          <ng-template flex="50" tdLoading="loading.companies">
            <mat-form-field>
              <mat-select #companySelect placeholder="Select the company" [(ngModel)]="company" name="company">
                <mat-option *ngFor="let c of companies" [value]="c">
                  {{c.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-template>
          <button [disabled]="running" color="warn" mat-flat mat-button (click)="rebuildLabelsByCompany()">Rebuild</button>
        </div>

        <mat-card>
          <mat-progress-bar color="primary" mode="indeterminate" *ngIf="running">
          </mat-progress-bar>
        </mat-card>
      </mat-card-content>

    </mat-card>

  </div>
</div>

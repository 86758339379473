<h1 mat-dialog-title>Select Companies</h1>

<mat-dialog-content>
  <ng-template tdLoading="loading.company">
    <mat-card>

      <mat-card-content *ngIf="companyList">
        {{selectedCount}} / 5 Company selected
      </mat-card-content>

    </mat-card>
    <mat-list dense class="will-load make-scrollable">
      <ng-template let-last="last" ngFor [ngForOf]="companyList" let-c>
        <mat-list-item>
          <mat-checkbox [(ngModel)]="c.selected" (change)="switchCompany($event,c)" value=""
                        color="primary">{{c.name}}</mat-checkbox>
        </mat-list-item>

        <mat-divider *ngIf="!last"></mat-divider>
      </ng-template>
    </mat-list>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button color="accent" class="text-upper" mat-dialog-close="" (click)="close()">Close</button>
  <button mat-button color="accent" class="text-upper" (click)="select()">Select</button>
</mat-dialog-actions>

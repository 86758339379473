import { SnackService } from './../../../services/snack.service';
import { CompanyService } from 'app/services/company.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Company } from 'app/models';
import { ActivatedRoute } from '@angular/router';
import {TdMediaService} from '@covalent/core/media';

@Component({
    selector: 'company-form',
    templateUrl: './company-form.component.html',
    styleUrls: ['./company-form.component.scss']
})
export class CompanyFormComponent implements OnInit, AfterViewInit {
    company: Company;
    logo: File;
    action: string;

    constructor(
        private companyService: CompanyService,
        private _route: ActivatedRoute,
        public media: TdMediaService,
        private snackService: SnackService
    ) {}

    ngOnInit() {
        this.company = new Company();
        this._route.url.subscribe((url: any) => {
            this.action = url.length > 1 ? url[1].path : 'add';
        });
        this._route.params.subscribe((params: { id: string }) => {
            if (params.id) {
                const companyId: string = params.id;
                this.companyService.findById(companyId).subscribe((company: Company) => {
                    this.company = company;
                });
            }
        });
    }

    goBack(): void {
        window.history.back();
    }

    ngAfterViewInit(): void {
        // broadcast to all listener observables when loading the page
        this.media.broadcast();
    }

    save() {
        this.companyService.save(this.company, this.logo).subscribe(
            res => {
                this.company = res;
                this.snackService.notifyInfo('Company created');
            },
            err => this.snackService.notifyErr(err),
            () => this.goBack()
        );
    }

    uploadEvent(file: File): void {
        this.logo = file;
    }
}

import { SnackService } from 'app/services/snack.service';
import { MachineService } from './../../services/machine.service';
import { Machine, Configuration } from 'app/models/machine';
import { CompareKey } from './../../models/chart-data';
import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TdLoadingService} from '@covalent/core/loading';
import {MatCheckboxChange} from '@angular/material/checkbox';

@Component({
  selector: 'app-configuration-dialog',
  templateUrl: './configuration-dialog.component.html',
  styleUrls: ['./configuration-dialog.component.css']
})
export class ConfigurationDialogComponent implements OnInit {

  machines: Machine[];
  machine: Machine;
  count = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public company: any,
    private machineService: MachineService,
    private snackService: SnackService,
    private _loadingService: TdLoadingService,
    // private secureStorage: SecureStorageService,
    private dialogRef: MatDialogRef<CompareKey[]>) { }

  ngOnInit() {
    this._loadingService.register('loading.machines');
    this.machineService.getAllMachineByCompany(this.company.id).subscribe(
      res => this.machines = res,
      err => this.snackService.notifyErr(err),
      () => this._loadingService.resolve('loading.machines')
    );
  }

  checkClick(event: MatCheckboxChange, c: Configuration) {
    if (event.checked) {
      if (this.count === 3) {
        c['selected'] = false;
        event.source.toggle();
      } else {
        this.count++;
      }
    } else {
      this.count--;
    }
  }

  selectConfigurations() {
    const compareKeys: CompareKey[] = [];
    this.machines.forEach(m => {
      m.configuration.filter(c => c['selected']).forEach(c => {
        compareKeys.push({
          'id': m.id,
          'rev': c.revision,
          'year': c.year
        })
      });
    });
    this.dialogRef.close(compareKeys);
  }

  close() {
    this.dialogRef.close();
  }


}

import { Router, ActivatedRoute } from '@angular/router';
import { SnackService } from './../../../services/snack.service';
import { AgendaService } from 'app/services/agenda.service';
import { Component, OnInit } from '@angular/core';
import { Agenda } from 'app/models/agenda';
import * as moment from 'moment';
@Component({
  selector: 'app-agenda-form',
  templateUrl: './agenda-form.component.html',
  styleUrls: ['./agenda-form.component.css']
})
export class AgendaFormComponent implements OnInit {
  event: Agenda;
  startDate: Date;
  startTime: string;
  endDate: Date;
  endTime: string;
  action: string;

  times: string[] = [
    '00:00',
    '00:30',
    '01:00',
    '01:30',
    '02:00',
    '02:30',
    '03:00',
    '03:30',
    '04:00',
    '04:30',
    '05:00',
    '05:30',
    '06:00',
    '06:30',
    '07:00',
    '07:30',
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
    '20:30',
    '21:00',
    '21:30',
    '22:00',
    '22:30',
    '23:00',
    '23:30'
  ];

  constructor(
    private snackService: SnackService,
    private agendaService: AgendaService,
    private _router: Router,
    private _route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.event = new Agenda();
    this._route.url.subscribe((url: any) => {
      this.action = url.length > 1 ? url[1].path : 'add';
    });
    this._route.params.subscribe((params: { id: string }) => {
      if (params.id) {
        const eventId: string = params.id;
        this.agendaService.findById(eventId).subscribe((event: Agenda) => {
          this.event = event;
          this.startTime =
            this.paddy(this.event.start.getHours(), 2) +
            ':' +
            this.paddy(this.event.start.getMinutes(), 2);
          this.endTime =
            this.paddy(this.event.end.getHours(), 2) +
            ':' +
            this.paddy(this.event.end.getMinutes(), 2);
        });
      }
    });
  }

  private paddy(n, p, c?) {
    const pad_char = typeof c !== 'undefined' ? c : '0';
    const pad = new Array(1 + p).join(pad_char);
    return (pad + n).slice(-pad.length);
  }

  save() {
    this.event.start = moment.utc(this.event.start).toDate();
    // tslint:disable-next-line:radix
    const st: number[] = this.startTime.split(':').map(v => Number.parseInt(v));
    this.event.start.setHours(st[0], st[1]);
    this.event.end = moment.utc(this.event.end).toDate();
    // tslint:disable-next-line:radix
    const et: number[] = this.endTime.split(':').map(v => Number.parseInt(v));
    this.event.end.setHours(et[0], et[1]);
    this.agendaService.save(this.event).subscribe(
      res => (this.event = res),
      err => this.snackService.notifyErr(err),
      () => {
        this._router.navigate(['/adm/agenda']);
      }
    );
  }

  delete() {
    this.agendaService
      .delete(this.event)
      .subscribe(
        res => console.log(res),
        err => this.snackService.notifyErr(err),
        () => this._router.navigate(['/adm/agenda'])
      );
  }
}

import {environment} from './../../environments/environment';
import {ChartData, ChartSeries, CompareKey, CountryMap, Labels, TrendCompanyCluster} from './../models/chart-data';
import {Observable} from 'rxjs';
import {BaseService} from './base-service';
import {Injectable} from '@angular/core';
import {TrendCluster} from 'app/models';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class StatisticsService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  getLabelTrend(): Observable<ChartData[]> {
    const pageUrl: string = environment.api_endpoint + 'statistics/label-trend/';
    return this.http
      .get<ChartData[]>(pageUrl)
      ;
  }

  getLabelTrendForCompaniesSet(companiesId: string[]): Observable<ChartData[]> {
    const pageUrl: string =
      environment.api_endpoint + 'statistics/label-trend-company?ids=' + companiesId.join(',');
    return this.http
      .get<ChartData[]>(pageUrl)
      ;
  }

  getLabelForCompanies(): Observable<ChartSeries[]> {
    const pageUrl: string = environment.api_endpoint + 'statistics/company-labels/';
    return this.http
      .get<ChartSeries[]>(pageUrl)
      ;
  }

  getNumbers(): Observable<ChartSeries[]> {
    const pageUrl: string = environment.api_endpoint + 'statistics/numbers/';
    return this.http
      .get<ChartSeries[]>(pageUrl)
      ;
  }

  getCountryMap(companyId: string = null): Observable<CountryMap[]> {
    const pageUrl: string =
      environment.api_endpoint + 'statistics/country-map/' + (companyId ? '?id=' + companyId : '');
    return this.http
      .get<CountryMap[]>(pageUrl)
      ;
  }

  getLabels(companyId: string = null): Observable<Labels[]> {
    const pageUrl: string =
      environment.api_endpoint + 'statistics/labels/' + (companyId ? '?id=' + companyId : '');
    return this.http
      .get<Labels[]>(pageUrl)
      ;
  }

  getCompares(compareKey: CompareKey[]): Observable<ChartData[]> {
    const pageUrl: string = environment.api_endpoint + 'statistics/compare';
    return this.http
      .post<ChartData[]>(pageUrl, compareKey)
      ;
  }

  getTrendCluster(): Observable<TrendCluster[]> {
    const pageUrl: string = environment.api_endpoint + 'statistics/label-trend-cluster';
    return this.http
      .get<TrendCluster[]>(pageUrl);
  }

  getTrendClusterForCompaniesSet(companiesId: string[]): Observable<TrendCompanyCluster[]> {
    const pageUrl: string = environment.api_endpoint + 'statistics/label-trend-cluster-company?ids=' + companiesId.join(',');

    return this.http
      .get<TrendCompanyCluster[]>(pageUrl);
  }
}


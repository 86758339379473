<mat-card tdMediaToggle="gt-xs" [mediaClasses]="['push']">
  <mat-card-title>{{title}}</mat-card-title>
  <mat-card-subtitle><span class="banner">The data should be related to the <span *ngIf="!configuration.year">reference year</span><span *ngIf="configuration.year">year {{configuration.year}}</span></span></mat-card-subtitle>
  <mat-divider></mat-divider>
  <mat-card-content class="push-bottom-none">
    <td-steps #steps mode="horizontal" (stepChange)="changeStep($event)" >

      <td-step #step1 label="Machine Data" sublabel="Define machine basic information" active="true" [state]="stepState1"
        >
        <mat-card>
          <ng-template tdLoading="validateConfigurationData" tdLoadingStrategy="overlay" tdLoadingColor="warn">
            <form #machineForm="ngForm">
              <div layout="row" layout-margin>
                <mat-form-field flex="50" color="accent">
                  <input matInput [disabled]="!mode" pattern="^[\w]*(([\s\w\.\,\-]*[\w])*)$" name="commercialName" type="text" required [(ngModel)]="machine.commercialName" placeholder="Commercial Name">
                </mat-form-field>
                <mat-form-field flex="50">
                  <input matInput required placeholder="Location" name="location" [matAutocomplete]="tdAutoState" [(ngModel)]="installation.location"
                    #modelDir="ngModel" (ngModelChange)="this.countries = filterStates(installation.location)" (blur)="validateLocation()">
                    <mat-error *ngIf="installation['valid']">Location must be selected in the list</mat-error>
                </mat-form-field>
                <mat-autocomplete #tdAutoState="matAutocomplete" [displayWith]="locationDisplayFn">
                  <mat-option *ngFor="let state of countries" [value]="state">
                    <span>{{ state.name }}</span>
                  </mat-option>
                </mat-autocomplete>
              </div>
              <div layout="row" layout-margin>
                <mat-form-field flex="50">
                  <input matInput required #revision  name="revision" type="text" [(ngModel)]="configuration.revision" placeholder="Revision"
                        (ngModelChange)="validateRevision()" (load)="validateRevision()" (blur)="validateRevision()" >
                  <mat-error *ngIf="configuration['notUnique']">The combination Revision and Year must be unique</mat-error>
                  <mat-error *ngIf="configuration['notValid']">The Revision must contains only alfa-numeric characters</mat-error>
                </mat-form-field>
                <mat-form-field flex="50">
                  <input matInput required #year name="year" type="number" min="{{currentYear-1}}" max="{{currentYear}}" [(ngModel)]="configuration.year" placeholder="Year"
                         (ngModelChange)="validateRevision()" (load)="validateRevision()" (blur)="validateRevision()">
                  <mat-hint align="end">{{currentYear-1}}-{{currentYear}}</mat-hint>
                  <mat-error *ngIf="configuration['notUnique']">The combination Revision and Year must be unique</mat-error>
                  <mat-error>Year must be in {{currentYear-1}}-{{currentYear}}</mat-error>
                </mat-form-field>
              </div>
              <div layout="row" layout-margin>
                <mat-form-field flex>
                  <textarea matInput [disabled]="!mode" name="description" maxlength="200" [(ngModel)]="machine.description" required
                            placeholder="Description"></textarea>
                  <mat-hint align="end">{{machine.description ? machine.description.length:'0'}}/200</mat-hint>
                </mat-form-field>
              </div>
              <div layout="row" layout-margin>
                <mat-form-field flex>
                  <input matInput name="url" pattern='^(http|https):\/\/[^ "]+$' type="url" [(ngModel)]="configuration.url" placeholder="Url">
                  <mat-hint align="end">Enter a valid url with http or https prefix</mat-hint>
                  <mat-error>Url is not valid</mat-error>
                </mat-form-field>
              </div>
            </form>
          </ng-template>
        </mat-card>
      </td-step>

      <td-step #step2 label="Process Data" sublabel="Define machine process information">
        <td-expansion-panel expand="true" (collapsed)="collapsedEvent(processDescriptorForm)">
          <ng-template td-expansion-panel-label>
            <mat-icon class="tc-red-700" *ngIf="!processDescriptorForm.valid">error_outline</mat-icon>
            <span [ngClass]="!processDescriptorForm.valid?'tc-red-700':''">Process Descriptor</span>
          </ng-template>
          <mat-card>
            <form #processDescriptorForm="ngForm">
              <div layout="row" layout-margin>
                <label>Work Cycle</label>
              </div>
              <div layout="row" layout-margin>
                <mat-form-field *ngIf="productCodes" style="width: 100%">
                  <mat-select matInput [style.width]="'100%'" flex="100" required placeholder="Work cycle" [(ngModel)]="cap" name="cap" (selectionChange)="loadSubs($event)">
                    <mat-option *ngFor="let caps of productCodes.caps" [value]="caps">
                      <span title="{{caps.name}}">{{caps.name}}</span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div layout="row" layout-margin>
                <mat-form-field *ngIf="capSubs" style="width: 100%">
                  <mat-select required [style.width]="'100%'" flex="100" placeholder="" [(ngModel)]="sub" name="sub">
                    <mat-option *ngFor="let subs of capSubs.subs" [value]="subs">
                      <span title="{{subs.subName}}">{{subs.subName}}</span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div layout="row" layout-margin>
                <mat-form-field *ngIf="sub" style="width: 100%">
                  <mat-select required [style.width]="'100%'" flex="100" placeholder="" [(ngModel)]="workCycle" name="workCycle">
                    <mat-option *ngFor="let wcs of sub.workCycles" [value]="wcs">
                      <span title="{{wcs.workCycle}}">{{wcs.workCycle}}</span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div layout="row" layout-margin *ngIf="(cap && !cap.key)">
                <mat-form-field flex="50">
                  <input matInput name="workCycleOther" type="text" required [disabled]="cap && cap.key" [(ngModel)]="processDescriptor.workCycle.other"
                    placeholder="Other" #workCycleOtherElement #workCycleOtherControl="ngModel">
                </mat-form-field>
              </div>
              <div layout="row" layout-margin>
                <mat-form-field flex="50">
                  <input matInput maxlength="40" name="materialProcessed" type="text" required [(ngModel)]="processDescriptor.materialProcessed" placeholder="Material Processed"
                    #materialProcessedElement #materialProcessedControl="ngModel">
                  <mat-hint align="end">{{40 - processDescriptor.materialProcessed?.length}} chars</mat-hint>
                </mat-form-field>
                <mat-form-field flex="50">
                  <input matInput name="processEfficiency" type="number" min="0" max="100" required [(ngModel)]="processDescriptor.processEfficiency"
                    placeholder="Process Efficiency" #processEfficiencyElement #processEfficiencyControl="ngModel">
                  <mat-hint align="end">0-100</mat-hint>
                  <span matSuffix>[%]</span>
                </mat-form-field>
              </div>
              <div layout="row" layout-margin>
                <mat-form-field flex="50">
                  <input matInput name="installedPower" type="number" min="0" required [(ngModel)]="processDescriptor.installedPower" placeholder="Installed Power"
                    #installedPowerElement #installedPowerControl="ngModel">
                  <mat-hint align="end">>0</mat-hint>
                  <span matSuffix>kW</span>
                </mat-form-field>
                <mat-form-field flex="50">
                  <input matInput name="soundEmissions" type="number" min="0" step=".1" required [(ngModel)]="processDescriptor.soundEmissions" placeholder="Sound Emissions"
                    #soundEmissionsElement #soundEmissionsControl="ngModel" (change)="fixSoundEmission()">
                  <mat-hint align="end">>0</mat-hint>
                  <span matSuffix>dB(A)</span>
                </mat-form-field>
              </div>
              <div layout="row" layout-margin>
                <mat-form-field tdFileDrop (fileDrop)="istruzioniOperativeFile = $event" (click)="fileInput.inputElement.click()" (keyup.enter)="fileInput.inputElement.click()"
                  (keyup.delete)="fileInput.clear()" (keyup.backspace)="fileInput.clear()" flex>
                  <input [ngClass]="!fileInput.valid?'tc-red-700':''" matInput placeholder="Operating Instructions" [value]="istruzioniOperativeFile?.length ? (istruzioniOperativeFile?.length + ' istruzioniOperativeFile') : istruzioniOperativeFile?.name"
                    readonly/>
                </mat-form-field>
                <button mat-icon-button *ngIf="istruzioniOperativeFile" (click)="fileInput.clear()" (keyup.enter)="fileInput.clear()">
                  <mat-icon>cancel</mat-icon>
                </button>
                <td-file-input #fileInput required name="istruzioniOperativeFile" [(ngModel)]="istruzioniOperativeFile" color="primary" accept=".pdf"
                  (select)="validatePDFFile($event,fileInput)">
                  <mat-icon>attach_file</mat-icon>
                  <span>Choose a file...</span>
                </td-file-input>
                <!--  <button mat-raised-button (click)="downloadOperativeInstructions()" color="accent">
                  <mat-icon>file_download</mat-icon>Download Operative Instructions
                </button>
                -->
              </div>
            </form>
          </mat-card>
        </td-expansion-panel>

        <td-expansion-panel label="Consumption" expand="true">
          <ng-template td-expansion-panel-label>
            <mat-icon class="tc-red-700" *ngIf="!consumptionForm.valid">error_outline</mat-icon>
            <span [ngClass]="!consumptionForm.valid?'tc-red-700':''">Consumption</span>
          </ng-template>
          <mat-card>
            <form #consumptionForm="ngForm">
              <div layout="row" layout-margin>
                <mat-form-field flex="50">
                  <input matInput name="water" type="number" min="0" [(ngModel)]="consumption.water" placeholder="Water" #waterElement #waterControl="ngModel">
                  <span matSuffix>L/kg
                    <span title="material processed">mp</span>
                  </span>
                </mat-form-field>
                <mat-form-field flex="50">
                  <input matInput name="powerConsumption" type="number" min="0" required [(ngModel)]="consumption.powerConsumption" placeholder="Power Consumption"
                    #powerConsumptionElement #powerConsumptionControl="ngModel">
                  <span matSuffix>kWh/kg
                    <span title="material processed">mp</span>
                  </span>
                </mat-form-field>
              </div>
              <div layout="row" layout-margin>
                <mat-form-field flex="30">
                  <input matInput name="compressedAir" type="number" min="0" [(ngModel)]="consumption.compressedAir" placeholder="Compressed Air"
                    #compressedAirElement #compressedAirControl="ngModel">
                  <span matSuffix>
                    Nm<sup>3</sup>/kg<span title="material processed">mp</span>
                  </span>
                </mat-form-field>
                <mat-form-field flex="70">
                  <mat-select [disabled]="!compressedAirElement.value" [required]="compressedAirElement.value>=0" placeholder="Compressed Air Type"
                    [(ngModel)]="consumption.compressedAirType" name="compressedAirType">
                    <mat-option *ngFor="let v of machineParams?.compressedAirTypes; let i=index" [value]="v">{{v}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div layout="row" layout-margin>
                <mat-form-field flex="30">
                  <input matInput name="steam" type="number" min="0" [(ngModel)]="consumption.steam" placeholder="Steam" #steamElement #steamControl="ngModel">
                </mat-form-field>
                <mat-form-field flex="60">
                  <mat-select [disabled]="!steamElement.value" [required]="steamElement.value>=0" placeholder="Steam Type" [(ngModel)]="consumption.steamType"
                    name="steamType">
                    <mat-option *ngFor="let v of machineParams?.steamTypes; let i=index" [value]="v">
                      <span title="{{v}}">{{v}}</span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field flex="10">
                  <mat-select [disabled]="!steamElement.value" [required]="steamElement.value>=0" placeholder="Steam UM" [(ngModel)]="consumption.steamUM"
                    name="steamUM">
                    <mat-option *ngFor="let v of machineParams?.steamUMs; let i=index" [value]="v">{{v}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div layout="row" layout-margin>
                <mat-form-field flex="90">
                  <input matInput name="naturalGas" type="number" min="0" [(ngModel)]="consumption.naturalGas" placeholder="Natural Gas" #naturalGasElement
                    #naturalGasControl="ngModel">
                </mat-form-field>
                <mat-form-field flex="10">
                  <mat-select [disabled]="!naturalGasElement.value" [required]="naturalGasElement.value>=0" placeholder="Natural Gas UM" [(ngModel)]="consumption.naturalGasUM"
                    name="naturalGasUM">
                    <mat-option *ngFor="let v of machineParams?.naturalGasUMs; let i=index" [value]="v">{{v}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </form>
          </mat-card>
        </td-expansion-panel>

        <td-expansion-panel label="Washing Agents" expand="true">
          <ng-template td-expansion-panel-label>
            <mat-icon class="tc-red-700" *ngIf="!washingAgentForm.valid">error_outline</mat-icon>
            <span [ngClass]="!washingAgentForm.valid?'tc-red-700':''">Washing Agents</span>
          </ng-template>
          <mat-card>
            <form #washingAgentForm="ngForm">
              <div layout="row" layout-margin>
                <mat-form-field flex="50">
                  <input mat-input matInput #auxiliairesElement #auxiliairesControl="ngModel" type="number" min="0" placeholder="Auxiliaires"
                    [(ngModel)]="washingAgent.auxiliaires" name="auxiliaires">
                  <span matSuffix>kg/kg
                    <span title="material processed">mp</span>
                  </span>
                </mat-form-field>
                <mat-form-field flex="50">
                  <input mat-input matInput #bleachesElement #bleachesControl="ngModel" type="number" min="0" placeholder="Bleaches" [(ngModel)]="washingAgent.bleaches"
                    name="bleaches">
                  <span matSuffix>kg/kg
                    <span title="material processed">mp</span>
                  </span>
                </mat-form-field>
              </div>
              <div layout="row" layout-margin>
                <mat-form-field flex="50">
                  <input mat-input matInput #buildersSofteningsElement #buildersSofteningsControl="ngModel" type="number" min="0" placeholder="Builders Softenings"
                    [(ngModel)]="washingAgent.buildersSoftenings" name="buildersSoftenings">
                  <span matSuffix>kg/kg
                    <span title="material processed">mp</span>
                  </span>
                </mat-form-field>
                <mat-form-field flex="50">
                  <input mat-input matInput #tensidesSurfactantsElement #tensidesSurfactantsControl="ngModel" type="number" min="0" placeholder="Tensides Surfactants"
                    [(ngModel)]="washingAgent.tensidesSurfactants" name="tensidesSurfactants">
                  <span matSuffix>kg/kg
                    <span title="material processed">mp</span>
                  </span>
                </mat-form-field>
              </div>
            </form>
          </mat-card>
        </td-expansion-panel>


        <td-expansion-panel label="Chemical Process" expand="true">
          <ng-template td-expansion-panel-label>
            <mat-icon class="tc-red-700" *ngIf="!chemicalsProcessForm.valid">error_outline</mat-icon>
            <span [ngClass]="!chemicalsProcessForm.valid?'tc-red-700':''">Chemical Process</span>
          </ng-template>
          <mat-card>
            <mat-list>
              <form #chemicalsProcessForm="ngForm">
                <div layout="row" layout-margin>
                  <mat-form-field flex="33">
                    <input mat-input matInput #inorganicElement #inorganicControl="ngModel" type="number" min="0" placeholder="Inorganic" [(ngModel)]="chemicalsProcess.inorganic"
                      name="inorganic">
                    <span matSuffix>kg/kg
                      <span title="material processed">mp</span>
                    </span>
                  </mat-form-field>
                  <mat-form-field flex="33">
                    <input mat-input matInput #organicElement #organicControl="ngModel" type="number" min="0" placeholder="Organic" [(ngModel)]="chemicalsProcess.organic"
                      name="organic">
                    <span matSuffix>kg/kg
                      <span title="material processed">mp</span>
                    </span>
                  </mat-form-field>
                  <mat-form-field flex="33">
                    <input mat-input matInput #saltElement #saltControl="ngModel" type="number" min="0" placeholder="Salt" [(ngModel)]="chemicalsProcess.salt"
                      name="salt">
                    <span matSuffix>kg/kg
                      <span title="material processed">mp</span>
                    </span>
                  </mat-form-field>
                </div>
              </form>
            </mat-list>
          </mat-card>
        </td-expansion-panel>

        <td-expansion-panel expand="true">
          <ng-template td-expansion-panel-label>
            <mat-icon class="tc-red-700" *ngIf="!customProcessInputForm.valid">error_outline</mat-icon>
            <span [ngClass]="!customProcessInputForm.valid?'tc-red-700':''">Custom Process Input</span>
          </ng-template>
          <mat-card>
            <mat-card-content>
              <form #customProcessInputForm="ngForm">
                <div layout="row" layout-margin *ngFor="let cpi of customProssesInputs; let i=index">
                  <mat-form-field flex="33">
                    <input matInput type="text" placeholder="Key" [(ngModel)]="cpi.key" name="key_{{i}}">
                  </mat-form-field>
                  <mat-form-field flex="33">
                    <input matInput type="text" placeholder="Value" [(ngModel)]="cpi.value" name="value_{{i}}">
                  </mat-form-field>
                  <mat-form-field flex="33">
                    <input matInput type="text" placeholder="UM" [(ngModel)]="cpi.um" name="um_{{i}}">
                  </mat-form-field>
                  <button mat-mini-fab color="primary" (click)="removeCPI(cpi,i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </form>
            </mat-card-content>
            <mat-card-actions>
              <button mat-raised-button color="accent" (click)="addNewCPI()">Add new</button>
            </mat-card-actions>
          </mat-card>
        </td-expansion-panel>
      </td-step>

      <td-step #step3 label="File Upload" sublabel="Upload file specification" >
        <mat-card>
          <mat-card-content>
            <div>
              <h3>Upload several files to certificate the values previously edited. Check each values certificated by uploaded
                file.
              </h3>
            </div>
            <td-expansion-panel label="{{uf.path}}" expand="true" *ngFor="let uf of uploadFiles; let i=index">
              <td-expansion-summary>
                <div layout="row" layout-margin>
                  <div flex="20" *ngIf="uf.params['installedPower']">
                    <mat-icon color="accent">done</mat-icon>Installed Power
                  </div>
                  <div flex="20" *ngIf="uf.params['soundEmissions']">
                    <mat-icon color="accent">done</mat-icon>Sound Emissions
                  </div>
                  <div flex="20" *ngIf="uf.params['water']">
                    <mat-icon color="accent">done</mat-icon>Water
                  </div>
                  <div flex="20" *ngIf="uf.params['powerConsumption']">
                    <mat-icon color="accent">done</mat-icon>Power Consumption
                  </div>
                  <div flex="20" *ngIf="uf.params['compressedAir']">
                    <mat-icon color="accent">done</mat-icon>Compressed Air
                  </div>
                  <div flex="20" *ngIf="uf.params['steam']">
                    <mat-icon color="accent">done</mat-icon>Steam
                  </div>
                  <div flex="20" *ngIf="uf.params['naturalGas']">
                    <mat-icon color="accent">done</mat-icon>Natural Gas
                  </div>

                  <div flex="20" *ngIf="uf.params['auxiliaires']">
                    <mat-icon color="accent">done</mat-icon>Auxiliaires
                  </div>
                  <div flex="20" *ngIf="uf.params['bleaches']">
                    <mat-icon color="accent">done</mat-icon>Bleaches
                  </div>
                  <div flex="20" *ngIf="uf.params['buildersSoftenings']">
                    <mat-icon color="accent">done</mat-icon>Builders Softenings
                  </div>
                  <div flex="20" *ngIf="uf.params['tensidesSurfactants']">
                    <mat-icon color="accent">done</mat-icon>Tensides Surfactants
                  </div>


                  <div flex="20" *ngIf="uf.params['inorganic']">
                    <mat-icon color="accent">done</mat-icon>Inorganic
                  </div>
                  <div flex="20" *ngIf="uf.params['organic']">
                    <mat-icon color="accent">done</mat-icon>Organic
                  </div>
                  <div flex="20" *ngIf="uf.params['salt']">
                    <mat-icon color="accent">done</mat-icon>Salt
                  </div>
                  <div flex="20" *ngIf="uf.params['customProcessInput']">
                    <mat-icon color="accent">done</mat-icon>Custom Process Input
                  </div>
                </div>
              </td-expansion-summary>
              <mat-list>
                <mat-list-item> Process Descriptor </mat-list-item>
                <mat-list-item>
                  <mat-checkbox [disabled]="paramsToBeCertificated['installedPower']==0" flex="20" (change)="changeParamStatus($event,'installedPower')"
                    [(ngModel)]="uf.params['installedPower']">
                    <span [ngClass]="{'param-required': paramsToBeCertificated['installedPower']==1,'param-certificated':paramsToBeCertificated['installedPower']==2}">Installed Power</span>
                  </mat-checkbox>
                  <mat-checkbox [disabled]="paramsToBeCertificated['soundEmissions']==0" flex="20" (change)="changeParamStatus($event,'soundEmissions')"
                    [(ngModel)]="uf.params['soundEmissions']">
                    <span [ngClass]="{'param-required': paramsToBeCertificated['soundEmissions']==1,'param-certificated':paramsToBeCertificated['soundEmissions']==2}">Sound Emissions</span>
                  </mat-checkbox>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item> Consumption </mat-list-item>
                <mat-list-item>
                  <mat-checkbox [disabled]="paramsToBeCertificated['water']==0" flex="20" (change)="changeParamStatus($event,'water')" [(ngModel)]="uf.params['water']">
                    <span [ngClass]="{'param-required': paramsToBeCertificated['water']==1,'param-certificated':paramsToBeCertificated['water']==2}">Water</span>
                  </mat-checkbox>
                  <mat-checkbox [disabled]="paramsToBeCertificated['powerConsumption']==0" flex="20" (change)="changeParamStatus($event,'powerConsumption')"
                    [(ngModel)]="uf.params['powerConsumption']">
                    <span [ngClass]="{'param-required': paramsToBeCertificated['powerConsumption']==1,'param-certificated':paramsToBeCertificated['powerConsumption']==2}">Power Consumption</span>
                  </mat-checkbox>
                  <mat-checkbox [disabled]="paramsToBeCertificated['compressedAir']==0" flex="20" (change)="changeParamStatus($event,'compressedAir')"
                    [(ngModel)]="uf.params['compressedAir']">
                    <span [ngClass]="{'param-required': paramsToBeCertificated['compressedAir']==1,'param-certificated':paramsToBeCertificated['compressedAir']==2}">Compressed Air</span>
                  </mat-checkbox>
                  <mat-checkbox [disabled]="paramsToBeCertificated['steam']==0" flex="20" (change)="changeParamStatus($event,'steam')" [(ngModel)]="uf.params['steam']">
                    <span [ngClass]="{'param-required': paramsToBeCertificated['steam']==1,'param-certificated':paramsToBeCertificated['steam']==2}">Steam</span>
                  </mat-checkbox>
                  <mat-checkbox [disabled]="paramsToBeCertificated['naturalGas']==0" flex="20" (change)="changeParamStatus($event,'naturalGas')"
                    [(ngModel)]="uf.params['naturalGas']">
                    <span [ngClass]="{'param-required': paramsToBeCertificated['naturalGas']==1,'param-certificated':paramsToBeCertificated['naturalGas']==2}">Natural Gas</span>
                  </mat-checkbox>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item> Washing Agents </mat-list-item>
                <mat-list-item>
                  <mat-checkbox [disabled]="paramsToBeCertificated['auxiliaires']==0" flex="20" (change)="changeParamStatus($event,'auxiliaires')"
                    [(ngModel)]="uf.params['auxiliaires']">
                    <span [ngClass]="{'param-required': paramsToBeCertificated['auxiliaires']==1,'param-certificated':paramsToBeCertificated['auxiliaires']==2}">Auxiliaires</span>
                  </mat-checkbox>
                  <mat-checkbox [disabled]="paramsToBeCertificated['bleaches']==0" flex="20" (change)="changeParamStatus($event,'bleaches')"
                    [(ngModel)]="uf.params['bleaches']">
                    <span [ngClass]="{'param-required': paramsToBeCertificated['bleaches']==1,'param-certificated':paramsToBeCertificated['bleaches']==2}">Bleaches</span>
                  </mat-checkbox>
                  <mat-checkbox [disabled]="paramsToBeCertificated['buildersSoftenings']==0" flex="20" (change)="changeParamStatus($event,'buildersSoftenings')"
                    [(ngModel)]="uf.params['buildersSoftenings']">
                    <span [ngClass]="{'param-required': paramsToBeCertificated['buildersSoftenings']==1,'param-certificated':paramsToBeCertificated['buildersSoftenings']==2}">Builders Softenings</span>
                  </mat-checkbox>
                  <mat-checkbox [disabled]="paramsToBeCertificated['tensidesSurfactants']==0" flex="20" (change)="changeParamStatus($event,'tensidesSurfactants')"
                    [(ngModel)]="uf.params['tensidesSurfactants']">
                    <span [ngClass]="{'param-required': paramsToBeCertificated['tensidesSurfactants']==1,'param-certificated':paramsToBeCertificated['tensidesSurfactants']==2}">Tensides Surfactants</span>
                  </mat-checkbox>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item> Chemicals Process </mat-list-item>
                <mat-list-item>
                  <mat-checkbox [disabled]="paramsToBeCertificated['inorganic']==0" flex="20" (change)="changeParamStatus($event,'inorganic')"
                    [(ngModel)]="uf.params['inorganic']">
                    <span [ngClass]="{'param-required': paramsToBeCertificated['inorganic']==1,'param-certificated':paramsToBeCertificated['inorganic']==2}">Inorganic</span>
                  </mat-checkbox>
                  <mat-checkbox [disabled]="paramsToBeCertificated['organic']==0" flex="20" (change)="changeParamStatus($event,'organic')"
                    [(ngModel)]="uf.params['organic']">
                    <span [ngClass]="{'param-required': paramsToBeCertificated['organic']==1,'param-certificated':paramsToBeCertificated['organic']==2}">Organic</span>
                  </mat-checkbox>
                  <mat-checkbox [disabled]="paramsToBeCertificated['salt']==0" flex="20" (change)="changeParamStatus($event,'salt')" [(ngModel)]="uf.params['salt']">
                    <span [ngClass]="{'param-required': paramsToBeCertificated['salt']==1,'param-certificated':paramsToBeCertificated['salt']==2}">Salt</span>
                  </mat-checkbox>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item> Custom Process Input </mat-list-item>
                <mat-list-item>
                  <mat-checkbox [disabled]="paramsToBeCertificated['customProcessInput']==0" flex="20" (change)="changeParamStatus($event,'customProcessInput')"
                    [(ngModel)]="uf.params['customProcessInput']">
                    <span [ngClass]="{'param-required': paramsToBeCertificated['customProcessInput']==1,'param-certificated':paramsToBeCertificated['customProcessInput']==2}">Custom Process Input</span>
                  </mat-checkbox>
                </mat-list-item>
                <mat-divider></mat-divider>
              </mat-list>
            </td-expansion-panel>
          </mat-card-content>
          <mat-card-actions layout="row" layout-align="end center">
            <td-file-input [(ngModel)]="uFiles" color="primary" (select)="uploadFile($event)" accept=".pdf" multiple>
              <mat-icon>attach_file</mat-icon>
              <span>Choose a file...</span>
            </td-file-input>
          </mat-card-actions>
        </mat-card>
      </td-step>
      <td-step #step4 label="Summary" sublabel="Recap of machine configuration" >
        <mat-card>
          <mat-card-content>
            <h2>Green Label is ready to be generated!</h2>
            <h5>click the button to start the generation of label</h5>
            <button mat-raised-button *ngIf="currStepIndex==3" (click)="save()" color="primary">
              <mat-icon class="mat-48">navigate_next</mat-icon>
              Generate Label
            </button>
          </mat-card-content>
        </mat-card>
      </td-step>

    </td-steps>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions>
    <div layout="row" layout-margin>
      <button mat-raised-button (click)="downloadOperativeInstructions()" *ngIf="currStepIndex==0" color="accent">
        <mat-icon class="mat-24">file_download</mat-icon>Download Operative Instructions
      </button>
      <span *ngIf="currStepIndex==0" class="banner-btn">Operative instructions data should be referred to the <span *ngIf="!configuration.year">reference year</span><span *ngIf="configuration.year">year {{configuration.year}}</span></span>
      <button mat-raised-button (click)="prevStep()" *ngIf="currStepIndex>0" color="primary">
        <mat-icon class="mat-24">navigate_before</mat-icon>
        Prev
      </button>
      <span flex></span>
      <button mat-raised-button *ngIf="currStepIndex<3" (click)="nextStep()" color="primary">
        <mat-icon class="mat-24">navigate_next</mat-icon>
        Next
      </button>
    </div>
  </mat-card-actions>
</mat-card>

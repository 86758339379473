import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AuthInterceptor, AuthModule, OidcConfigService} from 'angular-auth-oidc-client';
import {environment} from '../../../environments/environment';
import {HTTP_INTERCEPTORS} from '@angular/common/http';

export function configureAuth(oidcConfigService: OidcConfigService): () => Promise<any> {
  return () =>
    oidcConfigService.withConfig({
      secureRoutes: [environment.api_endpoint, 'http://localhost:8000'],

      stsServer: environment.auth.issuer,
      redirectUrl: window.location.origin,
      clientId: environment.auth.client,
      scope: environment.auth.scope,
      responseType: 'code',
      silentRenew: true,
      useRefreshToken: true,
      maxIdTokenIatOffsetAllowedInSeconds: 600,
      issValidationOff: false,
      autoUserinfo: false,
      customParams: {
        prompt: 'select_account', // login, consent
      },
    });
}

@NgModule({
  imports: [AuthModule.forRoot({})],
  exports: [AuthModule],
  providers: [
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configureAuth,
      deps: [OidcConfigService],
      multi: true,
    },
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
  ],
})
export class AuthConfigModule {
}

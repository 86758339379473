import {environment} from 'environments/environment';

import {MessageService} from './../../services/message.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from 'app/models';
import {Message} from 'app/models/message';

import {Subscription} from 'rxjs/internal/Subscription';
import {TdLoadingService} from '@covalent/core/loading';
import {timer} from 'rxjs';
import {SecurityService} from 'app/services/security.service';
import {OidcSecurityService} from 'angular-auth-oidc-client';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
  currentUser: User;
  messages: Message[];
  unreadMessagesCount: any;
  latestMessages: Message[];
  version: string;

  routes: {
    title: string,
    route: string,
    icon: string,
    roles: string[]
  }[];
  userGuideUrl;
  brandBookUrl: string;
  logosUrl: string;
  private subscription: Subscription;



  constructor(
    private messageService: MessageService,
    private _loadingService: TdLoadingService,
    private security: SecurityService,
    private auth: OidcSecurityService
  ) {
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.security.getUser().subscribe(
      u => this.init(u)
    )

  }

  init(u: User) {
    console.log('USERINFO', u);
    this.version = environment.version; // GetAppVersion;
    this.currentUser = u; // {company: undefined, email: '', firstName: '', id: '', lastName: '', role: '', type: 'User'};
    // this.ks.getUserInfo();
    this.userGuideUrl = environment.base_url + '/assets/guide/user_guide_tool_acimit_v11.pdf';
    this.logosUrl = environment.base_url + '/assets/images/logos-new.zip';
    this.brandBookUrl = environment.base_url + '/assets/guide/green-label-brandbook.pdf';

    const time = timer(0, environment.pop_timeout);
    this.subscription = time.subscribe(t => {
      this.loadMessages();
    });

    this.routes = [
      {
        title: 'Tool',
        route: '/dashboard',
        icon: 'dashboard',
        roles: ['administrator', 'associate', 'member']
      },
      {
        title: 'Administration',
        route: '/adm/companies/' + this.currentUser.company.id,
        // route: '/adm/dashboard',
        icon: 'company',
        roles: ['administrator', 'associate']
      }
    ];
  }

  loadMessages() {
    this._loadingService.register('message.loading');
    this.messageService.getMyMessages().subscribe(
      res => {
        this.messages = res;
        this.unreadMessagesCount = res.filter(m => m.to[0].status === false).length;
        this.latestMessages = res.slice(0, 5);
      },
      err => console.log(err),
      () => this._loadingService.resolve('message.loading')
    );
  }

  logout() {
    this.auth.logoff();
  }
}

<h1 mat-dialog-title>Select location</h1>

<mat-dialog-content>
  <form>
    <mat-form-field flex>
      <input matInput required placeholder="Location" name="location" [matAutocomplete]="tdAutoState" [(ngModel)]="location" #modelDir="ngModel"
        (ngModelChange)="this.countries = filterStates(location)">
    </mat-form-field>
    <mat-autocomplete #tdAutoState="matAutocomplete" [displayWith]="locationDisplayFn">
      <mat-option *ngFor="let state of countries" [value]="state">
        <span>{{ state.name }}</span>
      </mat-option>
    </mat-autocomplete>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button color="accent" class="text-upper" mat-dialog-close="" (click)="close()">Close</button>
  <button mat-button color="accent" class="text-upper" (click)="select()">Select</button>
</mat-dialog-actions>

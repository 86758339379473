import {Trash} from '../models';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {BaseService} from './base-service';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class TrashService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  empty(): Observable<string> {

    const pageUrl: string = environment.api_endpoint + 'trash/';
    return this.http
      .delete<string>(pageUrl)
      ;
  }

  delete(t: Trash): Observable<string> {
    const pageUrl: string = environment.api_endpoint + 'trash/' + t.id;
    return this.http
      .delete<string>(pageUrl)
      ;
  }

  restoreTrash(t: Trash): Observable<string> {
    switch (t.contentType) {
      case 'configuration':
        return this.restoreConfiguration(t.id);
      case 'machine':
        return this.restoreMachine(t.id);
      case 'company':
        return this.restoreCompany(t.id);
      case 'installation':
        return this.restoreInstallation(t.id);
    }
  }

  restoreCompany(id: string): Observable<any> {
    const pageUrl: string = environment.api_endpoint + 'trash/company/' + id;
    const httpOptions: Object = {
      responseType: 'arraybuffer'
    };
    return this.http
      .get<any>(pageUrl, httpOptions)
      ;
  }

  trashCompany(id: string): Observable<string> {
    const pageUrl: string = environment.api_endpoint + 'trash/company/' + id;
    return this.http
      .delete<string>(pageUrl)
      ;
  }

  restoreConfiguration(id: string): Observable<any> {
    const pageUrl: string =
      environment.api_endpoint + 'trash/configuration/' + id;
    const httpOptions: Object = {
      responseType: 'arraybuffer'
    };
    return this.http
      .get<any>(pageUrl, httpOptions)
      ;
  }

  trashConfiguration(
    machineId: string,
    revision: string,
    year: number
  ): Observable<string> {
    const pageUrl: string =
      environment.api_endpoint +
      'trash/configuration/' +
      machineId +
      '/' +
      encodeURI(revision) +
      '/' +
      year;
    return this.http
      .delete<string>(pageUrl)
      ;
  }

  trashInstallation(
    machineId: string,
    revision: string,
    year: number,
    labelId: string
  ): Observable<string> {
    const pageUrl: string =
      environment.api_endpoint +
      'trash/installation/' +
      machineId +
      '/' +
      encodeURI(revision) +
      '/' +
      year +
      '/' +
      labelId;
    return this.http
      .delete<string>(pageUrl)
      ;
  }

  restoreInstallation(id: string): Observable<any> {
    const pageUrl: string = environment.api_endpoint + 'trash/installation/' + id;
    const httpOptions: Object = {
      responseType: 'arraybuffer'
    };
    return this.http
      .get<any>(pageUrl, httpOptions)
      ;
  }

  restoreMachine(id: string): Observable<any> {
    const pageUrl: string = environment.api_endpoint + 'trash/machine/' + id;
    const httpOptions: Object = {
      responseType: 'arraybuffer'
    };
    return this.http
      .get<any>(pageUrl, httpOptions)
      ;
  }

  trashMachine(machineId: string) {
    const pageUrl: string =
      environment.api_endpoint + 'trash/machine/' + machineId;
    return this.http
      .delete<string>(pageUrl)
      ;
  }

  findAll(trashType: string): Observable<Trash[]> {
    const pageUrl: string = environment.api_endpoint + 'trash/' + trashType;
    return this.http
      .get<Trash[]>(pageUrl)
      ;
  }
}

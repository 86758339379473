import { TitleService } from '../../services/title.service';
import { Router } from '@angular/router';
import { SnackService } from '../../services/snack.service';
import { AgendaService } from '../../services/agenda.service';
import { Component, ViewChild } from '@angular/core';

import * as moment from 'moment';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.css']
})
export class AgendaComponent {
  scope = this;
  calendarOptions: any = {
    height: 'auto',
    firstDay: 1,
    timezone: 'local',
    fixedWeekCount: false,
    // defaultDate: '2016-09-12',
    editable: true,
    eventLimit: true, // allow "more" link when too many events
    // events: function (start, end, timezone, callback) {
    //   this.start = start;
    //   this.end = end;
    // }
  };
  start: any;
  end: any;

  @ViewChild('calendar') myCalendar: any;


  loadEvents(start, end, timezone, callback) {
    if (this.agendaService) {
      const s = moment(start).format('DDMMYYYY');
      const e = moment(end).format('DDMMYYYY');
      this.agendaService.findAll(s, e).subscribe(
        res => callback(res),
        err => console.log(err)
      );
    }
  }

  changeCalendarView(view) {
    this.myCalendar.fullCalendar('changeView', view);

  }

  //  private scope:any;
  constructor(private agendaService: AgendaService, private snackService: SnackService,
              private _router: Router, private titleService: TitleService) {
    this.titleService.setTitle('Admin Agenda');
    const scope = this;
    this.calendarOptions['events'] = function (start, end, timezone, callback) {
      scope.loadEvents(start, end, timezone, callback);
    }
    this.calendarOptions['eventClick'] = function (calEvent, jsEvent, view) {

      // change the border color just for fun
      jsEvent.target.classList.add('hightlight-event');
      setTimeout(function () {
        jsEvent.target.classList.remove('hightlight-event');
      }, 3000);
      _router.navigate(['/adm/agenda/', calEvent._id, 'edit']);


    }
  }

}

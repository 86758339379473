<mat-card tdMediaToggle="gt-xs" [mediaClasses]="['push']">
  <td-search-box class="push-left push-right" placeholder="search" [alwaysVisible]="true" (searchDebounce)="filterCompany($event)"></td-search-box>
  <mat-divider></mat-divider>
  <ng-template tdLoading="company.list">
    <div flex layout="row" mat-scroll-y>
      <mat-list class="will-load" #scrollableDiv flex >
        <div class="mat-padding" *ngIf="!filteredCompanyList || filteredCompanyList.length === 0" layout="row" layout-align="center center">
          <h3>No companies to display.</h3>
        </div>
        <ng-template let-company let-last="last" ngFor [ngForOf]="filteredCompanyList">
          <mat-list-item>
            <img class="logo-preview" mat-list-avatar lazy-image-load [data-source]="company.id" alt="company logo" />
            <h3 mat-line> {{company.name}} </h3>
            <span>
              <button mat-icon-button [mat-menu-trigger-for]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu x-position="before" #menu="matMenu">
                <a [routerLink]="[company.id ]" mat-menu-item>Dashboard</a>
                <a [routerLink]="[company.id + '/edit']" mat-menu-item>Edit</a>
                <button (click)="deleteCompany(company.id)" mat-menu-item>Delete</button>
              </mat-menu>
            </span>
          </mat-list-item>
          <mat-divider *ngIf="!last" mat-inset></mat-divider>
        </ng-template>
      </mat-list>
    </div>
  </ng-template>
  <mat-divider></mat-divider>
  <mat-card-actions>
    <button mat-button class="mat-primary" (click)="goBack()">BACK</button>
  </mat-card-actions>

  <a mat-fab color="accent" class="mat-fab-position-bottom-right position-bottom-right" [routerLink]="['add']">
    <mat-icon>add</mat-icon>
  </a>
</mat-card>

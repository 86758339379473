<mat-card tdMediaToggle="gt-xs" [mediaClasses]="['push']">
  <mat-card-title>{{action}} company</mat-card-title>
  <mat-divider></mat-divider>
  <mat-card-content class="push-bottom-none">
    <form #companyForm="ngForm">
      <div layout="row">
        <mat-form-field flex>
          <input mat-input matInput #nameElement #nameControl="ngModel" type="text" placeholder="Company Name" [(ngModel)]="company.name"
            name="name" required>
        </mat-form-field>
      </div>
      <div layout="row" class="push-top">
        <mat-form-field flex>
          <input mat-input matInput #addressElement #addressControl="ngModel" type="text" placeholder="Address" [(ngModel)]="company.address"
            name="address">
        </mat-form-field>
      </div>
      <div layout="row">
        <td-file-input color="primary" (select)="uploadEvent($event)" accept=".png,.jpg">
          <mat-icon>attach_file</mat-icon><span>Choose a logo...</span>
        </td-file-input>
        <span flex></span>

        <span *ngIf="logo;else nologo">
          <img *ngIf="logo" src="" imgPreview [image]="logo" class="logo-preview" mat-list-avatar alt="company logo" />
        </span>
        <ng-template #nologo>
          <img *ngIf="company.logo" [src]="'data:image/jpg;base64,'+company.logo" class="logo-preview" mat-list-avatar alt="company logo" />
        </ng-template>
      </div>
    </form>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions>
    <button mat-button [disabled]="!companyForm.form.valid" color="primary" (click)="save()">SAVE</button>
    <button mat-button (click)="goBack()">CANCEL</button>
  </mat-card-actions>
</mat-card>

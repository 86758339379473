
export class Geo {
    longitude: number;
    latitude: number;
    zoom: number;
}
export class State {
    id: string;
    name: string;
    code: string;
    region: string;
    subRegion: string;
    geo: Geo;
    order: number;
}

import {Company} from 'app/models/company';

export class User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  userId?: number;
  role: string;
  type: 'User';
  privacy: PrivacyCheck;

  company: Company;


  public static deserialize(o): User {
    const u: User = new User;
    u.id = o.id;
    u.lastName = o.lastName;
    u.firstName = o.firstName;
    u.userId = o.userId;
    u.email = o.email;
    u.role = o.role;
    u.company = Company.deserialize(o.company);
    return u;
  }
}

export interface PrivacyCheck {
  receiveMailCheck: boolean;
  usingImagesCheck: boolean;
  mandatoryCheck: boolean;
  privacyUpdateDate?: Date;
}

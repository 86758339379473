import { Injectable } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable()
export class SnackService {

  constructor(private _snackBarService: MatSnackBar) { }


  notifyErr(message: string) {
    this._snackBarService.open(message, 'Close', {
      duration: 30000,
      panelClass: ['snack-error']
    });
  }
  notifyWarn(message: string) {
    this._snackBarService.open(message, 'Close', {
      duration: 30000,
      panelClass: ['snack-warn']
    });
  }

  notifyInfo(message: string) {
    this._snackBarService.open(message, 'Close', {
      duration: 30000,
      panelClass: ['snack-info']
    })
  }

}

import {throwError} from 'rxjs/internal/observable/throwError';

export class BaseService {
    protected handleError(error: any) {
        // In a real world app, we might use a remote logging infrastructure

        if (error) {
            if (error.status) {
                if (error.status < 400 || error.status === 500) {
                    return throwError(new Error(error.status));
                }
            }
        }

        let errMsg: string;
            errMsg = error.message ? error.message : error.toString();
        return throwError(errMsg);
    }

    protected deserialize(json, environment, clazz) {
        const instance = new clazz();
        for (const prop in json) {
            if (!json.hasOwnProperty(prop)) {
                continue;
            }

            if (typeof json[prop] === 'object') {
                instance[prop] = this.deserialize(json[prop], environment, environment[prop]);
            } else {
                instance[prop] = json[prop];
            }
        }

        return instance;
    }
}

import { Injectable } from '@angular/core';
import { BaseService } from 'app/services/base-service';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { Batch } from 'app/models/batch';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class MaintenanceService extends BaseService {
    constructor(private http: HttpClient) {
        super();
    }

    fillInstallation(): Observable<Batch> {
        const pageUrl: string = environment.api_endpoint + 'maintenance/fill-installation';
        return this.http
            .post<Batch>(pageUrl, {})
            ;
    }
    rebuildAll(): Observable<Batch> {
        const pageUrl: string = environment.api_endpoint + 'maintenance/green-label/rebuild';
        return this.http
            .post<Batch>(pageUrl, {})
            ;
    }

    rebuildByCompany(companyId: string): Observable<Batch> {
        const pageUrl: string =
            environment.api_endpoint + 'maintenance/green-label/rebuild/' + companyId;
        return this.http
            .post<Batch>(pageUrl, {})
            ;
    }

    monitor(batchId: string): Observable<Batch> {
        const pageUrl: string = environment.api_endpoint + 'maintenance/batch/' + batchId;
        return this.http
            .get<Batch>(pageUrl)
            ;
    }
}

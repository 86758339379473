import { Component, OnInit, Input } from '@angular/core';
import { User } from 'app/models';
import { UserService } from 'app/services/user.service';
import { ActivatedRoute } from '@angular/router';
import {TdLoadingService} from '@covalent/core/loading';
import {TdDialogService} from '@covalent/core/dialogs';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  @Input()
  companyId: string;
  users: User[];
  filteredUsers: Array<User>;
  constructor(private userService: UserService, private _route: ActivatedRoute,
    private _loadingService: TdLoadingService, private _dialogService: TdDialogService,
    private _snackBarService: MatSnackBar) { }

  ngOnInit() {
    this.loadUsers(this.companyId);
  }

  deleteUser(id: string): void {
    this._dialogService
      .openConfirm({ message: 'Are you sure you want to delete this user?' })
      .afterClosed().subscribe((confirm: boolean) => {
        if (confirm) {
          this._loadingService.register('users.list');
          this.userService.delete(id).subscribe(() => {
            this.users = this.users.filter((c: User) => {
              return c.id !== id;
            });
            this.filteredUsers = this.filteredUsers.filter((c: User) => {
              return c.id !== id;
            });
            this._loadingService.resolve('users.list');
            this._snackBarService.open('User deleted', 'Ok');
          }, (error: Error) => {
            this._dialogService.openAlert({ message: 'There was an error' });
            this._loadingService.resolve('users.list');
          });
        }
      });
  }

  private loadUsers(companyId: string) {
    this._loadingService.register('users.list');
    this.userService.findByCompanyId(companyId).subscribe(
      (res: User[]) => {
        this.users = res;
        this.filteredUsers = res;
        this._loadingService.resolve('users.list');
      },
      err => {
        this.users = [];
        this.filteredUsers = [];
        console.log(err);
        this._loadingService.resolve('users.list');
      });
  }
}

<div layout-gt-sm="row" tdMediaToggle="gt-xs" [mediaClasses]="['push-sm']">
  <div flex-gt-sm>
    <mat-card tdMediaToggle="gt-xs" [mediaClasses]="['push']">
      <mat-card-title>
        <span class="mat-headline">Messages</span>
      </mat-card-title>
      <mat-divider></mat-divider>
      <mat-divider></mat-divider>

      <mat-card-content>
        <ng-template tdLoading="message.list">
          <div class="mat-padding" *ngIf="!messages || messages.length === 0" layout="row" layout-align="center center">
            <h3>No messages to display.</h3>
          </div>
          <mat-list class="will-load">
            <ng-template let-last="last" ngFor [ngForOf]="messages" let-m>
              <mat-list-item (click)="selectMessage(m)" class="link-item" [ngClass]="{'active': (message && m.id===message.id)}">
                <mat-icon mat-list-avatar>message</mat-icon>
                <h4 mat-line><span class="text-wrap" [ngClass]="{'unread-message': m.status==false}">{{m.object}}</span></h4>
                <p mat-line>
                  <span>from: {{m.from?.name}} </span>
                  <span *ngIf="m.createdBy">({{m.createdBy?.firstName}} {{m.createdBy?.lastName}})</span>
                </p>
                <p mat-line>
                  <span>to: </span>
                  <ng-template let-lastTo="last" ngFor [ngForOf]="m.to" let-t>
                    <span>{{t.company?.name}}<span *ngIf="!lastTo">; </span>
                    </span>
                  </ng-template>
                </p>
                <p mat-line>{{m.sentDate | timeAgo}}</p>
              </mat-list-item>
              <mat-divider *ngIf="!last"></mat-divider>
            </ng-template>
          </mat-list>
        </ng-template>
      </mat-card-content>
      <mat-divider></mat-divider>
      <mat-card-actions layout="row" layout-align="end center">

      </mat-card-actions>
    </mat-card>
    <mat-card tdMediaToggle="gt-xs" [mediaClasses]="['push']" *ngIf="message">
      <mat-card-title>
        Message detail
      </mat-card-title>
      <mat-divider></mat-divider>
      <mat-card-content>
        <mat-list>
          <mat-list-item>
            <mat-icon mat-list-avatar>account_box</mat-icon>
            <h4 mat-line>{{message.from.name}}<span>({{message.createdBy?.firstName}} {{message.createdBy?.lastName}})</span></h4>
            <p mat-line>From</p>
          </mat-list-item>
          <mat-divider mat-inset></mat-divider>
          <mat-list-item>
            <mat-icon mat-list-avatar>subject</mat-icon>
            <h4 mat-line><span class="text-wrap">{{message.object}}</span></h4>
            <p mat-line>Object</p>
          </mat-list-item>
          <mat-divider mat-inset></mat-divider>
          <mat-list-item>
            <mat-icon mat-list-avatar>message</mat-icon>
            <h4 mat-line><span class="text-wrap">{{message.message}}</span></h4>
            <p mat-line>Message</p>
          </mat-list-item>
          <mat-divider></mat-divider>
          <h3 mat-subheader>Dates</h3>
          <mat-list-item>
            <mat-icon mat-list-avatar>access_time</mat-icon>
            <h4 mat-line>{{message.sentDate | timeAgo}}</h4>
            <p mat-line>Sent Date</p>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
      <mat-card-actions align="end">
      </mat-card-actions>
    </mat-card>


    <a mat-fab class="mat-fab-position-bottom-right position-bottom-right" color="accent" [routerLink]="['add']">
      <mat-icon>add</mat-icon>
    </a>
  </div>
</div>

export class Company {
    id: string;
    name: string;
    address: string;
    logo = '';
    selected?: boolean;

    public static deserialize(o): Company {
        const c: Company = new Company;
        c.id = o.id;
        c.name = o.name;
        c.address = o.address;
        c.logo = o.logo;
        return c;
    }
}

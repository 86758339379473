<mat-card>
  <mat-card-title>Agenda</mat-card-title>
  <mat-divider></mat-divider>
  <mat-card-content>
    <full-calendar #calendar [options]="calendarOptions"></full-calendar>
  </mat-card-content>
  <mat-card-actions align="start|end">
    <button mat-button color="accent" class="text-upper" [routerLink]="['/adm/agenda/add']">New Event</button>
  </mat-card-actions>
</mat-card>

import {DocumentContainer} from './../models/document-container';

import {Injectable} from '@angular/core';
import {BaseService} from 'app/services/base-service';
import {Observable} from 'rxjs';
import {environment} from 'environments/environment';
import {Logs} from 'app/models/logs';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class LogsService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  /* getAll(page: number, size: number): Observable<DocumentContainer<Logs>> {
       let pageUrl: string = environment.api_endpoint + 'logs/';
       return this.http.get(pageUrl)
           .map(this.extractData)
           ;
   }
*/
  getAllByLevel(page: number, pageSize: number, level?: number): Observable<DocumentContainer<Logs>> {
    const pageUrl: string = environment.api_endpoint +
      'logs/' + (level !== null ? level : '') + '/?page=' + page + '&size=' + pageSize + '&sort=creationDate,desc';
    return this.http.get<DocumentContainer<Logs>>(pageUrl)
      ;
  }

}

import { TitleService } from '../../services/title.service';
import { Logs } from './../../models/logs';
import { LogsService } from './../../services/logs.service';
import { Component, OnInit } from '@angular/core';
import { DocumentContainer } from 'app/models/document-container';
import {TdLoadingService} from '@covalent/core/loading';
import {IPageChangeEvent} from '@covalent/core/paging';

@Component({
  selector: 'logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})
export class LogsComponent implements OnInit {
  logs: DocumentContainer<Logs>;
  page = 0;
  size = 20;
  pageSize = 20;

  logLevel: number;

  constructor(
    private logService: LogsService,
    private _loadingService: TdLoadingService,
    private titleService: TitleService
  ) {
    this.titleService.setTitle('Admin Logs');
  }

  ngOnInit() {
    this.logLevel = null;
    this.page = 0;
    this.size = 20;
    this.search();
  }

  searchByFilter(evt: any) {
    this.search();
  }

  search() {
    this._loadingService.register('logs.loading');
    this.logService
      .getAllByLevel(this.page, this.size, this.logLevel)
      .subscribe(
        res => {
          this.logs = res;
        },
        err => {
          console.log(err);
        },
        () => {
          this._loadingService.resolve('logs.loading');
        }
      );
  }

  changePage(evt: IPageChangeEvent) {
    this.page = evt.page;
    this.size = evt.pageSize;
    this.search();
  }
}

import {Labels} from './../../models/chart-data';
import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {IPageChangeEvent} from '@covalent/core/paging';

@Component({
  selector: 'labels',
  templateUrl: './labels.component.html',
  styleUrls: ['./labels.component.css']
})
export class LabelsComponent implements OnInit, OnChanges {
  @Input('data') labelsData: Labels[];
  labelsDataPage: Labels[];
  pageSize = 5;

  ngOnInit() {
    this.sliceData();
  }

  ngOnChanges() {
    this.sliceData();
  }

  changePage(evt: IPageChangeEvent) {
    if (this.labelsData) {
      this.labelsDataPage = this.labelsData.slice(
        (evt.page - 1) * evt.pageSize,
        evt.page * evt.pageSize
      );
    }
  }

  private sliceData() {
    if (this.labelsData) {
      this.labelsDataPage = this.labelsData.slice(0, this.pageSize);
    }
  }
}

import {DownloadServiceService} from '../../../services/download-service.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ParameterService} from '../../../services/parameter.service';
import {MachineService} from 'app/services/machine.service';
import {Configuration, CustomProssesInput, Installation, Machine, MachineParams} from '../../../models';
import {Cap} from 'app/models/product-codes';
import {ProductCodesService} from '../../../services/product-codes.service';
import {State} from 'app/models/state';
import {Files} from 'app/models/machine';
import {AfterViewInit, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {ITdDynamicElementConfig} from '@covalent/dynamic-forms';
import {SnackService} from 'app/services/snack.service';
import {IStepChangeEvent, StepState, TdStepComponent} from '@covalent/core/steps';
import {LoadingMode, LoadingType, TdLoadingService} from '@covalent/core/loading';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MachineFormBaseComponent} from '../MachineFormBaseComponent';

@Component({
  selector: 'app-machine-form',
  templateUrl: './machine-form.component.html',
  styleUrls: ['./machine-form.component.scss']
})
export class MachineFormComponent extends MachineFormBaseComponent implements OnInit, AfterViewInit {
  tdStatesName: string[];
  states: string[];
  countries: State[];
  countriesOrig: State[];

  stepState1: StepState = StepState.None;
  wizardActive = true;
  currentYear = new Date().getFullYear();

  @ViewChild('steps') stepscomponent: any;
  @ViewChild('step1') step1: any;
  @ViewChild('step2') step2: TdStepComponent;
  @ViewChild('step3') step3: TdStepComponent;
  @ViewChild('step4') step4: TdStepComponent;
  steps: TdStepComponent[];
  currStep: TdStepComponent;
  currStepIndex = 0;

  // uploadFiles: { file: File; params: string[] }[] = [];
  uploadFiles: Files[] = [];
  uFiles: File | FileList;
  machineParams: MachineParams;
  // paramsToBeCertificated: [{ key: string }][] = [];
  paramsToBeCertificated = {};

  processDescriptorElements: ITdDynamicElementConfig[];

  machineElements: ITdDynamicElementConfig[];

  title = 'Create new Machine';
  emptyCap: Cap = {
    key: null,
    name: 'Others',
    nameIt: 'Altro',
    subs: null
  };

  protected _route: ActivatedRoute;
  protected ds: DownloadServiceService;
  private originalRevision: string;
  private originalYear: number;

  constructor(
    protected router: Router,
    protected _loadingService: TdLoadingService,
    protected parameterService: ParameterService,
    protected productCodesService: ProductCodesService,
    protected machineService: MachineService,
    protected snackService: SnackService,
    private injector: Injector
  ) {
    super(productCodesService, _loadingService,
      snackService, router, machineService);
    this._route = injector.get(ActivatedRoute);
    this.ds = injector.get(DownloadServiceService);
    this._loadingService.create({
      name: 'label.generating',
      mode: LoadingMode.Indeterminate,
      type: LoadingType.Circular,
      color: 'accent'
    });
    this.paramsToBeCertificated['installedPower'] = 1;
    this.paramsToBeCertificated['soundEmissions'] = 1;
    this.paramsToBeCertificated['water'] = 0;
    this.paramsToBeCertificated['powerConsumption'] = 1;
    this.paramsToBeCertificated['compressedAir'] = 0;
    this.paramsToBeCertificated['steam'] = 0;
    this.paramsToBeCertificated['naturalGas'] = 0;
    this.paramsToBeCertificated['auxiliaires'] = 0;
    this.paramsToBeCertificated['bleaches'] = 0;
    this.paramsToBeCertificated['buildersSoftenings'] = 0;
    this.paramsToBeCertificated['tensidesSurfactants'] = 0;
    this.paramsToBeCertificated['inorganic'] = 0;
    this.paramsToBeCertificated['organic'] = 0;
    this.paramsToBeCertificated['salt'] = 0;
    this.paramsToBeCertificated['customProcessInput'] = 0;
  }

  ngOnInit() {
    console.log('steps component', this.stepscomponent);
    this.newMachine();
    this._route.params.subscribe(
      (params: { id: string; rev: string; year: number }) => {
        console.log('CLONE PARAMS', params);
        if (params.rev) {
          this.title = 'Clone machine ';
          this.loadMachine(params);
          // CLONA
        } else if (params.id) {
          // NEW CONFIGURATION
          this.mode = 'newc';
          this.machineService.get(params.id).subscribe(
            res => {
              this.machine = res;
              this.title = 'Add new configuration to machine ' + res.commercialName;
            },
            err => {
              console.log(err);
            }
          );
        }
      });
  }

  filterStates(val: string | State): State[] {
    if (!val) {
      return this.countries;
    } else {
      if (!(val instanceof Object)) {
        return this.countriesOrig.filter((s: State) => s.name.match(new RegExp(val, 'gi')));
      }
    }
  }

  ngAfterViewInit() {
    console.log('steps component after', this.stepscomponent);

    this.init();
  }

  collapsedEvent(obj: any): void {
    if (!obj.valid) {
      console.log('error');
    }
  }

  prevStep() {
    if (this.currStepIndex > 0) {
      this.currStepIndex--;
      this.steps[this.currStepIndex].open();
    }
  }

  nextStep() {
    if (this.currStepIndex < 3) {
      this.currStepIndex++;
      this.steps[this.currStepIndex].open();
    }
  }


  changeStep(event: IStepChangeEvent): void {
    console.log('CHange event', event);
    this.currStepIndex = this.steps.indexOf(event.newStep);
    switch (this.currStepIndex) {
      case 0:
        break;
      case 1:
        this.changeToStep1();
        break;
      case 2:
        this.changeToStep2();
        break;
      case 3:
        this.changeToStep3();
        break;
    }
  }

  changeParamStatus(event: MatCheckboxChange, key: string) {
    let checked = false;
    this.uploadFiles.forEach(up => {
      checked = checked || up.params[key];
    });
    this.paramsToBeCertificated[key] = checked || event.checked ? 2 : 1;
  }

  addNewCPI() {
    const cpi: CustomProssesInput = new CustomProssesInput();
    this.customProssesInputs.push(cpi);
  }

  removeCPI(cpi: CustomProssesInput, index: number) {
    if (index > -1) {
      this.customProssesInputs.splice(index, 1);
    }
  }

  locationDisplayFn(state: State): string {
    return state ? state.name : '';
  }

  downloadOperativeInstructions() {
    this.ds.downloadOperativeInstructions();
  }

  save() {
    if (this.mode === 'newm') {
      // ADD
      this.saveNewMachine();
    } else if (this.mode === 'newc') {
      this.saveConfiguration();
    } else {
      this.cloneConfiguration();
    }
  }



  cloneConfiguration(): void {
    this._loadingService.register('label.generating');
    this.prepareConfiguration();
    console.log('CLONE CONFIGURATION');
    const obs$ = this.machineService
      .cloneConfiguration(
        this.machine,
        this.originalRevision,
        this.originalYear,
        this.configuration,
        this.uploadFiles,
        this.istruzioniOperativeFile
      );
    this.completeConfiguration(obs$);

  }

  saveConfiguration() {
    this._loadingService.register('label.generating');
    this.prepareConfiguration();

    const obs$ = this.machineService
      .saveConfiguration(
        this.machine,
        this.configuration,
        this.uploadFiles,
        this.istruzioniOperativeFile
      )
    this.completeConfiguration(obs$);
  }

  protected init() {
    this.parameterService.loadStates().subscribe(
      res => {
        this.tdStatesName = res.map(s => s.name);
        this.states = this.tdStatesName;
        this.countries = res;
        this.countriesOrig = res;
      },
      err => console.log(err)
    );
    this.parameterService
      .loadMachineConfiguration()
      .subscribe(res => (this.machineParams = res), err => console.log(err));
    this.steps = [this.step1, this.step2, this.step3, this.step4];
    const emptyCap: Cap = {
      key: null,
      name: 'Others',
      nameIt: 'Altro',
      subs: null
    };
    this.productCodesService.getCaps().subscribe(
      res => {
        this.productCodes = res;
        this.productCodes.caps.splice(0, 0, emptyCap);
      },
      err => console.log(err)
    );
  }

  private changeToStep3() {
    let check = false;
    Object.keys(this.paramsToBeCertificated).forEach((key) => {
      if (this.paramsToBeCertificated[key] === 1) {
        check = true;
      }
    });
    if (!check) {
      this.step3.state = StepState.Complete;
    } else {
      this.step3.state = StepState.Required;
      this.step3.open();
    }
  }

  private changeToStep2() {
    if (
      this.processDescriptorForm.valid &&
      this.consumptionForm.valid &&
      this.washingAgentForm.valid &&
      this.chemicalsProcessForm.valid &&
      this.customProcessInputForm.valid
    ) {
      this.step2.state = StepState.Complete;
      if (!this.processDescriptor.workCycle.other) {
        if (this.workCycle) {
          this.processDescriptor.workCycle.productCode = this.workCycle.key;
        } else if (this.sub) {
          this.processDescriptor.workCycle.productCode = this.sub.key;
        } else {
          this.processDescriptor.workCycle.productCode = this.cap.key;
        }
      }

      // processare valori inseriti per richiedere file
      if (this.step3.state === StepState.None) {
        this.changeToStep2FileToBeCertificated();

      }
    } else {
      this.step2.state = StepState.Required;
      this.step2.open();
    }
  }

  private changeToStep2FileToBeCertificated() {
    this.paramsToBeCertificated['installedPower'] = this.processDescriptor
      .installedPower
      ? 1
      : 0;
    this.paramsToBeCertificated['soundEmissions'] = this.processDescriptor
      .soundEmissions
      ? 1
      : 0;
    this.paramsToBeCertificated['water'] = this.consumption.water ? 1 : 0;
    this.paramsToBeCertificated['powerConsumption'] = this.consumption
      .powerConsumption
      ? 1
      : 0;
    this.paramsToBeCertificated['compressedAir'] = this.consumption
      .compressedAir
      ? 1
      : 0;
    this.paramsToBeCertificated['steam'] = this.consumption.steam ? 1 : 0;
    this.paramsToBeCertificated['naturalGas'] = this.consumption.naturalGas
      ? 1
      : 0;
    this.paramsToBeCertificated['auxiliaires'] = this.washingAgent.auxiliaires
      ? 1
      : 0;
    this.paramsToBeCertificated['bleaches'] = this.washingAgent.bleaches
      ? 1
      : 0;
    this.paramsToBeCertificated['buildersSoftenings'] = this.washingAgent
      .buildersSoftenings
      ? 1
      : 0;
    this.paramsToBeCertificated['tensidesSurfactants'] = this.washingAgent
      .tensidesSurfactants
      ? 1
      : 0;
    this.paramsToBeCertificated['inorganic'] = this.chemicalsProcess.inorganic
      ? 1
      : 0;
    this.paramsToBeCertificated['organic'] = this.chemicalsProcess.organic
      ? 1
      : 0;
    this.paramsToBeCertificated['salt'] = this.chemicalsProcess.salt ? 1 : 0;
    this.paramsToBeCertificated['customProcessInput'] =
      this.customProssesInputs.length > 0 ? 1 : 0;
  }

  private changeToStep1() {
    if (!this.machineForm.valid) {
      this.step1.state = StepState.Required;
      this.step1.open();
    } else {
      this.step1.state = StepState.Complete;
    }
  }

  fixSoundEmission() {
    this.processDescriptor.soundEmissions = +this.processDescriptor.soundEmissions.toFixed(1);
  }

  private loadMachine(params: { id: string; rev: string; year: number }) {
    if (params.id) {
      this.mode = 'clone';
      this._loadingService.register('validateConfigurationData');
      this.machineService.get(params.id).subscribe(
        res => {
          this.prepareMachine(res, params);
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  private prepareMachine(res: Machine, params: { id: string; rev: string; year: number }) {
    this.machine = res;
    console.log(res);
    this.title =
      'Clone configuration ' +
      params.rev +
      '.' +
      params.year +
      ' to machine ' +
      res.commercialName;

    this.originalRevision = params.rev;
    this.originalYear = +params.year;

    const bconf: Configuration[] = this.machine.configuration.filter(
      c =>
        c.revision === this.originalRevision &&
        c.year === this.originalYear
    );
    console.log(bconf);
    console.log(params);
    if (bconf && bconf.length > 0) {
      const baseConfiguration = <Configuration>this.deepCopy(bconf[0]);

      this.configuration = baseConfiguration;
      this.configuration.params.processDescriptor.istruzioni_operative_path = null;
      this.processDescriptor =
        baseConfiguration.params.processDescriptor;
      this.configuration.revision += '-clone';
      console.log(this.processDescriptor);
      if (this.processDescriptor.workCycle.other) {
        this.cap = this.emptyCap;
      } else {

        const capK = this.processDescriptor.workCycle.productCode.split('.');
        this.cap = this.productCodes.caps.filter(
          c => c.key === capK[0]
        )[0];
        this.productCodesService
          .getSubs(this.cap.key)
          .subscribe(r => {
            this.capSubs = r.caps[0];
            this.sub = this.capSubs.subs.filter(
              s => s.key === capK[0] + '.' + capK[1]
            )[0];
            this.workCycle = this.sub.workCycles.filter(
              w => w.key === capK[0] + '.' + capK[1] + '.' + capK[2]
            )[0];
          });
      }
      this.consumption = baseConfiguration.params.consumption;
      this.washingAgent = baseConfiguration.params.washingAgents;
      this.chemicalsProcess =
        baseConfiguration.params.chemicalsProcess;
      this.customProssesInputs = [];
      this.installation = new Installation();
      this.uploadFiles = baseConfiguration.files.map(f => {
        const _f = new Files();
        _f.file = f.file;
        _f.path = f.path;
        _f.params = [];
        f.params.forEach((_p, i) => (_f.params[i] = 'true'));
        return _f;
      });
      this.changeToStep2FileToBeCertificated();
      this.machineForm.controls['revision'].markAsTouched();
      this.machineForm.controls['year'].markAsTouched();
      this.validateRevision();
      this.machineForm.control.markAsTouched();
      this.machineForm.controls['revision'].markAsTouched();
      this.machineForm.controls['year'].markAsTouched();
      this._loadingService.resolve('validateConfigurationData');
    }
  }

  deepCopy(obj) {
    let copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || 'object' !== typeof obj) {
      return obj;
    }

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (let i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.deepCopy(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (const attr in obj) {
        if (obj.hasOwnProperty(attr)) {
          copy[attr] = this.deepCopy(obj[attr]);
        }
      }
      return copy;
    }

    throw new Error('Unable to copy obj! Its type isn\'t supported.');
  }
}

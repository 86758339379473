<div layout-gt-sm="row" tdMediaToggle="gt-xs" [mediaClasses]="['push-sm']">
  <div flex-gt-sm>
    <mat-card tdMediaToggle="gt-xs" [mediaClasses]="['push']">
      <mat-card-title>New Message</mat-card-title>
      <mat-divider></mat-divider>
      <mat-card-content class="push-bottom-none">

        <div layout-gt-xs="row">
          <div flex-gt-xs="30">
            <ng-template tdLoading="loading.companies">
              <div layout-gt-sm="row">
                <mat-checkbox [(ngModel)]="checkAllMachine" (change)="checkAll($event)" flex="30">
                  <span>All</span>
                </mat-checkbox>
                <td-search-input flex="70" placeholder="Search here" [showUnderline]="true" (searchDebounce)="deb($event)"
                  >
                </td-search-input>
              </div>
              <mat-list dense style="overflow:auto; max-height:350px">
                <mat-list-item *ngFor="let c of filteredCompanies">
                  <mat-checkbox [(ngModel)]="c['msg']">
                    <span>{{c.name}}</span>
                  </mat-checkbox>
                </mat-list-item>
              </mat-list>
            </ng-template>
          </div>
          <div flex-gt-xs="70">
            <div layout="column">
              <mat-form-field flex>
                <input matInput required type="text" [(ngModel)]="message.object" placeholder="Object">
              </mat-form-field>
              <mat-form-field flex>
                <textarea rows="5" matInput required [(ngModel)]="message.message" placeholder="Description"></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>

      </mat-card-content>
      <mat-card-actions layout="row" layout-align="end center">
        <button color="accent" class="text-upper" mat-button (click)="send()">Send</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

import { TitleService } from 'app/services/title.service';
import { CountryMap, Labels, ChartData } from './../../../models/chart-data';
import { SnackService } from './../../../services/snack.service';
import { StatisticsService } from './../../../services/statistics.service';
import { environment } from 'environments/environment';
import { Component, OnInit } from '@angular/core';
import { CompanyDialogComponent } from 'app/components/company-dialog/company-dialog.component';
import { TrendCluster, TrendCompanyCluster } from 'app/models';
import {TdLoadingService} from '@covalent/core/loading';
import {MatDialog} from '@angular/material/dialog';
import { filterLabels } from 'app/shared/utils';


@Component({
  selector: 'app-adm-dashboard',
  templateUrl: './adm-dashboard.component.html',
  styleUrls: ['./adm-dashboard.component.css']
})
export class AdmDashboardComponent implements OnInit {
  colorScheme = {
    domain: environment.color_scheme
  };
  colorScheme2 = {
    domain: environment.color_scheme_2
  };
  trendData: any[];
  trendDataForCompaniesSet: any[];
  labelsForCompaniesData: any[];
  numbersData: any[];
  countryMapData: CountryMap[];
  labelsData: Labels[];
  filteredLabelsData: Labels[];
  comparesData: ChartData[];
  showLabelTrend = false;
  legend: any[];
  trendCluster: TrendCluster[];
  trendCompanyCluster: TrendCompanyCluster[];
  trendMode: 'trendCluster' | 'trendCompanyCluster';

  constructor(
    private titleService: TitleService,
    private statisticsService: StatisticsService,
    private snackService: SnackService,
    private _loadingService: TdLoadingService,
    private matDialog: MatDialog
  ) {
    this.titleService.setTitle('Admin Dashboard');
  }

  ngOnInit() {
    this.labelTrend();
    // this.loadLabelTrendForCompaniesSet(['594b6b584dd7d79d54c1a084','594b6b584dd7d79d54c1a087','594b6b584dd7d79d54c1a088'])
    this.labelsForCompanies();
    this.loadNumbers();
    this.loadCountryMap();
    this.listLabels();
  }

  filterLabels(code: string) {
    this.filteredLabelsData = filterLabels(code, this.labelsData);
  }
  listLabels() {
    this._loadingService.register('load.labels');
    this.statisticsService.getLabels().subscribe(
      res => {
        this.labelsData = res;
        this.filteredLabelsData = res;
      },
      err => this.snackService.notifyErr(err),
      () => this._loadingService.resolve('load.labels')
    );
  }

  loadCountryMap() {
    this._loadingService.register('load.country.map');
    this.statisticsService.getCountryMap().subscribe(
      res => {
        this.countryMapData = res;
      },
      err => this.snackService.notifyErr(err),
      () => this._loadingService.resolve('load.country.map')
    );
  }

  loadNumbers() {
    this._loadingService.register('load.numbers.chart');
    this.statisticsService.getNumbers().subscribe(
      res => {
        this.numbersData = res;
      },
      err => this.snackService.notifyErr(err),
      () => this._loadingService.resolve('load.numbers.chart')
    );
  }

  labelsForCompanies() {
    this._loadingService.register('load.company-labels.chart');
    this.statisticsService.getLabelForCompanies().subscribe(
      res => {
        this.labelsForCompaniesData = res;
        console.log(res);
      },
      err => this.snackService.notifyErr(err),
      () => this._loadingService.resolve('load.company-labels.chart')
    );
  }

  labelTrend() {
    // this._loadingService.register('load.trend.chart');
    // this.statisticsService.getLabelTrend().subscribe(
    //   res => {
    //     this.trendDataForCompaniesSet = res;

    //     this.showLabelTrend = false;
    //     // console.log('Trend');
    //     // console.log(res);
    //   },
    //   err => this.snackService.notifyErr(err),
    //   () => this._loadingService.resolve('load.trend.chart')
    // );

    this._loadingService.register('load.trend.chart');
    this.statisticsService.getTrendCluster().subscribe(
      res => {
        this.trendCluster = res;
        this.trendMode = 'trendCluster';
      },
      err => this.snackService.notifyErr(err),
      () => this._loadingService.resolve('load.trend.chart')
    );
  }

  openDialog() {
    this.matDialog.open(CompanyDialogComponent)
      .afterClosed()
      .subscribe(
        res => {
          this.loadLabelTrendForCompaniesSet(res);
        },
        err => this.snackService.notifyErr(err)
      );
  }

  loadLabelTrendForCompaniesSet(ids: string[]) {
    if (ids) {
      this._loadingService.register('load.trend.chart');
      this.statisticsService.getTrendClusterForCompaniesSet(ids).subscribe(
        res => {
          this.trendCompanyCluster = res;
          this.showLabelTrend = true;
          this.trendMode = 'trendCompanyCluster';
        },
        err => this.snackService.notifyErr(err),
        () => this._loadingService.resolve('load.trend.chart')
      );
    }
  }
}

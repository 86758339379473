import { CompanyService } from '../services/company.service';
import { Directive, ElementRef, OnInit, Input } from '@angular/core';
@Directive({
    selector: '[lazy-image-load]'
})
export class LazyImageDirective implements OnInit {

    @Input('data-source') dataSource: string;

    constructor(public el: ElementRef, private cs: CompanyService) {}

    ngOnInit() {
        if ((this.el.nativeElement.nodeName.toLowerCase() !== 'img')) {
            console.warn('imagelazyload directive applied to a non Image element');
        } else {
            this.displayImage();
        }
    }

    displayImage() {
        const nativeElement = this.el.nativeElement;

        nativeElement.onload = () => {
            nativeElement.classList.remove('loading');
            nativeElement.classList.add('loaded');
        };

        const source = this.cs.getLogoUrl(this.dataSource);

        nativeElement.classList.add('loading');
        nativeElement.setAttribute('src', source);
    }
}

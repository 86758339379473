<mat-expansion-panel expanded="true">

  <div>
    <p>
      <strong>PRIVACY NOTICE</strong>
    </p>
    <p>
      Pursuant to art 13 of Regulation
      (EU) 2016/679 (hereinafter, the “<strong>GDPR</strong>”)
      the Client is hereby informed that its data will be processed by the
      following means and for the following purposes:
    </p>

    <ol>
      <li>
        <strong>DATA CONTROLLER</strong>
        <p>
          RINA CONSULTING S.p.A., whose registered office is in Genoa (Italy), via Cecchi 6, Tax code and VAT n°
          03476550102, as well as the Company(ies) of the RINA Group are Joint Controllers according to art.26 GDPR,
          available via the contact details shown on the
          <u><a href="https://www.rina.org/">www.rina.org</a> </u>
          website, or via the e-mail address for the Data Protection Officer
          <u><a href="mailto:rina.dpo@rina.org">rina.dpo@rina.org</a></u>.
        </p>
      </li>
      <li>
        <p>
          <strong>PURPOSE OF PROCESSING </strong>
        </p>
        <p style="text-align: justify">
          The Joint Controllers process the personal data (hereinafter, “<strong>personal data</strong>” or also
          “<strong>data</strong>”)
          provided by the Client:
        </p>
        <ol type="a">
          <li><p style="text-align: justify">
            <u>Without having to obtain your explicit consent</u>, for the following purposes:
          </p>
            <ul>
              <li><p style="text-align: justify">
                pre-contractual activities
                aimed at the proposal of offers and the establishment of the
                contractual relationship for the provision of the Controller's
                services (such as in the case of contact data collected directly by
                the data subject or publicly available or communicated from third
                parties expressly authorized by the data subject necessary for
                registration and access to restricted areas on online platforms or
                mobile applications);</p>
              <li><p style="text-align: justify">
                to fulfil contractual,
                administrative and fiscal obligations arising from relationships in
                force (like in the case of tax data for the issue of the invoice
                and the data relating to the electronic payment communicated by
                third parties such as e-payment service providers);</p>
              <li><p style="text-align: justify">
                fulfil the obligations
                established by the specific contractual terms of the requested
                service and/or by any binding accreditation and/or certification
                requirements (such as the collection of audit evidence or other
                supporting documentation, also containing special categories of
                personal data defined at art. 9 GDPR such as health certificates,
                if required by law or by reference standard and therefore mandatory
                to obtain the service itself, provided both through traditional and
                digital methodologies, or furnished by remote tools);</p>
              <li><p style="text-align: justify">
                to fulfil the obligations
                imposed by law or by an order of the competent Authority;
              </p>
              <li><p>
                to exercise the Joint
                Controller’s rights, such as the right of defence in a lawsuit.
              </p>
            </ul>
          <li><p style="text-align: justify">
            <u>Only with your prior consent</u>,
            for the following promotional purposes:
          </p>
            <ul>
              <li><p style="text-align: justify">
                to send you e-mails, post, sms
                and/or telephone calls, newsletters, commercial communications,
                and/or advertising materials on products or services offered by the
                Company, and to measure the level of satisfaction with the quality
                of such services.
              </p>
              <li><p style="text-align: justify">
                to use your images, photos and
                videos, taken on the occasion of the provision of the service, to
                document the success of the initiative, with the possibility of
                publication on the website www.rina.org, on the Company's official
                social media channels, or others external communication channels
                selected from time to time.</p>
            </ul>
        </ol>
      </li>
      <li><p>
        <strong>PROCESSING METHOD </strong>
      </p>
        <p>
          Your personal data will be
          processed in accordance with the principles of lawfulness, fairness
          and transparency.
        </p>
        <p style="text-align: justify">
          The processing activity consist
          of the following operations: collection, recording, organisation,
          structuring, storage, consultation, adaptation or alteration, use,
          dissemination, disclosure by transmission, retrieval, alignment or
          combination, restriction, erasure or destruction of the data. Your
          personal details are subjected to both hard-copy and electronic
          processing.
        </p>
        <p style="text-align: justify">
          The personal data will be stored
          for the time necessary to carry out the purposes indicated above and,
          in any case, for not more than 30 years from termination of
          contractual relations and not more than 2 years from collecting data
          for marketing purposes.
        </p>
        <p style="text-align: justify">
          Once 10 years have passed since
          the contractual relations have ceased, access to the data will be
          limited to heads of departments.
        </p>
        <p style="text-align: justify">
          Should the Joint Controllers have
          a documented need to store the data for a period longer than 30 years
          (e.g. if erasure could compromise its legitimate right to defence or
          in general, to safeguard its company assets), such further storage
          shall take place, limiting access to said data to the head of the
          legal department only, in order to guarantee the legitimate
          exercising of the right of defence in a lawsuit.
        </p>
      </li>
      <li><p>
        <strong>RECIPIENTS OF THE DATA</strong></p>
        <p style="text-align: justify">
          Your data may be made accessible
          for the purposes indicated in art 2.a and 2.b to the following
          recipients:
        </p>
        <ul>
          <li><p style="text-align: justify">
            affiliate companies or
            subsidiaries of RINA Group, in Italy and abroad, to the extent to
            which this is necessary for processing, in conformity to the binding
            corporate rules adopted by RINA Group;
          </p></li>
          <li><p style="text-align: justify">
            to companies or other third
            parties (credit institutions, professionals, consultants, insurance
            companies for the provision of insurance services, surveillance
            companies etc.) who carry out activities on behalf of the Joint
            Controllers;</p>
          </li>
          <li><p style="text-align: justify">
            to public entities, supervisory
            bodies, judicial and control authorities, accreditation or
            notification bodies, auditing firms etc. for the fulfilment of legal
            obligations or sector regulators.</p></li>
        </ul>
      </li>
      <li><p>
        <strong>TRANSFERS OF DATA </strong>
      </p>
        <p style="text-align: justify">
          Personal data are stored on
          servers located within the European Union. In any case, it is
          understood that, should this be necessary, the Joint Controllers will
          have the right to move the servers even outside the EU. In such a
          case, that transfers of data outside the EU will be done in
          accordance with the applicable laws, also by means of including
          standard contractual clauses provided for by the European Commission,
          and adopting binding corporate rules for intra-group transfers.
        </p>
      </li>
      <li><p>
        <strong>LEGAL BASIS OF THE PROCESSING</strong></p>
        <p style="text-align: justify">
          The provision of data and related
          processing for the purposes indicated in art<strong>.
        </strong>2.a is necessary in
          order to guarantee the Controller’s services you have requested,
          and for implementing the contract and any pre-contractual
          obligations. The legal basis of the afore mentioned processing can be
          found in art. 6, par. 1, b) GDPR. Any refusal will make it impossible
          for the Joint Controllers to provide the services covered by the
          contract.
        </p>
        <p style="text-align: justify">
          Providing data for the purposes
          indicated in art. 2.b, on the other hand, is not mandatory. You may,
          therefore, decide not to provide any data or subsequently refuse
          processing of data already provided. The legal basis of the afore
          mentioned treatment can be found in art. 6, par. 1, a) GDPR, the only
          consequence of such refusal will be that receiving newsletters,
          commercial communications, and advertising materials related to the
          services offered by the Joint Controllers will not be possible.
          However, you will continue to have the right to the services
          indicated in art. 2.a.</p>
      </li>
      <li><p>
        <strong>RIGHTS OF THE DATA SUBJECT</strong></p>
        <p style="text-align: justify">
          As the data subject, you have the
          right to:
        </p>
        <ol type="i">
          <li><p style="text-align: justify">
            obtain confirmation of whether
            personal data regarding you are processed or not, as well as to
            obtain a copy of said data;
          </p>
          </li>
          <li><p style="text-align: justify">
            obtain an indication of: a) the
            source of the personal data; b) the purposes and means of
            processing; c) the logic <span lang="en-US">involved
		</span>in the case of
            processing done with the help of electronic instruments; d) the
            identity and the contact details of the Joint Controllers, the data
            processors and the data protection officer; e) the recipients or
            categories of recipients to which the personal data can be
            communicated, or who can know them as the designated representative
            within the territory of the State, processors, or employees who
            carry out processing;
          </p>
          </li>
          <li><p style="text-align: justify">
            obtain: a) updating,
            rectification, or completion of the data; b) erasure,
            transformation into an anonymous form or blocking of data processed
            in violation of laws; c) certification that the operations referred
            to in letters a) and b) have been made known, also in relation to
            their content, to those to whom the data have been communicated or
            disclosed by transmission, unless this is impossible or involves a
            disproportionate effort; d) a structured format, from the Joint
            Controllers, commonly used and provided in an intelligible and
            easily accessible form with the personal data related to you, and,
            where technically feasible, to obtain transmission of said data
            <span lang="en-US">directly
		from one controller to another</span>;

          </p>
          </li>
          <li><p style="text-align: justify">
            object to: a) processing of
            your personal data, even if pertinent to the purpose for which they
            were collected. b) processing of your personal data for the
            purposes of sending advertising or direct sales materials, or for
            carrying out market research or commercial communication, using
            automated telephone calling systems without an operator, by e-mail
            and or by means of traditional telephone and/or hard copy postal
            marketing methods. Such right of object may also be exercised only
            in part, thereby allowing the data subjects to choose whether to
            receive only communications using traditional means or only
            automated communications, or neither of the two types of
            communication.
          </p>
          </li>
          <li><p style="text-align: justify">
            Therefore, in your capacity as
            Data Subject, you have the rights pursuant to Arts. 15 – 21 of
            GDPR, as well as the right to lodge a complaint with the competent
            Authority pursuant to art 77 of GDPR.
          </p>
          </li>
        </ol>
      </li>
      <li><p>
        <strong>PROCEDURE FOR EXERCISING
          RIGHTS AND COMMUNICATIONS</strong></p>
        <p style="text-align: justify">
          The RINA Group has appointed a
          Data Protection Officer, who can be contacted for all matters related
          to processing of your personal data and the exercising of related
          rights.
        </p>
        <p style="text-align: justify">
          Therefore, you may contact the
          Data Protection Officer at any time, using the following procedure:
        </p>
        <ol type="a">
          <li>
            <ul>
              <li><p style="text-align: justify">
                by sending a registered letter
                with notification of receipt to RINA S.p.A., via Corsica 12, 16128
                Genova, for the attention of the Data Protection Officer, or by</p>
              </li>
              <li><p style="text-align: justify">
                by sending an e-mail message to
                <u><a href="mailto:rina.dpo@rina.org">rina.dpo@rina.org</a></u>.</p>
              </li>
            </ul>
          </li>
        </ol>
      </li>
    </ol>

  </div>


  <div>

    <p style="text-align: justify">
      We wish to state that you havethe right to withdraw the consent given at any time by writing to
      <u>rina.<a href="mailto:rina.dpo@rina.org">rina.dpo@rina.org</a>.</u>
    </p>

    <p style="text-align: justify">
      Yours sincerely,
    </p>

    <p style="text-align: justify">
      <mat-checkbox [(ngModel)]="checks.mandatoryCheck" disabled="true">I declare that I have examined the notice
        and accept its contents.
      </mat-checkbox>
    </p>


    <p style="text-align: justify">
      For the purpose of receiving
      e-mails, post, sms and/or telephone calls, newsletters, commercial
      communications, and/or advertising materials on products or services
      the Joint Controllers offer, and to measure the degree of
      satisfaction with the quality of such services (non mandatory
      consent).</p>
    <mat-checkbox [(ngModel)]="checks.receiveMailCheck">I agree</mat-checkbox>


    <p style="text-align: justify">
      For the purpose of using
      your images, photos and videos, taken on the occasion of the
      provision of the service, to document the success of the initiative,
      with the possibility of publication on the website www.rina.org, on
      the Company's official social media channels, or others external
      communication channels selected from time to time (non mandatory
      consent)..</p>
    <mat-checkbox [(ngModel)]="checks.usingImagesCheck">I agree</mat-checkbox>
  </div>
  <div>
    <button mat-flat-button color="accent" class="text-upper" (click)="save()">Save</button>
  </div>
</mat-expansion-panel>

import {Labels} from '../models';

export function filterLabels(code: string, labelsData: Labels[]): Labels[] {
  let filteredLabelsData;
  if (code && code !== '') {
    filteredLabelsData = labelsData.filter(l => {
      return l.installations.targaCode.indexOf(code) >= 0;
    });
  } else if (!code || code === '') {
    filteredLabelsData = labelsData;
  }
  console.log(filteredLabelsData);
  return filteredLabelsData;
}

import { DomSanitizer } from '@angular/platform-browser';
import { Company } from './../models';
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { BaseService } from './base-service';
import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class CompanyService extends BaseService {
    constructor(private http: HttpClient, public sanitizer: DomSanitizer) {
        super();
    }

    findAll(): Observable<Company[]> {
        const pageUrl: string = environment.api_endpoint + 'companies/';
        return this.http
            .get<Company[]>(pageUrl)
            ;
    }

    findById(id: string): Observable<Company> {
        const pageUrl: string = environment.api_endpoint + 'companies/' + id;
        return this.http
            .get<Company>(pageUrl)
            ;
    }

    delete(id: string): Observable<Company> {
        const pageUrl: string = environment.api_endpoint + 'companies/trash/' + id;
        return this.http
            .delete<Company>(pageUrl)
            ;
    }
    getLogoUrl(id: string): string {
        return environment.api_endpoint + 'companies/' + id + '/logo';
    }

    save(company: Company, logo: File): Observable<Company> {
        const form = new FormData();
        if (logo) {
            form.append('logo', logo, logo.name);
        } else {
            form.append('logo', logo);
        }
        const b: Blob = new Blob([JSON.stringify(company)], { type: 'application/json' });
        form.append('company', b);
        const pageUrl: string = environment.api_endpoint + 'companies/';
        return this.http
            .post<Company>(pageUrl, form)
            ;
    }

}

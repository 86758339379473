<div *ngIf="chart && chart.series" style="height:250px">
  <ngx-charts-bar-vertical [scheme]="colorScheme" [results]="chart.series" xAxis="true" yAxis="true" [legend]="false" showXAxisLabel="true"
    showYAxisLabel="true" [xAxisLabel]="xLabel" yAxisLabel="Generated Labels">
  </ngx-charts-bar-vertical>
</div>


<div fxLayout="row">
  <div flex="50">
    Month
    <mat-slide-toggle [checked]="mode==='year'" (change)="switchMode($event)"></mat-slide-toggle>
    Year
  </div>
  <div flex="50">
    <div *ngIf="mode==='month' && yearList">
      Change Year
      <mat-select placeholder="Select the year" (selectionChange)="changeYear($event)" [(ngModel)]="year" [(value)]="year">
        <mat-option *ngFor="let y of yearList" [value]="y">
          {{y}}
        </mat-option>
      </mat-select>
    </div>
  </div>
</div>

<h2 mat-dialog-title>Select Configurations</h2>

<mat-dialog-content>
  <h4>{{count}}/3 configuration selected</h4>
  <ng-template tdLoading="loading.machines">
    <div style="max-height: 300px; overflow:auto">
      <ng-template let-m let-last="last" ngFor [ngForOf]="machines">

        <td-expansion-panel label="{{m.commercialName}}" [expand]="false">

          <mat-list dense>
            <ng-template let-c let-lastC="last" ngFor [ngForOf]="m.configuration">
              <mat-list-item>
                <mat-checkbox matLine class="example-margin" (change)="checkClick($event,c)" [(ngModel)]="c['selected']">{{c.revision}}.{{c.year}}</mat-checkbox>
              </mat-list-item>
            </ng-template>
          </mat-list>
        </td-expansion-panel>
      </ng-template>
    </div>
  </ng-template>
</mat-dialog-content>


<mat-dialog-actions>
  <button mat-button class="text-upper" (click)="close()">Close</button>
  <!-- Can optionally provide a result for the closing dialog. -->
  <button mat-button color="accent" class="text-upper" (click)="selectConfigurations()">Select</button>
</mat-dialog-actions>

<mat-card tdMediaToggle="gt-xs" [mediaClasses]="['push']">
  <mat-card-title>Green Label</mat-card-title>
  <mat-divider></mat-divider>
  <mat-card-content class="push-bottom-none">
    <h2>Green Label for
      <span color="primary"> {{machine?.commercialName}}</span>
    </h2>
    <h4>{{targa?.targaCode}}</h4>
    <h4>{{targa?.location.name}} ({{targa?.generationDate | timeAgo}})</h4>
    <h2>Carbon Footprint: kg {{targa?.footprint.value | scientificNotation}} of CO
      <sub>2</sub> Eq/kg processed material </h2>
      <div *ngIf="configuration?.url">
          <a mat-button matTooltip="Open Green Label" matTooltipPosition="below" [href]="configuration.url">
            <mat-icon>open_in_new</mat-icon>
            <span>{{configuration.url}}</span>
          </a>

      </div>
      <mat-divider></mat-divider>

  </mat-card-content>
  <mat-card-actions layout-align="start">
    <button mat-raised-button color="primary" (click)="downloadLabel()">
      <mat-icon class="mat-48">file_download</mat-icon>Green-Label</button>
    <button mat-raised-button color="primary" (click)="downloadReport()">
      <mat-icon class="mat-48">file_download</mat-icon>Report</button>
    <button mat-raised-button color="primary" (click)="downloadCommunicationMaterials()">
      <mat-icon class="mat-48">file_download</mat-icon>Logos</button>
  </mat-card-actions>
</mat-card>

import { TitleService } from '../../../services/title.service';
import { DownloadServiceService } from './../../../services/download-service.service';
import { MachineService } from 'app/services/machine.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Installation, Machine, Configuration } from 'app/models/machine';
import {LoadingMode, LoadingType, TdLoadingService} from '@covalent/core/loading';

@Component({
  selector: 'app-green-label-report',
  templateUrl: './green-label-report.component.html',
  styleUrls: ['./green-label-report.component.scss']
})
export class GreenLabelReportComponent implements OnInit {

  machineId: string;
  labelId: string;
  targa: Installation;
  machine: Machine;
  configuration: Configuration;
  constructor(private _loadingService: TdLoadingService, private _route: ActivatedRoute,
    private machineService: MachineService, private ds: DownloadServiceService, private titleService: TitleService) {
    this.titleService.setTitle('Green-Label Download');
    this._loadingService.create({
      name: 'label.loading',
      mode: LoadingMode.Indeterminate,
      type: LoadingType.Circular,
      color: 'accent',
    });
  }

  ngOnInit() {
    this._route.params.subscribe((params: { id: string, labelId: string }) => {
      this.machineId = params.id;
      this.labelId = params.labelId;
      this._loadingService.register('label.loading');

      this.machineService.get(params.id).subscribe(
        res => {
          this.machine = res;
          this.machine.configuration.some(
            c => {
              c.installations.some(i => {
                if (i.targaCode === params.labelId) {
                  this.targa = i;
                  this.configuration = c;
                  return true;
                }
                return false;
              });
              return !!this.targa;
            }
          )
          this.titleService.setTitle('Green-Label Download ' + params.labelId);
        },
        err => console.log(err),
        () => this._loadingService.resolve('label.loading')
      );


    });
  }

  downloadLabel() {
    this.ds.downloadLabel(this.machineId, this.labelId);
  }

  downloadReport() {
    this.ds.downloadReport(this.machineId, this.labelId);
  }
  downloadCommunicationMaterials() {
    this.ds.downloadCommunicationMaterialsNew(this.machineId, this.labelId);
  }

}

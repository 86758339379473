<ng-template tdLoading="user.list">
  <mat-list class="will-load">
    <div class="mat-padding" *ngIf="!filteredUsers || filteredUsers.length === 0" layout="row"
         layout-align="center center">
      <h3>No users to display.</h3>
    </div>
    <ng-template let-user let-last="last" ngFor [ngForOf]="filteredUsers">
      <mat-list-item>
        <mat-icon class="material-icons icon icon-{{user.role}}" matTooltip="{{user.role}}" matTooltipPosition="above"
                  mat-list-avatar>person
        </mat-icon>
        <h3 mat-line> {{user.lastName}} {{user.firstName}} </h3>
        <p mat-line> {{user.email}} </p>
        <span>
          <button mat-icon-button [mat-menu-trigger-for]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu x-position="before" #menu="matMenu">
                <button (click)="deleteUser(user.id)" mat-menu-item>Delete</button>
              </mat-menu>
        </span>
      </mat-list-item>
      <mat-divider *ngIf="!last" mat-inset></mat-divider>
    </ng-template>
  </mat-list>
</ng-template>

import { Component, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { TitleService } from 'app/services/title.service';
import {TdMediaService} from '@covalent/core/media';

@Component({
  selector: 'app-frontend',
  templateUrl: './frontend.component.html',
  styleUrls: ['./frontend.component.scss']
})
export class FrontendComponent implements AfterViewInit {
  _title = 'Green Label';

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    public media: TdMediaService,
    private titleService: TitleService
  ) { }

  ngAfterViewInit(): void {
    // broadcast to all listener observables when loading the page
    setTimeout(() => { // workaround since MatSidenav has issues redrawing at the beggining
      this.media.broadcast();
      this._changeDetectorRef.detectChanges();
    });
  }

  public getTitle(): string {
    return this.titleService.getTitle();
  }
}

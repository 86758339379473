import { Machine } from 'app/models/machine';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'targa-report',
  templateUrl: './targa-report.component.html',
  styleUrls: ['./targa-report.component.scss']
})
export class TargaReportComponent {

  @Input()
  machine: Machine;

}

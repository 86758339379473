import { TitleService } from '../../services/title.service';
import { SnackService } from './../../services/snack.service';
import { Message } from './../../models/message';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from './../../services/message.service';
import { Component, OnInit } from '@angular/core';
import {TdLoadingService} from '@covalent/core/loading';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit {
  private messageId: string;
  messages: Message[];

  message: Message;

  constructor(
    private _loadingService: TdLoadingService,
    private messageService: MessageService,
    private _route: ActivatedRoute,
    private _router: Router,
    private titleService: TitleService,
    private snackService: SnackService
  ) {
    this.titleService.setTitle('Messages');
  }

  ngOnInit() {
    this._loadingService.register('message.list');
    this.messageService.getMyMessages().subscribe(
      res => {
        this.messages = res;
        this._route.params.subscribe((params: { id: string }) => {
          if (params.id) {
            this.messageId = params.id;
            this.message = this.messages.find(x => x.id === this.messageId);
            if (this.message.to[0].status === false) {
              this.selectMessage(this.message);
            }
          }
        });
      },
      err => this.snackService.notifyErr(err),
      () => this._loadingService.resolve('message.list')
    );
  }

  selectMessage(m: Message) {
    this.message = m;
    this.messageService
      .markAsRead(this.message)
      .subscribe(
      res => (this.message = res),
      err => this.snackService.notifyErr(err)
      );
    window.history.pushState('Message', 'Message', '/messages/' + m.id);
  }
}

import {Role} from './../models/role';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {User} from 'app/models';
import {SecurityService} from '../services/security.service';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {map, switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';

@Injectable()
export class RoleGuard implements CanActivate {

  constructor(private router: Router, private security: SecurityService, private auth: OidcSecurityService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    const roles = route.data['roles'] as Array<Role>;

    return this.auth.isAuthenticated$.pipe(
      switchMap((isAuthorized) => {
        console.log('auth', isAuthorized);
        if (isAuthorized) {
          return this.auth.userData$;
        } else {
          return of(false);
        }
      }),
      map((a) => {
          if (typeof a === 'boolean') {
            this.router.navigate(['/error/unauthorized']);
            return false;
          } else {
            const user: User = this.security.getUserInfo();
            const role: string = user.role;

            const rs: Array<Role> = roles.filter(r => r.key === role);
            let can = false;
            if (rs && rs[0]) {
              if (rs[0].check === false) {
                can = true;
              } else {
                can = route.params['id'] === user.company.id;
              }
            }
            if (can === false) {
              this.router.navigate(['/error/unauthorized']);
            }
            return can;
          }
        }
      ));

  }
}


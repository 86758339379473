<div *ngIf="chart && chart" style="height:250px">
  <ngx-charts-bar-vertical-2d [scheme]="colorScheme" [results]="chart" xAxis="true" yAxis="true" [legend]="false" showXAxisLabel="true"
    showYAxisLabel="true" [xAxisLabel]="xLabel" yAxisLabel="Generated Labels" barPadding="4" groupPadding="8">
  </ngx-charts-bar-vertical-2d>
</div>

<div fxLayout="row" flex *ngIf="legend" style="text-align:center">
  Legend -
  <ng-template let-l let-last="last" ngFor [ngForOf]="legend">
    <span [style.color]="l.color">{{l.name}}</span>
  </ng-template>
</div>

<div fxLayout="row">
  <div flex="50">
    Month
    <mat-slide-toggle [checked]="mode==='year'" (change)="switchMode($event)"></mat-slide-toggle>
    Year
  </div>
  <div flex="50">
    <div *ngIf="mode==='month' && yearList">
      Change Year
      <mat-select placeholder="Select the year" (selectionChange)="changeYear($event)" [(ngModel)]="year" [(value)]="year">
        <mat-option *ngFor="let y of yearList" [value]="y">
          {{y}}
        </mat-option>
      </mat-select>
    </div>
  </div>
</div>

import { TitleService } from './../../services/title.service';
import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { User } from 'app/models';
import { SecureStorageService } from 'app/services/secure-storage.service';
import {TdMediaService} from '@covalent/core/media';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, AfterViewInit {
  user: User;
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    public media: TdMediaService,
    private secureStorage: SecureStorageService,
    private titleService: TitleService
  ) {
    this.titleService.setTitle('Administration');
  }

  ngAfterViewInit(): void {
    // broadcast to all listener observables when loading the page
    setTimeout(() => { // workaround since MatSidenav has issues redrawing at the beggining
      this.media.broadcast();
      this._changeDetectorRef.detectChanges();
    });
  }

  ngOnInit() {
    this.user = JSON.parse(this.secureStorage.retrieve('user'));
  }

  getTitle(): string {
    return this.titleService.getTitle();

  }
}

import {PrivacyCheck, User} from '../models';
import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {BaseService} from './base-service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class UserService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  updatePrivacy(privacyCheck: PrivacyCheck) {
    const pageUrl: string = environment.api_endpoint + 'users/privacy';
    return this.http.post<User>(pageUrl, privacyCheck);
  }

  getUserById(id: string): Observable<User> {
    const pageUrl: string = environment.api_endpoint + 'users/find-logged-by-id/?id=' + id;
    return this.http.get<User>(pageUrl)
      ;
  }

  findById(userId: string): Observable<User> {
    const pageUrl: string = environment.api_endpoint + 'users/' + userId;
    return this.http.get<User>(pageUrl)
      ;
  }

  findByCompanyId(companyId: string): Observable<User[]> {
    const pageUrl: string = environment.api_endpoint + 'users/find-by-company/?id=' + companyId;
    return this.http.get<User[]>(pageUrl)
      ;
  }

  delete(id: string): Observable<User> {
    const pageUrl: string = environment.api_endpoint + 'users/' + id;
    return this.http.delete<User>(pageUrl)
      ;
  }

  save(user: User, companyId: string, action: string): Observable<User> {
    const pageUrl: string = environment.api_endpoint + 'users/?companyId=' + companyId;
    if (action === 'add') {
      return this.http.post<User>(pageUrl, user);
    } else {
      return this.http.put<User>(pageUrl, user);
    }
  }

  preCreate(user: User, companyId: string, action: string): Observable<User> {
    const pageUrl: string = environment.api_endpoint + 'users/pre-create?companyId=' + companyId;
    if (action === 'add') {
      return this.http.post<User>(pageUrl, user);
    } else {
      return this.save(user, companyId, action);
    }
  }

}

import {Injectable} from '@angular/core';

import {BaseService} from 'app/services/base-service';
import {environment} from 'environments/environment';
import {SnackService} from 'app/services/snack.service';
import {HttpClient} from '@angular/common/http';
import {LoadingMode, LoadingType, TdLoadingService} from '@covalent/core/loading';
import {FileSaverService} from 'ngx-filesaver';

@Injectable()
export class DownloadServiceService extends BaseService {

  constructor(private http: HttpClient, private _loadingService: TdLoadingService, private snackService: SnackService,
              private fileSaver: FileSaverService) {
    super();
    this._loadingService.create({
      name: 'downloading',
      mode: LoadingMode.Indeterminate,
      type: LoadingType.Circular,
      color: 'accent',
    });
  }

  downloadParamFile(machineId: string, revision: string, year: number, param: string) {
    const pageUrl: string = environment.api_endpoint + 'machines/' + machineId + '/' + revision + '/' + year + '/download?param=' + param;
    this.download(param + '.pdf', pageUrl, 'application/pdf');
  }

  downloadCommunicationMaterials() {
    const pageUrl: string = environment.api_endpoint + 'download/communication-materials';
    this.download('communication_materials.zip', pageUrl, 'application/zip');
  }

  downloadOperativeInstructions() {

    const pageUrl: string = environment.api_endpoint + 'download/operative-istruction';
    this.download('operative_instructions.zip', pageUrl, 'application/zip');
  }

  downloadLabel(machineId: string, labelId: string) {
    const pageUrl: string = environment.api_endpoint + 'machines/' + machineId + '/green-label/' + labelId + '/download';
    this.download(labelId + '.pdf', pageUrl, 'application/pdf');
  }

  downloadReport(machineId: string, labelId: string) {
    const pageUrl: string = environment.api_endpoint + 'machines/' + machineId + '/green-label/' + labelId + '/download-report';
    this.download(labelId + '.report.pdf', pageUrl, 'application/pdf');
  }

  private download(fileName: string, pageUrl: string, contentType: string = 'application/pdf') {
    this._loadingService.register('downloading');
    this.http.get(pageUrl, {responseType: 'blob', observe: 'response'})
      .subscribe(
        (res: any) => {
          if (res.status === 204) {
            this.snackService.notifyErr('File not found');
            this._loadingService.resolve('downloading');
          } else {
            this.fileSaver.save(res.body, fileName);
          }
        },
        err => {
          this._loadingService.resolve('downloading');
          console.log(err);
          switch (err.status) {
            case 404:
              this.snackService.notifyErr('File not Found');
              break;
            case 204:
              this.snackService.notifyErr('File not Found');
              break;
            default:
              this.snackService.notifyErr('Error during server communication');
              break;
          }

        },
        () => {
          this._loadingService.resolve('downloading');
        }
      );
  }

  downloadCommunicationMaterialsNew(machineId: string, labelId: string) {
    const pageUrl: string = environment.api_endpoint + 'machines/' + machineId + '/green-label/' + labelId + '/download-cm';
    this.download(labelId + '-cm.zip', pageUrl, 'application/pdf');
  }
}

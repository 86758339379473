import { Injectable } from '@angular/core';
import { BaseService } from 'app/services/base-service';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { Message, MessageExchange } from 'app/models/message';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class MessageService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  markAsRead(m: Message): Observable<Message> {
    const pageUrl: string = environment.api_endpoint + 'messages/mark-as-read/' + m.id;
    return this.http.put<Message>(pageUrl, {})
      ;
  }
  getAllMessages(): Observable<Message[]> {
    const pageUrl: string = environment.api_endpoint + 'messages/';
    return this.http.get<Message[]>(pageUrl)
      ;
  }

  getMyMessages(): Observable<Message[]> {
    const pageUrl: string = environment.api_endpoint + 'messages/my/';
    return this.http.get<Message[]>(pageUrl)
      ;
  }

  send(m: MessageExchange): Observable<Message> {
    const pageUrl: string = environment.api_endpoint + 'messages/';
    return this.http.post<Message>(pageUrl, m)
      ;
  }
}

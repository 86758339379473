import {AfterViewInit, Component, Injector, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {SecureStorageService} from 'app/services/secure-storage.service';
import {CompanyService} from 'app/services/company.service';
import {Company, User} from 'app/models';
import {SnackService} from 'app/services/snack.service';
import {MaintenanceService} from 'app/services/maintenance.service';
import {Batch} from 'app/models/batch';
import {TdLoadingService} from '@covalent/core/loading';
import {TdMediaService} from '@covalent/core/media';
import {TdDialogService} from '@covalent/core/dialogs';

@Component({
  selector: 'app-company-dashboard',
  templateUrl: './company-dashboard.component.html',
  styleUrls: ['./company-dashboard.component.scss']
})
export class CompanyDashboardComponent implements OnInit, AfterViewInit {
  user: User;
  company: Company;
  public media: TdMediaService;
  private _loadingService: TdLoadingService;
  private dialogService: TdDialogService;

  constructor(private _router: Router,
              private _route: ActivatedRoute,
              private injector: Injector,
              private snackService: SnackService,
              private companyService: CompanyService,
              private secureStorage: SecureStorageService,
              private maintenanceService: MaintenanceService) {
    this._loadingService = injector.get(TdLoadingService);
    this.media = injector.get(TdMediaService);
    this.dialogService = injector.get(TdDialogService);
  }

  ngOnInit() {
    this.user = JSON.parse(this.secureStorage.retrieve('user'));
    this._route.params.subscribe((params: { id: string }) => {
      if (params.id) {
        const companyId: string = params.id;
        this.companyService.findById(companyId).subscribe((company: Company) => {
          this.company = company;
        });
      }
    });
  }

  ngAfterViewInit(): void {
    // broadcast to all listener observables when loading the page
    this.media.broadcast();
  }

  rebuildCompanyLabels() {

    this.dialogService
      .openConfirm({message: 'Are you sure you want to rebuild all labels?'})
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.maintenanceService.rebuildByCompany(this.company.id).subscribe(
            (res: Batch) => {
              if (res !== null) {
                this.snackService.notifyInfo('Rebuild process started');
              } else {
                this.snackService.notifyWarn('Rebuild process not started');
              }
            },
            err => this.snackService.notifyErr(err)
          );
        }
      });
  }
}

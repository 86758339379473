import {State} from './state';


import {Company} from 'app/models';
import {FootprintParams} from 'app/models/footprint-params';

export class WorkCycle {
    productCode: string;
    other: string;
}

export class ProcessDescriptor {
    istruzioni_operative_path: string;
    workCycle: WorkCycle;
    materialProcessed: string;
    processEfficiency: number;
    installedPower: number;
    soundEmissions: number;
}

export class Consumption {
    water: number;
    powerConsumption: number;
    compressedAir: number;
    compressedAirType: string;
    steam: number;
    steamType: string;
    steamUM: string;
    steamStd: number;
    naturalGas: number;
    naturalGasUM: string;
    naturalGasStd: number;
}

export class WashingAgent {
    auxiliaires: number;
    bleaches: number;
    buildersSoftenings: number;
    tensidesSurfactants: number;
}

export class ChemicalsProcess {
    inorganic: number;
    organic: number;
    salt: number;
}

export class CustomProssesInput {
    key: string;
    value: string;
    um: string;
}

export class Param {
    processDescriptor: ProcessDescriptor;
    consumption: Consumption;
    washingAgents: WashingAgent;
    chemicalsProcess: ChemicalsProcess;
    customProssesInput: CustomProssesInput[];
}

export class FootprintContribute {
    name: string;
    value: number;
}

export class Footprint {
    value: number;
    contributes: FootprintContribute[];
}

export class Installation {
    targaCode: string;
    location: State;
    targaFile: string;
    footprint: Footprint;
    number: number;
    generationDate: Date;
    footprintParams: FootprintParams;
}

export class Files {
    path?: string;
    file?: File;
    params: string[];
}

export class Configuration {
    revision: string;
    year: number;

    url: string;
    files: Files[];
    // footprint: number;
    footprint: Footprint;
    creationDate: Date;
    params: Param;
    installations: Installation[];
}

export class Machine {
    id: string;
    company: Company;
    commercialName: string;
    description: string;
    creationDate: Date;
    configuration: Configuration[];
}


export class FileParams {
    installedPower = false;
    soundEmissions = false;
    water = false;
    powerConsumption = false;
    compressedAir = false;
    steam = false;
    naturalGas = false;
    auxiliaires = false;
    bleaches = false;
    buildersSoftenings = false;
    tensidesSurfactants = false;
    inorganic = false;
    organic = false;
    salt = false;
    customProcessInput = false;
}

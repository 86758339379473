<mat-card tdMediaToggle="gt-xs" [mediaClasses]="['push']">
  <mat-card-header>
    <mat-card-title>Logs</mat-card-title>
    <mat-card-subtitle>List of events</mat-card-subtitle>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content>
    <ng-template tdLoading="logs.loading">
      <mat-list class="will-load" *ngIf="logs">
        <mat-list-item *ngFor="let log of logs.content">
          <mat-icon mat-list-avatar [ngSwitch]="log.context">
            <ng-container *ngSwitchCase="'company'">domain</ng-container>
            <ng-container *ngSwitchDefault>bug_report</ng-container>
          </mat-icon>
          <h3 mat-line>
            {{log.message}} - {{log.context}}
          </h3>
          <div [ngSwitch]="log.context">
            <div *ngSwitchCase="'company'">company</div>
            <div *ngSwitchDefault>output2</div>
          </div>
          <p mat-line>
            <span class="log-level-{{log.level}}"> {{log.user?.firstName}} {{log.user?.lastName}}</span> - {{log.creationDate
            | timeAgo}}
          </p>
        </mat-list-item>
      </mat-list>
      <td-paging-bar *ngIf="logs" #pagingCP pageLinkCount="5" [firstLast]="true" [pageSize]="pageSize"
        [total]="logs.totalElements" (change)="changePage($event)">
      </td-paging-bar>
    </ng-template>
  </mat-card-content>
  <mat-card-actions>
    <mat-button-toggle-group #group="matButtonToggleGroup" [(ngModel)]="logLevel" (change)="searchByFilter($event)">
      <mat-button-toggle>
        ALL
      </mat-button-toggle>
      <mat-button-toggle value="0">
        <span class="log-level-0">INFO</span>
      </mat-button-toggle>
      <mat-button-toggle value="1">
        <span class="log-level-1">WARN</span>
      </mat-button-toggle>
      <mat-button-toggle value="2">
        <span class="log-level-2">ERROR</span>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </mat-card-actions>
</mat-card>

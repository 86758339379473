import { User } from "app/models";

export class Batch {
    id: string;
    title: string;
    status: string;
    running: boolean;
    startTime: Date;
    endTime?: Date;
    updateTime?: Date;
    completePercent: number; 
    user: User;
}
import { User } from 'app/models';
export class Logs {
    id: string;
    user: User;
    creationDate: Date;
    level: number;
    message: string;
    messageDetail: string;
    context: string;
}


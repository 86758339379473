import {Component, Input, OnInit} from '@angular/core';

import * as L from 'leaflet';
import 'leaflet.markercluster';
import {CountryMap} from 'app/models/chart-data';

@Component({
  selector: 'chart-map',
  templateUrl: './chart-map.component.html',
  styleUrls: ['./chart-map.component.css']
})

export class ChartMapComponent implements OnInit {

  @Input('data')
  countryMapData: CountryMap[];

  mapOptions = {
    layers: [
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {maxZoom: 18, attribution: '...'})
    ],
    zoom: 5,
    center: L.latLng({lat: 38.991709, lng: -76.886109})
  };

  // Open Street Map Definition
  LAYER_OSM = {
    id: 'openstreetmap',
    name: 'Open Street Map',
    enabled: false,
    layer: L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      attribution: 'Open Street Map'
    })
  };

  // Values to bind to Leaflet Directive
  layersControlOptions = {position: 'bottomright'};
  baseLayers = {
    'Open Street Map': this.LAYER_OSM.layer
  };
  options = {
    zoom: 1,
    center: L.latLng([32.759562, 14.941406])
  };

  // Marker cluster stuff
  markerClusterGroup: L.MarkerClusterGroup;
  markerClusterOptions: L.MarkerClusterGroupOptions = {
    spiderfyOnMaxZoom: false,
    zoomToBoundsOnClick: false,
    iconCreateFunction: function (cluster) {
      const childCount = cluster.getChildCount();

      let c = ' marker-cluster-';
      if (childCount < 10) {
        c += 'small';
      } else if (childCount < 100) {
        c += 'medium';
      } else {
        c += 'large';
      }

      return new L.DivIcon({
        html: '<div><span>' + childCount + '</span></div>',
        className: 'marker-cluster' + c,
        iconSize: new L.Point(40, 40)
      });
    }
  };


  map: L.Map;
  markerClusterData: any[];

  ngOnInit() {
    this.markerClusterData = [];
    this.countryMapData.forEach(c => {
      let icon;
      if (c.country.order > 0) {
        icon = new L.DivIcon({
          html: '<div><span>' + (c.country.code) + '</span></div>',
          className: 'marker-cluster marker-cluster-small ' + (c.country.order > 0 ? 'marker-outside' : ''),
          iconSize: new L.Point(40, 40)
        });
      } else {
        icon = new L.DivIcon({
          html: '<div><span>' + c.count + '</span></div>',
          className: 'marker-cluster marker-cluster-small ' + (c.country.order > 0 ? 'marker-outside' : ''),
          iconSize: new L.Point(40, 40)
        });
      }
      for (let i = 0; i < c.count; i++) {
        const marker = L.marker([c.country.geo.latitude, c.country.geo.longitude], {icon});
        marker.bindPopup(c.country.name);
        this.markerClusterData.push(marker);
      }


    });
  }

  markerClusterReady(group: L.MarkerClusterGroup) {
    this.markerClusterGroup = group;
  }

  onMapReady(map: L.Map) {
    this.map = map;
  }

}

import {environment} from './../../environments/environment';
import {Observable} from 'rxjs';
import {BaseService} from './base-service';
import {Injectable} from '@angular/core';
import {Agenda} from 'app/models/agenda';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class AgendaService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  save(agenda: Agenda): Observable<Agenda> {
    const pageUrl: string = environment.api_endpoint + 'agenda/';
    return this.http
      .post<Agenda>(pageUrl, agenda)
      ;
  }

  delete(agenda: Agenda): Observable<Agenda> {
    const pageUrl: string = environment.api_endpoint + 'agenda/' + agenda.id;
    return this.http
      .delete<Agenda>(pageUrl)
      ;
  }

  findById(id: string): Observable<Agenda> {
    const pageUrl: string = environment.api_endpoint + 'agenda/' + id;
    return this.http
      .get<Agenda>(pageUrl)
      ;
  }

  findAll(start: string, end: string): Observable<Agenda[]> {
    const pageUrl: string =
      environment.api_endpoint + 'agenda/?start=' + start + '&end=' + end;
    return this.http
      .get<Agenda[]>(pageUrl)
      ;
  }
}

import {TitleService} from './../../services/title.service';
import {Company} from './../../models/company';
import {User} from './../../models/user';
import {SecureStorageService} from 'app/services/secure-storage.service';
import {CompanyService} from './../../services/company.service';
import {SnackService} from './../../services/snack.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Machine} from 'app/models/machine';
import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {MachineService} from 'app/services/machine.service';
import {Subscription} from 'rxjs/internal/Subscription';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {TdLoadingService} from '@covalent/core/loading';

@Component({
  selector: 'app-machine',
  templateUrl: './machine.component.html',
  styleUrls: ['./machine.component.scss']
})
export class MachineComponent implements OnInit {
  machineList: Machine[];
  machine: Machine;
  machineId: string;
  basePath: string;
  revision: string;
  year: number;
  user: User;
  company: Company;
  companyList: Company[];
  allCompany: Company;
  machineSubscription: Subscription;

  @ViewChild('machineSelect') machineSelect: MatSelect;
  protected _loadingService: TdLoadingService;
  private _route: ActivatedRoute;
  private _router: Router;

  constructor(
    private machineService: MachineService,
    private location: Location,
    private snackService: SnackService,
    private companyService: CompanyService,
    private secureStorage: SecureStorageService,
    private titleService: TitleService,
    private injector: Injector
  ) {
    this._router = injector.get(Router);
    this._route = injector.get(ActivatedRoute);
    this._loadingService = injector.get(TdLoadingService);
    this.titleService.setTitle('Machines');
  }

  ngOnInit() {

    this.allCompany = new Company();
    this.allCompany.id = null;
    this.allCompany.name = 'All';
    this._loadingService.register('company.loading');

    this.user = JSON.parse(this.secureStorage.retrieve('user'));
    if (this.user.role === 'administrator') {
      this.company = this.allCompany;
      this.companyService.findAll().subscribe(
        res => {
          this.companyList = [this.allCompany];
          this.companyList.push(...res);
        },
        err => this.snackService.notifyErr(err),
        () => this._loadingService.resolve('company.loading')
      );
    } else {
      this.company = this.user.company;
    }
    this._loadingService.register('machine.loading');
    this.machineSubscription = this.machineService.getAllMachine().subscribe(
      res => {
        this.machineList = res;
      },
      err => this.snackService.notifyErr(err),
      () => this._loadingService.resolve('machine.loading')
    );
    this._route.params.subscribe(
      (params: { id: string; rev: string; year: number }) => {
        if (params.id) {
          this.machineId = params.id;
          this.revision = params.rev;
          this.year = params.year;
          this._loadingService.register('dashboard.machine.load');
          this.machineService.get(params.id).subscribe(
            res => {
              this.machine = res;
            },
            err => this.snackService.notifyErr(err),
            () => {
              this._loadingService.resolve('dashboard.machine.load');
            }
          );
        }
      }
    );
  }

  selectCompany(event: MatSelectChange) {
    this._loadingService.register('machine.loading');
    if (this.machineSubscription) {
      this.machineSubscription.unsubscribe();
      this.machineSubscription = null;
    }
    let sub;
    if (this.company && this.company.id) {
      sub = this.machineService.getAllMachineByCompany(this.company.id);
    } else {
      sub = this.machineService.getAllMachine();
    }
    this.machineList = null;
    this.machineSubscription = sub.subscribe(
      res => {
        this.machineList = res;
        this.machine = null;
      },
      err => {
        this.snackService.notifyErr(err);
        this._loadingService.resolve('machine.loading');
      },
      () => {
        this._loadingService.resolveAll('machine.loading');
        window.history.pushState(
          'DashboardMachine',
          'Dashboard Machine',
          '/machines/'
        );
        this.machineSubscription = null;
      }
    );
  }

  selectMachine(event: MatSelectChange) {
    console.log(event);
    this._loadingService.register('dashboard.machine.load');
    this.machineService.get(event.value).subscribe(
      res => {
        console.log('RES Machine', res);
        this.machine = res;
        this.revision = undefined;
        this.year = undefined;
      },
      err => this.snackService.notifyErr(err),
      () => {
        this._loadingService.resolve('dashboard.machine.load');
        window.history.pushState(
          'DashboardMachine',
          'Dashboard Machine',
          '/machines/' + event.value
        );
      }
    );

  }
}

<td-layout #layout *ngIf="currentUser">
  <td-navigation-drawer name="{{currentUser.firstName}} {{currentUser.lastName}}" email="{{currentUser.email}}">
    <div class="md-title text-primary text-center pad-top">
      <strong>Green Label</strong>
    </div>
    <mat-nav-list>
      <ng-template let-item let-last="last" ngFor [ngForOf]="routes">
        <a *hasRole=item.roles mat-list-item [routerLink]="[item.route]" (click)="layout.close()">
          <mat-icon>{{item.icon}}</mat-icon>{{item.title}}</a>
      </ng-template>
    </mat-nav-list>
    <mat-nav-list td-navigation-drawer-menu>
      <a mat-list-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>Sign out</a>
    </mat-nav-list>
  </td-navigation-drawer>
  <td-layout-nav color="{{currentUser.role=='administrator' ? 'accent' : 'primary'}}" navigationRoute="/">
    <button mat-icon-button td-menu-button tdLayoutToggle>
      <mat-icon>menu</mat-icon>
    </button>
    <div td-toolbar-content layout="row" layout-align="center center" flex>
      <span>Green Label</span>
      <span flex></span>
      <div class="mini-loading">
        <ng-template style.min-height="40" tdLoading="message.loading" tdLoadingMode="indeterminate" tdLoadingType="circular" tdLoadingStrategy="replace"
          tdLoadingColor="accent">
          <button mat-icon-button [matMenuTriggerFor]="messageMenu">
            <td-notification-count color="{{currentUser.role=='administrator' ? 'primary' : 'accent'}}" [notifications]="unreadMessagesCount">
              <mat-icon>messages</mat-icon>
            </td-notification-count>
          </button>
        </ng-template>
      </div>

      <a [href]="userGuideUrl" target="_blank" matTooltip="User guide" mat-icon-button>
        <mat-icon aria-label="help">help</mat-icon>
      </a>
      <a [href]="logosUrl" target="_blank" matTooltip="New Logos" mat-icon-button>
        <mat-icon aria-label="check">check</mat-icon>
      </a>
      <a [href]="brandBookUrl" target="_blank" matTooltip="Brand Book" mat-icon-button>
        <mat-icon aria-label="BrandBook">branding_watermark</mat-icon>
      </a>

      <mat-menu #messageMenu="matMenu" [overlapTrigger]="false">
        <td-menu>
          <div td-menu-header class="mat-subhead">Messages</div>
          <mat-nav-list dense>
            <ng-template ngFor let-last="last" let-message [ngForOf]="latestMessages">
              <a [routerLink]="['/messages',message.id]" mat-list-item>
                <mat-icon mat-list-avatar>message</mat-icon>
                <h4 mat-line>
                  <span class="text-wrap" [ngClass]="{'unread-message': message.status==false}">{{message.object}}</span>
                </h4>
                <p mat-line *ngIf="!message.from">Automatic Alert System</p>
                <p mat-line *ngIf="message.from">>{{message.from?.name}} ({{message.createdBy?.firstName}} {{message.createdBy?.lastName}})</p>
                <p mat-line>{{message.sentDate | timeAgo}}</p>
                <mat-divider *ngIf="!last"></mat-divider>
              </a>
            </ng-template>
          </mat-nav-list>
          <a [routerLink]="['/messages']" mat-button td-menu-footer color="accent">See All Messages</a>
        </td-menu>
      </mat-menu>


    </div>
    <router-outlet></router-outlet>
    <td-layout-footer>
      <div layout="row" layout-align="space-between">
        <a class="mat-caption" routerLink="privacy">Privacy Policy</a>
        <span class="mat-caption">Copyright &copy; 2017 Rina Consulting S.p.A. All rights reserved</span>
        <span class="mat-caption">GreenLabel version {{version}}</span>
      </div>
    </td-layout-footer>
  </td-layout-nav>

</td-layout>

import { User } from "./user";
import { Machine, Configuration } from "./machine";
import { Company } from "./company";
export class TrashContent {
  company: Company;
  machines: Machine[];
  users: User[];
  machineId: string;
  configuration: Configuration;
}
export class Trash {
  id: string;
  deleteDate: Date;
  deletedBy: User;
  collection: string;
  content: TrashContent;
  contentType: string;
}

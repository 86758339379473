<div layout="column" layout-gt-sm="row" class="inset">
  <div flex-gt-sm="60">
    <div layout="row">
      <div flex layout="column">
        <mat-card tdMediaToggle="gt-xs" [mediaClasses]="['push']">
          <mat-card-title>Company Users</mat-card-title>
          <mat-divider></mat-divider>
          <mat-card-content class="push-bottom-none">
            <user-list *ngIf="company" [companyId]="company.id"></user-list>
          </mat-card-content>
          <mat-divider></mat-divider>
          <mat-card-actions>
            <a mat-button *ngIf="user.role!=='member'" color="accent" class="text-upper" [routerLink]="['users/add']">Add User</a>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>
  <div flex-gt-sm="40">
    <div layout="row">
      <div flex layout="column">
        <mat-card tdMediaToggle="gt-xs" [mediaClasses]="['push']" *ngIf="company">
          <mat-card-title>Company Profile</mat-card-title>
          <mat-divider></mat-divider>
          <mat-card-content class="push-bottom-none">
            <img lazy-image-load [data-source]="company.id" alt="company-logo">
            <mat-list>
              <mat-list-item>
                <h3 mat-line>
                  {{company.name}}
                </h3>
                <p mat-line>
                  <span>Company name</span>
                </p>
              </mat-list-item>
              <mat-list-item>
                <h3 mat-line>
                  {{company.address}}
                </h3>
                <p mat-line>
                  <span>Address</span>
                </p>
              </mat-list-item>
            </mat-list>
          </mat-card-content>
          <mat-divider></mat-divider>
          <mat-card-actions>
            <a mat-button *ngIf="user.role!=='member'" color="accent" class="text-upper" [routerLink]="['edit']">Edit</a>
          </mat-card-actions>
        </mat-card>

      </div>
    </div>
    <div layout="row">
      <div flex layout="column">
        <mat-card tdMediaToggle="gt-xs" [mediaClasses]="['push']" *ngIf="company">
          <mat-card-title>Company Maintenance</mat-card-title>
          <mat-divider></mat-divider>
          <mat-card-content class="push-bottom-none">
            <button color="warn" mat-flat mat-button (click)="rebuildCompanyLabels()">Rebuild Company Labels</button>
          </mat-card-content>
          <mat-divider></mat-divider>
          <mat-card-actions>

          </mat-card-actions>
        </mat-card>

      </div>
    </div>
  </div>
</div>

import {TrashComponent} from './pages/trash/trash.component';
import {AdmDashboardComponent} from './pages/admin/adm-dashboard/adm-dashboard.component';

import {AdmMessagesComponent} from './pages/admin/adm-messages/adm-messages.component';
import {MessagesComponent} from './pages/messages/messages.component';
import {LogsComponent} from './pages/logs/logs.component';
import {FrontendComponent} from './pages/frontend/frontend.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {CompanyComponent} from './pages/company/company.component';
import {MainComponent} from './pages/main/main.component';
import {RouterModule, Routes} from '@angular/router';
import {CompanyFormComponent} from 'app/pages/company/company-form/company-form.component';
import {UserFormComponent} from 'app/pages/user/user-form/user-form.component';
import {CompanyDashboardComponent} from 'app/pages/company/company-dashboard/company-dashboard.component';
import {RoleGuard} from 'app/shared/role.guard';
import {AdminComponent} from 'app/pages/admin/admin.component';
import {MachineComponent} from 'app/pages/machine/machine.component';
import {MachineFormComponent} from 'app/pages/machine/machine-form/machine-form.component';
import {GreenLabelReportComponent} from 'app/pages/machine/green-label-report/green-label-report.component';
import {UnauthorizedErrorComponent} from 'app/pages/error/unauthorized-error/unauthorized-error.component';
import {MessageFormComponent} from 'app/components/message-form/message-form.component';
import {AgendaFormComponent} from 'app/components/agenda/agenda-form/agenda-form.component';
import {AgendaComponent} from 'app/components/agenda/agenda.component';
import {MaintenanceComponent} from 'app/pages/admin/maintenance/maintenance.component';
import {RoleMachineGuard} from 'app/shared/role-machine.guard';
import {PrivacyGuard} from './shared/privacy.guard';
import {PrivacyPageComponent} from './pages/privacy-page/privacy-page.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [PrivacyGuard],
    // canActivate: [AuthGuardForced],
    children: [
      {
        path: '',
        component: FrontendComponent,
        children: [
          {
            path: 'dashboard',
            children: [
              {
                path: 'machine/:id/:rev/:year',
                component: DashboardComponent,
                canActivate: [RoleGuard],
                data: {
                  roles: [
                    {key: 'administrator', check: false},
                    {key: 'associate', check: false},
                    {key: 'member', check: false}
                  ]
                }
              },
              {
                path: 'machine/:id',
                component: DashboardComponent,
                canActivate: [RoleGuard],
                data: {
                  roles: [
                    {key: 'administrator', check: false},
                    {key: 'associate', check: false},
                    {key: 'member', check: false}
                  ]
                }
              },
              {
                path: 'machine',
                component: DashboardComponent,
                canActivate: [RoleGuard],
                data: {
                  roles: [
                    {key: 'administrator', check: false},
                    {key: 'associate', check: false},
                    {key: 'member', check: false}
                  ]
                }
              },
              {
                path: '',
                component: DashboardComponent,
                canActivate: [RoleGuard],
                data: {
                  roles: [
                    {key: 'administrator', check: false},
                    {key: 'associate', check: false},
                    {key: 'member', check: false}
                  ]
                }
              }
            ]
          },
          {
            path: 'messages',
            children: [
              {
                path: ':id',
                component: MessagesComponent,
                canActivate: [RoleGuard],
                data: {
                  roles: [
                    {key: 'administrator', check: false},
                    {key: 'associate', check: false},
                    {key: 'member', check: false}
                  ]
                }
              },
              {
                path: '',
                component: MessagesComponent,
                canActivate: [RoleGuard],
                data: {
                  roles: [
                    {key: 'administrator', check: false},
                    {key: 'associate', check: false},
                    {key: 'member', check: false}
                  ]
                }
              }
            ]
          },
          {
            path: 'machines',
            children: [
              // tslint:disable-next-line:max-line-length
              {
                path: 'add',
                component: MachineFormComponent,
                canActivate: [RoleGuard],
                data: {
                  roles: [
                    {key: 'administrator', check: false},
                    {key: 'associate', check: false},
                    {key: 'member', check: false}
                  ]
                }
              },
              // tslint:disable-next-line:max-line-length
              // { path: ':id/edit', component: MachineEditComponent, canActivate: [RoleGuard], data: { roles: [{ key: 'administrator', check: false }, { key: 'associate', check: false }, { key: 'member', check: false }] } },
              {
                path: ':id/green-label/:labelId',
                component: GreenLabelReportComponent,
                canActivate: [RoleGuard],
                data: {
                  roles: [
                    {key: 'administrator', check: false},
                    {key: 'associate', check: false},
                    {key: 'member', check: false}
                  ]
                }
              },
              {
                path: ':id/:rev/:year/clone',
                component: MachineFormComponent,
                canActivate: [RoleMachineGuard],
                data: {
                  roles: [
                    {key: 'administrator', check: false},
                    {key: 'associate', check: true},
                    {key: 'member', check: true}
                  ]
                }
              },
              {
                path: ':id/:rev/:year',
                component: MachineComponent,
                canActivate: [RoleGuard],
                data: {
                  roles: [
                    {key: 'administrator', check: false},
                    {key: 'associate', check: false},
                    {key: 'member', check: false}
                  ]
                }
              },
              {
                path: ':id/conf',
                component: MachineFormComponent,
                canActivate: [RoleGuard],
                data: {
                  roles: [
                    {key: 'administrator', check: false},
                    {key: 'associate', check: false},
                    {key: 'member', check: false}
                  ]
                }
              },
              {
                path: ':id',
                component: MachineComponent,
                canActivate: [RoleGuard],
                data: {
                  roles: [
                    {key: 'administrator', check: false},
                    {key: 'associate', check: false},
                    {key: 'member', check: false}
                  ]
                }
              },

              {
                path: '',
                component: MachineComponent,
                canActivate: [RoleGuard],
                data: {
                  roles: [
                    {key: 'administrator', check: false},
                    {key: 'associate', check: false},
                    {key: 'member', check: false}
                  ]
                }
              }
            ]
          },

          {path: 'privacy', component: PrivacyPageComponent}
        ]
      },
      {
        path: 'adm',
        component: AdminComponent,
        children: [
          {
            path: 'dashboard',
            component: AdmDashboardComponent,
            canActivate: [RoleGuard],
            data: {
              roles: [
                {key: 'administrator', check: false},
                {key: 'associate', check: true}
              ]
            }
          },
          {
            path: 'agenda',
            children: [
              {
                path: 'add',
                component: AgendaFormComponent,
                canActivate: [RoleGuard],
                data: {roles: [{key: 'administrator', check: false}]}
              },
              {
                path: ':id/edit',
                component: AgendaFormComponent,
                canActivate: [RoleGuard],
                data: {roles: [{key: 'administrator', check: false}]}
              },
              {
                path: '',
                component: AgendaComponent,
                canActivate: [RoleGuard],
                data: {roles: [{key: 'administrator', check: false}]}
              }
            ]
          },
          {
            path: 'trash',
            component: TrashComponent,
            canActivate: [RoleGuard],
            data: {
              roles: [
                {key: 'administrator', check: false},
                {key: 'associate', check: false}
              ]
            }
          },
          {
            path: 'companies',
            children: [
              {
                path: 'add',
                component: CompanyFormComponent,
                canActivate: [RoleGuard],
                data: {roles: [{key: 'administrator', check: false}]}
              },
              {
                path: ':id',
                component: CompanyDashboardComponent,
                canActivate: [RoleGuard],
                data: {
                  roles: [
                    {key: 'administrator', check: false},
                    {key: 'associate', check: true}
                  ]
                }
              },
              {
                path: ':id/delete',
                component: CompanyFormComponent,
                canActivate: [RoleGuard],
                data: {roles: [{key: 'administrator', check: false}]}
              },
              {
                path: ':id/edit',
                component: CompanyFormComponent,
                canActivate: [RoleGuard],
                data: {
                  roles: [
                    {key: 'administrator', check: false},
                    {key: 'associate', check: true}
                  ]
                }
              },
              {
                path: ':id/users',
                children: [
                  {
                    path: 'add',
                    component: UserFormComponent,
                    canActivate: [RoleGuard],
                    data: {
                      roles: [
                        {key: 'administrator', check: false},
                        {key: 'associate', check: true}
                      ]
                    }
                  },
                  {
                    path: ':userId/delete',
                    component: UserFormComponent,
                    canActivate: [RoleGuard],
                    data: {
                      roles: [
                        {key: 'administrator', check: false},
                        {key: 'associate', check: true}
                      ]
                    }
                  },
                  {
                    path: ':userId/edit',
                    component: UserFormComponent,
                    canActivate: [RoleGuard],
                    data: {
                      roles: [
                        {key: 'administrator', check: false},
                        {key: 'associate', check: true}
                      ]
                    }
                  }
                ]
              },
              {
                path: '',
                component: CompanyComponent,
                canActivate: [RoleGuard],
                data: {roles: [{key: 'administrator', check: false}]}
              }
            ]
          },
          {
            path: 'logs',
            component: LogsComponent,
            canActivate: [RoleGuard],
            data: {roles: [{key: 'administrator', check: false}]}
          },
          {
            path: 'messages',
            children: [
              {
                path: 'add',
                component: MessageFormComponent,
                canActivate: [RoleGuard],
                data: {roles: [{key: 'administrator', check: false}]}
              },
              {
                path: ':id',
                component: AdmMessagesComponent,
                canActivate: [RoleGuard],
                data: {roles: [{key: 'administrator', check: false}]}
              },
              {
                path: '',
                component: AdmMessagesComponent,
                canActivate: [RoleGuard],
                data: {roles: [{key: 'administrator', check: false}]}
              }
            ]
          },
          {
            path: 'maintenance',
            component: MaintenanceComponent,
            canActivate: [RoleGuard],
            data: {
              roles: [{key: 'administrator', check: false}]
            }
          }
        ]
      },
      {
        path: 'error',
        children: [
          {
            path: 'unauthorized',
            component: UnauthorizedErrorComponent
          }
        ]
      }
    ]
  }
];

export const appRoutingProviders: any[] = [];

export const appRoutes: any = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });

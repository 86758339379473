import {TdMediaService} from '@covalent/core/media';
import {switchMap} from 'rxjs/operators';
import {UserService} from './services/user.service';
import {from} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {PrivacyDialogComponent} from './components/privacy-dialog/privacy-dialog.component';
import {SecurityService} from './services/security.service';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {Component} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app works!';


  constructor(public media: TdMediaService,
              private auth: OidcSecurityService,
              private userService: UserService,
              private securityService: SecurityService,
              private matDialog: MatDialog) {
    console.log('INIT AUTH');

    this.auth.checkAuth().subscribe(
      authenticated => {
        if (!authenticated) {
          console.log('not authenticated');
          this.auth.authorize();
          return;
        }
        from(this.auth.userData$).pipe(
          switchMap(p => {
            console.log('userdata', p);
            return this.userService.getUserById(p.email);
          })
        ).subscribe(
          res => {
            console.log('USER', res);
            this.securityService.setUserInfo(res);
            if (!res.privacy || res.privacy.mandatoryCheck === false) {

              this.matDialog.open(PrivacyDialogComponent, { disableClose: true })
                .afterClosed()
                .subscribe(d => {
                    console.log(d);
                    if (!d) {
                      console.log('NON PUOI');
                    }
                  }
                );
            }
          }
        );
      }
    );

  }

}

import {Injectable} from '@angular/core';

@Injectable()
export class SecureStorageService {

  remove(key: string) {
    sessionStorage.removeItem(key);
  }

  store(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }

  retrieve(key: string): string {
    return sessionStorage.getItem(key);
  }

}

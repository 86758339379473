import { ParameterService } from './../../services/parameter.service';
import { Component, OnInit } from '@angular/core';
import {State} from '../../models';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-location-dialog',
  templateUrl: './location-dialog.component.html',
  styleUrls: ['./location-dialog.component.scss']
})
export class LocationDialogComponent implements OnInit {

  countries: State[];
  countriesOrig: State[];
  location: State;
  constructor(private parameterService: ParameterService, private dialogRef: MatDialogRef<State>) { }

  ngOnInit() {
    this.parameterService.loadStates().subscribe(
      res => {
        this.countries = res;
        this.countriesOrig = res;
      },
      err => console.log(err)
    );
  }
  close() {
    this.dialogRef.close();
  }

  select() {
    this.dialogRef.close(this.location);
  }

  locationDisplayFn(state: State): string {
    return state ? state.name : '';
  }

  filterStates(val: string | State): State[] {
    if (!val) {
      return this.countries;
    } else {
      if (!(val instanceof Object)) {
        return this.countriesOrig.filter((s: State) => s.name.match(new RegExp(val, 'gi')));
      }
    }
  }



}

import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {PrivacyCheck} from '../../models';

@Component({
  selector: 'app-privacy-dialog',
  templateUrl: './privacy-dialog.component.html',
  styleUrls: ['./privacy-dialog.component.css']
})
export class PrivacyDialogComponent {

  constructor(private dialogRef: MatDialogRef<PrivacyCheck>) {
  }

  privacyUpdated(event: boolean) {
    this.dialogRef.close(event);
  }
}

import { Injectable } from '@angular/core';
import { Configuration } from 'app/models';

@Injectable()
export class DataPassingService {

  configuration: Configuration;


}

import { Company } from './company';
import { User } from './user';

export class Message {
  id: string;
  from: Company;
  createdBy: User;
  to: MessageTo[];
  object: string;
  message: string;
  status?: boolean; // false = unread, true = read
  type: number;
  sentDate: Date;
}

export class MessageTo {
  company: Company;
  status: boolean; // false = unread, true = read
  readDate: Date;
}

export class MessageExchange {
  message: string;
  object: string;
  type: number;
  from: string;
  to: string[];
}

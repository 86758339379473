import {Injectable} from '@angular/core';
import {User} from '../models';
import {SecureStorageService} from './secure-storage.service';
import {Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  s: Subject<User> = new Subject<User>()

  constructor(private secureStorage: SecureStorageService) {
  }

  getUser(): Observable<User> {
    return this.s;
  }

  getUserInfo(): User {
    return JSON.parse(this.secureStorage.retrieve('user'));
  }

  setUserInfo(user: User) {
    this.secureStorage.store('user', JSON.stringify(user));
    this.s.next(user);
  }
}

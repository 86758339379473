<mat-list dense>
  <div class="mat-padding" *ngIf="!labelsDataPage || labelsDataPage.length === 0" layout="row" layout-align="center center">
    <h3>No labels to display.</h3>
  </div>
  <ng-template let-item let-last="last" ngFor [ngForOf]="labelsDataPage">
    <mat-list-item layout-align="row">
      <img mat-list-avatar class="bgc-amber-800" alt="{{item.installations.location.code}} flag" src="assets/images/flags/{{item.installations.location.code | lowercase}}.png"/>
      <h3 mat-line> {{item.machineName}} {{item.rev}}.{{item.year}} </h3>
      <p mat-line> ({{item.company.name}}) </p>
      <p mat-line> {{item.installations.location.name}} - {{item.generationDate | timeAgo}}</p>
      <span flex></span>
      <span hide-xs hide-sm hide-md flex-gt-xs="30" flex-xs="30" layout-gt-xs="row">
        <div class="mat-caption tc-grey-500" flex> {{ item.installations.targaCode }}</div>
      </span>
      <span hide-xs hide-sm hide-md flex-gt-xs="30" flex-xs="30" layout-gt-xs="row">
        <div class="mat-caption tc-grey-500" flex> kg {{ item.installations.footprint.value | number }} of CO
          <sub>2</sub> eq/kg mp</div>
      </span>
      <span flex>
        <a mat-button matTooltip="Open Machine Configuration" matTooltipPosition="below" [routerLink]="['/machines',item.machineId, item.rev, item.year]">
          <mat-icon>extension</mat-icon>
        </a>
        <a mat-button matTooltip="Open Green Label" matTooltipPosition="below" [routerLink]="['/machines',item.machineId, 'green-label', item.id]">
          <mat-icon>open_in_new</mat-icon>
        </a>
      </span>
    </mat-list-item>
    <mat-divider *ngIf="!last" mat-inset></mat-divider>
  </ng-template>
</mat-list>
<mat-divider></mat-divider>
<td-paging-bar #pagingLabels pageLinkCount="5" [initialPage]="1" [firstLast]="true" [pageSize]="5" [total]="labelsData.length"
  (change)="changePage($event)">
</td-paging-bar>

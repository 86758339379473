import {Component, Input, OnChanges} from '@angular/core';
import {ChartData, ChartSeries, TrendCluster} from 'app/models';
import {environment} from 'environments/environment';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {MatSelectChange} from '@angular/material/select';

@Component({
  selector: 'gl-trend-cluster',
  templateUrl: './trend-cluster.component.html',
  styleUrls: ['./trend-cluster.component.scss']
})
export class TrendClusterComponent implements OnChanges {


  mode: 'year' | 'month' = 'year';
  monthsName = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  chart: ChartData;
  year: number;
  yearList: number[];
  xLabel: string;
  public colorScheme = {
    domain: environment.color_scheme
  };
  @Input() private trend: TrendCluster[];


  ngOnChanges() {
    this.yearList = this.trend.map(t => t.id);
    if (this.yearList && this.yearList.length > 0) {
      this.year = this.yearList[0];
    }
    this.prepareChart();
  }

  switchMode(event: MatSlideToggleChange) {
    console.log(event);
    this.mode = this.mode === 'year' ? 'month' : 'year';
    this.prepareChart();
  }

  changeYear(event: MatSelectChange) {
    console.log(event);
    console.log(this.year);
    this.prepareChart();
  }

  private prepareChart() {
    this.chart = new ChartData();
    this.chart.name = this.mode + 'series';
    this.chart.series = [];
    switch (this.mode) {
      case 'year': {
        this.initYear();
      }
        break;
      case 'month': {
        this.initMonth();
      }
        break;
    }
  }

  private initMonth() {
    this.xLabel = 'Months of ' + this.year;
    const months = this.trend.filter(t => t.id === this.year)[0].month;
    months.forEach(m => {
      const cs: ChartSeries = new ChartSeries();
      cs.name = this.monthsName[m.month - 1];
      cs.value = m.count;
      this.chart.series.push(cs);
    });
  }

  private initYear() {
    this.xLabel = 'Years';
    this.trend.forEach(t => {
      const cs: ChartSeries = new ChartSeries();
      cs.name = '' + t.id;
      cs.value = t.count;
      this.chart.series.push(cs);
    });
    console.log('initYear');
    console.log(this.chart);
    console.log(this.trend);
  }
}

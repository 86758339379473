import { Title } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

/**
* This class provides the Title service with methods to read names and add names.
*/
@Injectable()
export class TitleService {
  private _title: string;

  constructor(private title: Title) {}

  public getTitle(): string {
    return this._title;
  }
  public setTitle(_title: string) {
    this._title = _title;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'scientificNotation' })
export class ScientificNotationPipe implements PipeTransform {
  transform(value: number) {
    console.log (value);
    if (value === null) {
      return '';
    }
    if (value < 0.001) {
      return value.toExponential(2);
    } else {
      return value.toFixed(2);
    }
  }
}

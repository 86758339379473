import { SnackService } from 'app/services/snack.service';
import { User } from 'app/models';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UserService } from 'app/services/user.service';
import { ActivatedRoute } from '@angular/router';
import {TdDialogService} from '@covalent/core/dialogs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TdMediaService} from '@covalent/core/media';

@Component({
    selector: 'app-user-form',
    templateUrl: './user-form.component.html',
    styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit, AfterViewInit {

    user: User;
    currentUser: User;
    action: string;
    companyId: string;
    userId: string;

    constructor(
        private userService: UserService,
        private _route: ActivatedRoute,
        private _dialogService: TdDialogService,
        private _snackBarService: MatSnackBar,
        public media: TdMediaService,
        private snackService: SnackService
    ) {}

    ngOnInit() {
        this.currentUser = {company: undefined, email: '', firstName: '', id: '', lastName: '', role: '', type: 'User', privacy: undefined};
        this._route.url.subscribe((url: any) => {
            this.action = url.length > 1 ? url[1].path : 'add';
        });
        this._route.params.subscribe((params: { id: string; userId: string }) => {
            this.companyId = params.id;
            this.userId = params.userId;

            this.user = new User();

            if (this.userId) {
                this.userService.findById(this.userId).subscribe((user: User) => {
                    this.user = user;
                });
            } else {
                this.user.role = 'member';
            }
        });
    }

    goBack(): void {
        window.history.back();
    }

    ngAfterViewInit(): void {
        this.media.broadcast();
    }

    save() {
        this.userService.preCreate(this.user, this.companyId, this.action).subscribe(
            res => {
                this.user = res;
                this.snackService.notifyInfo('User created');
            },
            err => this.snackService.notifyErr(err),
            () => this.goBack()
        );
    }

    delete(id: string): void {
        this._dialogService
            .openConfirm({ message: 'Are you sure you want to delete this user?' })
            .afterClosed()
            .subscribe((confirm: boolean) => {
                if (confirm) {
                    this.userService.delete(id).subscribe(
                        () => {
                            this.snackService.notifyInfo('User deleted');
                            this.goBack();
                        },
                        (error: Error) => {
                            this.snackService.notifyErr('Error deliting user!');
                        }
                    );
                }
            });
    }
}

  <mat-card tdMediaToggle="gt-xs" [mediaClasses]="['push']">
    <mat-card-title>{{action}} user</mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content class="push-bottom-none">
      <form #companyForm="ngForm">
        <div layout="row">
          <mat-form-field flex>
            <input matInput  #lastNameElement #lastNameControl="ngModel" type="text" placeholder="Last Name" [(ngModel)]="user.lastName"
              name="lastName" required>
          </mat-form-field>
        </div>
        <div layout="row">
          <mat-form-field flex>
            <input matInput  #firstNameElement #firstNameControl="ngModel" type="text" placeholder="First Name" [(ngModel)]="user.firstName"
              name="firstName" required>
          </mat-form-field>
        </div>
        <div layout="row">
          <mat-form-field flex>
            <input matInput  #emailElement #emailControl="ngModel" type="email" placeholder="Email" [(ngModel)]="user.email" name="email"
              required>
          </mat-form-field>
        </div>
        <div layout="row" *ngIf="currentUser.role=='administrator'">
          <mat-radio-group name="role" [(ngModel)]="user.role">
            <mat-radio-button *ngIf="currentUser.role=='administrator'" name="role" value="administrator">Administrator</mat-radio-button>
            <mat-radio-button *ngIf="currentUser.role=='administrator'" name="role" value="associate">Associate</mat-radio-button>
            <mat-radio-button name="role" value="member">Member</mat-radio-button>
          </mat-radio-group>
        </div>
      </form>
    </mat-card-content>
    <mat-divider></mat-divider>
    <mat-card-actions>
      <button mat-button color="primary" (click)="save()">SAVE</button>
      <button mat-button (click)="goBack()">CANCEL</button>
      <button *ngIf="action!=='add'" mat-button (click)="delete(user.id)">DELETE</button>
    </mat-card-actions>
  </mat-card>

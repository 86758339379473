import {Injectable} from '@angular/core';
import {BaseService} from 'app/services/base-service';
import {Observable} from 'rxjs';
import {environment} from 'environments/environment';
import {ProductCodes} from 'app/models/product-codes';
import {HttpClient} from '@angular/common/http';


@Injectable()
export class ProductCodesService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  getCaps(): Observable<ProductCodes> {
    const pageUrl: string = environment.api_endpoint + 'product-codes/caps/';
    return this.http.get<ProductCodes>(pageUrl)
      ;
  }

  getSubs(cap: string): Observable<ProductCodes> {
    const pageUrl: string = environment.api_endpoint + 'product-codes/caps/' + cap + '/subs/';
    return this.http.get<ProductCodes>(pageUrl)
      ;
  }

}

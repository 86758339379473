<td-layout-manage-list #manageList [opened]="media.registerQuery('gt-sm') | async" [mode]="(media.registerQuery('gt-sm') | async) ? 'side' : 'push'"
  [sidenavWidth]="(media.registerQuery('gt-xs') | async) ? '257px' : '100%'">
  <mat-nav-list td-sidenav-content>
    <a *hasRole="['administrator','associate','member']" mat-list-item mat-ripple class="block relative" (click)="!media.query('gt-sm') && manageList.close()"
      [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:false}" [routerLink]="['/dashboard']">
      <mat-icon mat-list-icon>dashboard</mat-icon>
      Dashboard
    </a>
    <a *hasRole="['administrator','associate','member']" mat-list-item mat-ripple class="block relative" (click)="!media.query('gt-sm') && manageList.close()"
      [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:false}" [routerLink]="['/machines']">
      <mat-icon mat-list-icon>memory</mat-icon>
      Machines
    </a>

    <mat-divider></mat-divider>
    <a *hasRole="['administrator','associate','member']" mat-list-item mat-ripple class="block relative" (click)="!media.query('gt-sm') && manageList.close()"
      [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:false}" [routerLink]="['/messages']">
      <mat-icon mat-list-icon>message</mat-icon>
      Messages
    </a>
  </mat-nav-list>
  <mat-toolbar>
    <span>{{getTitle()}}</span>
    <span flex></span>
  </mat-toolbar>
  <div class="mat-content front-end" flex mat-scroll-y>
    <router-outlet></router-outlet>
  </div>
</td-layout-manage-list>

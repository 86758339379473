import {ScientificNotationPipe} from './shared/scientific-notation.pipe';
import {TitleService} from './services/title.service';
import {ConfigurationDialogComponent} from './components/configuration-dialog/configuration-dialog.component';
import {CompareChartComponent} from './components/compare-chart/compare-chart.component';
import {TrashComponent} from './pages/trash/trash.component';
import {TrashService} from './services/trash.service';
import {AgendaFormComponent} from './components/agenda/agenda-form/agenda-form.component';
import {AgendaComponent} from './components/agenda/agenda.component';
import {LabelsComponent} from './components/labels/labels.component';
import {ChartMapComponent} from './components/chart-map/chart-map.component';
import {CompanyDialogComponent} from './components/company-dialog/company-dialog.component';
import {StatisticsService} from './services/statistics.service';
import {AdmDashboardComponent} from './pages/admin/adm-dashboard/adm-dashboard.component';
import {LabelContributeChartComponent} from './components/label-contribute-chart/label-contribute-chart.component';
import {MessageFormComponent} from './components/message-form/message-form.component';
import {AdmMessagesComponent} from './pages/admin/adm-messages/adm-messages.component';
import {MessagesComponent} from './pages/messages/messages.component';
import {SnackService} from './services/snack.service';
import {MessageService} from './services/message.service';
import {LogsComponent} from './pages/logs/logs.component';
import {LogsService} from './services/logs.service';

import {DownloadServiceService} from './services/download-service.service';
import {ProductCodesService} from './services/product-codes.service';
import {ParameterService} from './services/parameter.service';
import {MachineService} from 'app/services/machine.service';
import {UserService} from './services/user.service';
import {CompanyService} from './services/company.service';
import {appRoutes} from './app.routes';
import {SecureStorageService} from './services/secure-storage.service';
import {RoleGuard} from './shared/role.guard';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppComponent} from './app.component';
import {MainComponent} from './pages/main/main.component';

import {CompanyComponent} from './pages/company/company.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {CompanyFormComponent} from './pages/company/company-form/company-form.component';
import {ImagePreviewDirective} from './directives/image-preview.directive';
import {UserFormComponent} from './pages/user/user-form/user-form.component';
import {CompanyDashboardComponent} from './pages/company/company-dashboard/company-dashboard.component';
import {AdminComponent} from './pages/admin/admin.component';
import {HasRoleDirective} from 'app/shared/has-role.directive';
import {MachineComponent} from './pages/machine/machine.component';
import {FrontendComponent} from './pages/frontend/frontend.component';
import {MachineFormComponent} from './pages/machine/machine-form/machine-form.component';
import {TargaReportComponent} from './components/targa-report/targa-report.component';
import {GreenLabelReportComponent} from './pages/machine/green-label-report/green-label-report.component';
import {LocationDialogComponent} from './components/location-dialog/location-dialog.component';
import {MachineDashboardComponent} from './pages/machine/machine-dashboard/machine-dashboard.component';
import {UnauthorizedErrorComponent} from './pages/error/unauthorized-error/unauthorized-error.component';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {SharedModule} from './shared/shared.module';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {LeafletMarkerClusterModule} from '@asymmetrik/ngx-leaflet-markercluster';
import {AgendaService} from 'app/services/agenda.service';
import {MY_DATE_FORMATS} from 'app/shared/date-format';
import {LazyImageDirective} from 'app/shared/lazy-image.directive';
import {MaintenanceComponent} from './pages/admin/maintenance/maintenance.component';
import {MaintenanceService} from 'app/services/maintenance.service';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TrendClusterComponent} from './components/trend-cluster/trend-cluster.component';
import {TrendCompanyClusterComponent} from './components/trend-company-cluster/trend-company-cluster.component';
import {DataPassingService} from 'app/services/data-passing.service';
import {RoleMachineGuard} from 'app/shared/role-machine.guard';
import {DateAdapter, MAT_DATE_FORMATS, MatRippleModule, NativeDateAdapter} from '@angular/material/core';
import {BrowserModule} from '@angular/platform-browser';
import {FullCalendarModule} from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import {environment} from '../environments/environment';
import {MatDialogModule} from '@angular/material/dialog';
import {NgcCookieConsentConfig} from 'ngx-cookieconsent';
import {FileSaverModule} from 'ngx-filesaver';
import {PrivacyDialogComponent} from './components/privacy-dialog/privacy-dialog.component';
import {PrivacyComponent} from './components/privacy/privacy.component';
import {PrivacyPageComponent} from './pages/privacy-page/privacy-page.component';
import {AuthConfigModule} from './security/auth-config/auth-config.module';
import {UserListComponent} from './components/user-list/user-list.component'; // a plugin
FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
]);


const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.cookie_domain
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#f1d600'
    }
  },
  theme: 'edgeless',
  type: 'opt-out'
};

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    CompanyComponent,
    DashboardComponent,
    CompanyFormComponent,
    ImagePreviewDirective,
    UserFormComponent,
    CompanyDashboardComponent,
    AdminComponent,
    HasRoleDirective,
    MachineComponent,
    FrontendComponent,
    MachineFormComponent,
    TargaReportComponent,
    GreenLabelReportComponent,
    LocationDialogComponent,
    MachineDashboardComponent,
    UnauthorizedErrorComponent,
    LogsComponent,
    MessagesComponent,
    AdmMessagesComponent,
    MessageFormComponent,
    LabelContributeChartComponent,
    AdmDashboardComponent,
    CompanyDialogComponent,
    ChartMapComponent,
    LabelsComponent,
    AgendaComponent,
    AgendaFormComponent,
    TrashComponent,
    CompareChartComponent,
    ConfigurationDialogComponent,
    LazyImageDirective,
    MaintenanceComponent,
    TrendClusterComponent,
    TrendCompanyClusterComponent,
    ScientificNotationPipe,
    PrivacyDialogComponent,
    PrivacyComponent,
    PrivacyPageComponent,
    UserListComponent
  ],
  entryComponents: [
    LocationDialogComponent,
    CompanyDialogComponent,
    ConfigurationDialogComponent,
    PrivacyDialogComponent
  ],
  imports: [
    FileSaverModule,
    LeafletModule,
    AuthConfigModule,
    LeafletMarkerClusterModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    FullCalendarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    appRoutes,
    MatDialogModule,
    MatRippleModule
  ],
  providers: [
    CompanyService,
    UserService,
    RoleGuard,
    RoleMachineGuard,
    SecureStorageService,
    MachineService,
    ParameterService,
    ProductCodesService,
    HasRoleDirective,
    DownloadServiceService,
    LogsService,
    MessageService,
    SnackService,
    StatisticsService,
    AgendaService,
    TrashService,
    TitleService,
    MaintenanceService,
    DataPassingService,

    {provide: DateAdapter, useClass: NativeDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

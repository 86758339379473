import { environment } from './../../../environments/environment';
import { FootprintContribute } from './../../models/machine';
import { Component, OnInit, Input } from '@angular/core';
import { Footprint } from "app/models/machine";

@Component({
  selector: 'label-contribute-chart',
  templateUrl: './label-contribute-chart.component.html',
  styleUrls: ['./label-contribute-chart.component.css']
})
export class LabelContributeChartComponent implements OnInit {

  @Input()
  footprint: Footprint;
  contributes: FootprintContribute[];


  view: any[] = [600, 250];

  // options
  showLegend = false;

  colorScheme = {
    domain: environment.color_scheme
  };

  // pie
  showLabels = true;
  explodeSlices = true;
  doughnut = false;

  ngOnInit() {
    this.contributes = [];
    this.footprint.contributes.filter(
      c => c.value > 0
    ).forEach(
      c => this.contributes.push(c)
      )
  }

}

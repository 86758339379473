import { Role } from './../models/role';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { User } from 'app/models';
import { MachineService } from 'app/services/machine.service';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {SecurityService} from '../services/security.service';

@Injectable()
export class RoleMachineGuard implements CanActivate {

    constructor(private router: Router, private ks: SecurityService, private machineService: MachineService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const roles = route.data['roles'] as Array<Role>;
        const user: User = this.ks.getUserInfo();
        const role: string = user.role;
        const rs: Array<Role> = roles.filter(r => r.key === role);
        if (rs && rs[0]) {
            if (rs[0].check === false) {
                return of(true);
            } else {
              return this.machineService.get(route.params['id']).pipe(map(r => r.company.id === user.company.id));
            }
        } else {
          return of(false);
        }
    }
}


import {SnackService} from './../../services/snack.service';
import {CompanyService} from 'app/services/company.service';
import {Company} from 'app/models';
import {Component, OnInit} from '@angular/core';
import {TdLoadingService} from '@covalent/core/loading';
import {MatDialogRef} from '@angular/material/dialog';
import {MatCheckboxChange} from '@angular/material/checkbox';

@Component({
  selector: 'app-company-dialog',
  templateUrl: './company-dialog.component.html',
  styleUrls: ['./company-dialog.component.css']
})
export class CompanyDialogComponent implements OnInit {

  companyList: Company[];
  selectedCount = 0;

  constructor(private companyService: CompanyService, private snackService: SnackService,
              private _loadingService: TdLoadingService, private dialogRef: MatDialogRef<Company[]>) {
  }

  ngOnInit() {
    this._loadingService.register('loading.company');
    this.companyService.findAll().subscribe(
      res => this.companyList = res,
      err => this.snackService.notifyErr(err),
      () => this._loadingService.resolve('loading.company')
    );
  }

  select() {
    this.dialogRef.close(
      this.companyList.filter(cc => cc['selected'] === true).map(cc => cc.id)
    );
  }

  close() {
    this.dialogRef.close();
  }

  switchCompany(evt: MatCheckboxChange, c: Company) {
    this.selectedCount = this.companyList.filter(cc => cc['selected'] === true).length;
    if (evt.checked) {
      if (this.selectedCount === 6) {
        c['selected'] = false;
        evt.source.toggle();
        this.selectedCount--;
      }
    }

  }


}

<mat-card>
  <mat-card-title>Compares</mat-card-title>
  <mat-divider></mat-divider>
  <mat-card-content>
    <div style="height:300px;">
      <ng-template tdLoading="load.compares">
        <div style="height: 250px" layout="row">
          <div flex="30">
            <ngx-charts-bar-vertical-2d *ngIf="comparesData" [scheme]="colorScheme2" [results]="[comparesData[0]]" xAxis="true" yAxis="true"
              showXAxisLabel="false" xAxisLabel="L/kg">
            </ngx-charts-bar-vertical-2d>
          </div>
          <div flex="30">
            <ngx-charts-bar-vertical-2d *ngIf="comparesData" [scheme]="colorScheme2" [results]="[comparesData[1]]" xAxis="true" yAxis="true"
              showXAxisLabel="false" xAxisLabel="kWh/kg">
            </ngx-charts-bar-vertical-2d>
          </div>
          <div flex="30">
            <ngx-charts-bar-vertical-2d *ngIf="comparesData" [scheme]="colorScheme2" [results]="[comparesData[2]]" xAxis="true" yAxis="true"
              showXAxisLabel="false" xAxisLabel="kg of CO2 eq/kg">
            </ngx-charts-bar-vertical-2d>
          </div>
        </div>
        <div flex *ngIf="comparesData" style="text-align:center">
          Legend -
          <ng-template let-l let-last="last" ngFor [ngForOf]="legend">
            <span [style.color]="l.color">{{l.name}}</span>
          </ng-template>
        </div>
      </ng-template>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button color="accent" class="text-upper" (click)="openDialog()" matTooltip="Select configurations to compare">
              <mat-icon class="mat-18">business</mat-icon> Select Configurations
          </button>
  </mat-card-actions>
</mat-card>

<mat-card flex tdMediaToggle="gt-xs" [mediaClasses]="['push']">
  <mat-card-title>
    <span class="mat-headline">Trash</span>
  </mat-card-title>
  <mat-divider></mat-divider>
  <mat-card-content>

    <mat-button-toggle-group (change)="loadElements()" [(ngModel)]="trashType" aria-label="Font Style">
      <mat-button-toggle value="company">Company</mat-button-toggle>
      <mat-button-toggle value="machine">Machine</mat-button-toggle>
      <mat-button-toggle value="configuration">Configuration</mat-button-toggle>
      <mat-button-toggle value="installation">Installation</mat-button-toggle>
    </mat-button-toggle-group>
    <ng-template tdLoading="loading.trash">
      <mat-list class="will-load" *ngIf="trashList">
        <ng-template let-t let-last="last" ngFor [ngForOf]="trashList">
          <mat-list-item>

            <h3 mat-line>
              {{t.contentType}}
            </h3>
            <p mat-line>
              <span [ngSwitch]="t.contentType">
                <span *ngSwitchCase="'configuration'">{{t.content['machineName']}}
                  - {{t.content['configuration']['year']}} {{t.content['configuration']['revision']}}</span>
              <span *ngSwitchCase="'installation'">{{t.content['machineName']}}
                - {{t.content['year']}} {{t.content['revision']}} - {{t.content['installation']['targaCode']}}
                - {{t.content['installation']['location']['name']}}</span>
              <span *ngSwitchCase="'machine'">{{t.content['machine']['id']}}
                - {{t.content['machine']['commercialName']}}</span>
              <span *ngSwitchCase="'company'">{{t.content['company']['id']}} - {{t.content['company']['name']}}</span>
              </span>
            </p>
            <p mat-line>
              {{t.deleteDate | timeAgo}}
            </p>
            <span>
                  <button mat-icon-button [mat-menu-trigger-for]="menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu x-position="before" #menu="matMenu">
                    <button (click)="restore(t)" mat-menu-item>Restore</button>
                    <button (click)="delete(t)" mat-menu-item>Delete</button>
                  </mat-menu>
              </span>
          </mat-list-item>
          <mat-divider *ngIf="!last" mat-inset></mat-divider>
        </ng-template>
      </mat-list>


    </ng-template>

  </mat-card-content>
  <mat-card-actions>
    <button mat-button color="accent" class="text-upper" (click)="emptyTrash()">Empty Trash</button>
  </mat-card-actions>
</mat-card>

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {SecurityService} from '../services/security.service';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PrivacyGuard implements CanActivate {


  constructor(private sec: SecurityService, private auth: OidcSecurityService) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.auth.isAuthenticated$.pipe(
      map(authenticated => {
        return authenticated;
      })
    );

  }

}

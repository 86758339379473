import { Installation } from './machine';
import { Company } from 'app/models';
import { State } from 'app/models/state';
export class ChartSeries {
  name: string;
  value: number;
}
export class ChartData {
  name: string;
  series: ChartSeries[];
}

export class CountryMap {
  country: State;
  count: number;
}

export class Labels {
  id: string;
  generationDate: Date;
  rev: string;
  year: number;
  globalFootprint: number;
  machineName: string;
  machineId: string;
  installations: Installation;
  company: Company;
}

export class CompareKey {
  id: string;
  rev: string;
  year: number;
}

export class TrendCompanyId {
  company: Company;
  year: number;
}
export class TrendCompanyCluster {
  id: TrendCompanyId;
  count;
  month: TrendClusterMonth[];
}

export class TrendCluster {
  id: number;
  count: number;
  month: TrendClusterMonth[];
}

export class TrendClusterMonth {
  month: number;
  count: number;
}

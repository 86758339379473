<td-layout-manage-list #manageList [opened]="media.registerQuery('gt-sm') | async" [mode]="(media.registerQuery('gt-sm') | async) ? 'side' : 'push'"
  [sidenavWidth]="(media.registerQuery('gt-xs') | async) ? '257px' : '100%'">
  <mat-toolbar td-sidenav-content>
    <span>Administration</span>
  </mat-toolbar>
  <mat-nav-list td-sidenav-content>
    <a *hasRole="['administrator']" mat-list-item mat-ripple class="block relative" (click)="!media.query('gt-sm') && manageList.close()"
      [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:false}" [routerLink]="['dashboard']">
      <mat-icon mat-list-icon>dashboard</mat-icon>
      Dashboard
    </a>
    <a *hasRole="['administrator']" mat-list-item mat-ripple class="block relative" (click)="!media.query('gt-sm') && manageList.close()"
      [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:false}" [routerLink]="['companies']">
      <mat-icon mat-list-icon>business</mat-icon>
      All Companies
    </a>
    <a *hasRole="['administrator','associate']" mat-list-item mat-ripple class="block relative" (click)="!media.query('gt-sm') && manageList.close()"
      [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:false}" [routerLink]="['companies/'+user?.company.id + '/']">
      <mat-icon mat-list-icon>business</mat-icon>
      My Company
    </a>
    <mat-divider></mat-divider>
    <a *hasRole="['administrator']" mat-list-item mat-ripple class="block relative" (click)="!media.query('gt-sm') && manageList.close()"
      [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:false}" [routerLink]="['/adm/agenda']">
      <mat-icon mat-list-icon>event</mat-icon>
      Agenda
    </a>
    <a *hasRole="['administrator']" mat-list-item mat-ripple class="block relative" (click)="!media.query('gt-sm') && manageList.close()"
      [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:false}" [routerLink]="['/adm/logs']">
      <mat-icon mat-list-icon>library_books</mat-icon>
      Logs
    </a>
    <a *hasRole="['administrator']" mat-list-item mat-ripple class="block relative" (click)="!media.query('gt-sm') && manageList.close()"
      [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:false}" [routerLink]="['/adm/messages']">
      <mat-icon mat-list-icon>message</mat-icon>
      Messages
    </a>
    <a *hasRole="['administrator','associate']" mat-list-item mat-ripple class="block relative" (click)="!media.query('gt-sm') && manageList.close()"
      [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:false}" [routerLink]="['/adm/trash']">
      <mat-icon mat-list-icon>delete</mat-icon>
      Trash
    </a>
    <a *hasRole="['administrator']" mat-list-item mat-ripple class="block relative" (click)="!media.query('gt-sm') && manageList.close()"
      [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:false}" [routerLink]="['/adm/maintenance']">
      <mat-icon mat-list-icon>perm_data_setting</mat-icon>
      Maintenance
    </a>
  </mat-nav-list>
  <mat-toolbar>
    <span>{{getTitle()}}</span>
    <span flex></span>
  </mat-toolbar>
  <div class="mat-content admin" flex mat-scroll-y>
    <router-outlet></router-outlet>
  </div>
</td-layout-manage-list>

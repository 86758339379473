import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {PrivacyCheck} from '../../models';
import {UserService} from '../../services/user.service';
import {SecurityService} from '../../services/security.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  checks: PrivacyCheck = {
    mandatoryCheck: true,
    receiveMailCheck: false,
    usingImagesCheck: false
  }

  @Output()
  privacyUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private userService: UserService, private security: SecurityService
  ) {
  }

  ngOnInit(): void {
    const user = this.security.getUserInfo();
    if (user && user.privacy) {
      this.checks = user.privacy;
    }
  }


  save() {
    this.userService.updatePrivacy(this.checks).subscribe(
      res => {
        this.security.setUserInfo(res);
        this.privacyUpdated.emit(true);
      },
      error => {
        this.privacyUpdated.emit(false);
      }
    );

  }

  leave() {
    this.privacyUpdated.emit(false);
  }
}

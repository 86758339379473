import {TitleService} from 'app/services/title.service';
import {SnackService} from 'app/services/snack.service';
import {TrashService} from './../../services/trash.service';
import {User} from './../../models/user';
import {Company} from './../../models/company';
import {CompanyService} from './../../services/company.service';
import {AfterViewInit, ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SecureStorageService} from 'app/services/secure-storage.service';
import {TdLoadingService} from '@covalent/core/loading';
import {TdMediaService} from '@covalent/core/media';
import {TdDialogService} from '@covalent/core/dialogs';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyComponent implements AfterViewInit, OnInit {
  companyList: Company[];
  filteredCompanyList: Array<Company>;
  user: User;
  public media: TdMediaService;
  private _loadingService: TdLoadingService;
  private _dialogService: TdDialogService;

  constructor(
    private _router: Router,
    private injector: Injector,
    private cs: CompanyService,
    private trashService: TrashService,
    private snackService: SnackService,
    private titleService: TitleService,
    private secureStorage: SecureStorageService,
  ) {
    this.titleService.setTitle('Admin Companies');
    this._loadingService = injector.get(TdLoadingService);
    this.media = injector.get(TdMediaService);
    this._dialogService = injector.get(TdDialogService);
  }

  ngOnInit() {
    this.user = JSON.parse(this.secureStorage.retrieve('user'));
  }

  ngAfterViewInit(): void {
    // broadcast to all listener observables when loading the page
    this.media.broadcast();
    this.loadCompany();
  }

  goBack(route?: string): void {
    this._router.navigate(['/']);
  }

  filterCompany(displayName: string = ''): void {
    if (this.companyList) {
      this.filteredCompanyList = this.companyList.filter((c: Company) => {
        return c.name.toLowerCase().indexOf(displayName.toLowerCase()) > -1;
      });
    }
  }

  loadCompany(): void {
    this._loadingService.register('company.list');
    this.cs.findAll().subscribe(
      res => {
        this.companyList = res;
        this.filteredCompanyList = res;
        this._loadingService.resolve('company.list');
      },
      err => {
        this.companyList = [];
        this.filteredCompanyList = [];
        this._loadingService.resolve('company.list');
      }
    );
  }

  deleteCompany(id: string): void {
    this._dialogService
      .openConfirm({
        message:
          'Are you sure you want to delete this company? All the related user will be removed!'
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this._loadingService.register('company.list');
          // this.cs.delete(id).subscribe(() => {
          this.trashService.trashCompany(id).subscribe(
            res => {
              this.companyList = this.companyList.filter((c: Company) => {
                return c.id !== res;
              });
              this.filteredCompanyList = this.filteredCompanyList.filter(
                (c: Company) => {
                  return c.id !== res;
                }
              );
              this.snackService.notifyInfo('Company deleted');
            },
            err => {
              this.snackService.notifyErr(err);
            },
            () => {
              this._loadingService.resolve('company.list');
            }
          );
        }
      });
  }
}

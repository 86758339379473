import { MachineParams } from './../models/machine-params';
import { BaseService } from './base-service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { State } from 'app/models/state';
import { environment } from 'environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ParameterService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  public loadMachineConfiguration(): Observable<MachineParams> {
    const pageUrl: string = environment.api_endpoint + 'config/machine-params/';
    return this.http.get<MachineParams>(pageUrl)
      ;
  }

  public loadStates(): Observable<State[]> {
    const pageUrl: string = environment.api_endpoint + 'countries/';
    return this.http.get<State[]>(pageUrl)
      ;
  }

}

<div>
  <div layout="row" class="pull-bottom-md pull-right-md">
    <ng-template tdLoading="load.numbers.chart">
      <ngx-charts-number-card *ngIf="numbersData" [scheme]="colorScheme" [results]="numbersData">
      </ngx-charts-number-card>
    </ng-template>
  </div>
  <div layout-gt-xs="row">
    <div flex-gt-xs="50">
      <mat-card>
        <mat-card-title>Label Trend</mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content>
          <ng-template tdLoading="load.trend.chart">
            <div *ngIf="trendMode==='trendCluster' && trendCluster">
              <gl-trend-cluster [trend]="trendCluster"></gl-trend-cluster>
            </div>
            <div *ngIf="trendMode==='trendCompanyCluster' && trendCompanyCluster">
              <gl-trend-company-cluster [trend]="trendCompanyCluster"></gl-trend-company-cluster>
            </div>
          </ng-template>

        </mat-card-content>
        <mat-divider></mat-divider>
        <mat-card-actions>
          <button mat-button color="accent" class="text-upper" (click)="labelTrend()" matTooltip="Show Label Trend">
            <mat-icon class="mat-18">all_inclusive</mat-icon> Label Trend
          </button>
          <button mat-button color="accent" class="text-upper" (click)="openDialog()" matTooltip="Show Trend for selected Companies">
            <mat-icon class="mat-18">business</mat-icon> Trend by company
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
    <div flex-gt-xs="50">
      <mat-card>
        <mat-card-title>Installations map</mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content>
          <ng-template tdLoading="load.country.map">
            <chart-map *ngIf="countryMapData" [data]="countryMapData"></chart-map>
          </ng-template>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div layout-gt-xs="row">
    <div flex>
      <mat-card>
        <mat-card-title>
          Labels for Companies
        </mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content>
          <ng-template tdLoading="load.company-labels.chart">
            <div style="height:300px;" *ngIf="labelsForCompaniesData">
              <ngx-charts-bar-vertical [scheme]="colorScheme2" [results]="labelsForCompaniesData" xAxis="true" yAxis="true" [legend]="false"
                showXAxisLabel="true" showYAxisLabel="true" xAxisLabel="Company" yAxisLabel="Generated Labels">
              </ngx-charts-bar-vertical>
            </div>
          </ng-template>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div layout-gt-xs="row">
    <div flex-gt-xs="50">
      <mat-card>
        <div layout="row" layout-align="start center" class="push-left push-right">
          <span class="mat-card-title">Labels</span>
          <span flex></span>
          <td-search-box backIcon="arrow_back" placeholder="Search by label code" (searchDebounce)="filterLabels($event)" (search)="filterLabels($event)"
            (clear)="filterLabels('')">
          </td-search-box>
        </div>
        <mat-divider></mat-divider>
        <mat-card-content>
          <ng-template tdLoading="load.labels">
            <labels *ngIf="filteredLabelsData" [data]="filteredLabelsData"></labels>
          </ng-template>
        </mat-card-content>
      </mat-card>
    </div>
    <div flex-gt-xs="50">
      <agenda></agenda>
    </div>
  </div>
</div>

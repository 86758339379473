import {Component, Input, OnChanges} from '@angular/core';
import {ChartData, ChartSeries, TrendCompanyCluster} from 'app/models';
import {environment} from 'environments/environment';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {MatSelectChange} from '@angular/material/select';

@Component({
  selector: 'gl-trend-company-cluster',
  templateUrl: './trend-company-cluster.component.html',
  styleUrls: ['./trend-company-cluster.component.scss']
})
export class TrendCompanyClusterComponent implements OnChanges {
  @Input() trend: TrendCompanyCluster[];
  mode: 'year' | 'month' = 'year';

  monthsName = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  chart: ChartData[];
  year: number;
  yearList: number[];
  xLabel: string;
  legend: any[];
  public colorScheme = {
    domain: environment.color_scheme
  };

  ngOnChanges() {
    this.yearList = this.trend.map(t => t.id.year).filter((value, index, self) => self.indexOf(value) === index).sort((n1, n2) => n1 - n2);
    if (this.yearList && this.yearList.length > 0) {
      this.year = this.yearList[0];
    }
    this.prepareChart();
  }

  switchMode(event: MatSlideToggleChange) {
    console.log(event);
    this.mode = this.mode === 'year' ? 'month' : 'year';
    this.prepareChart();
  }

  changeYear(event: MatSelectChange) {
    console.log(event);
    console.log(this.year);
    this.prepareChart();
  }

  private prepareChart() {
    switch (this.mode) {
      case 'year': {
        this.initYear();
      }
        break;
      case 'month': {
        this.initMonth();
      }
        break;
    }
  }

  private initMonth() {
    this.xLabel = 'Months of ' + this.year;
    const months: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const companies: string[] = [];
    const cdatas: ChartData[] = [];
    const tr = this.trend.filter(t => t.id.year === this.year);
    tr.forEach(t => {

      months.forEach(m => {
        let cd: ChartData = null;
        if (cdatas['' + m]) {
          cd = cdatas['' + m];
        } else {
          cd = new ChartData();
          cd.name = String(m);
          cd.series = [];
          cdatas[m] = cd;
        }
        if (companies.indexOf(t.id.company.name) < 0) {
          companies.push(t.id.company.name);
        }
        const _m = t.month.filter(tm => tm.month === m);
        const cs: ChartSeries = new ChartSeries();
        cs.name = t.id.company.name;
        if (_m && _m[0]) {
          cs.value = _m[0].count;
        } else {
          cs.value = 0;
        }
        cd.series.push(cs);
      });
    });

    this.chart = [];
    months.forEach(mn => this.chart.push(cdatas[mn]));
    console.log(this.chart);

    this.createLegend(companies);
  }

  private createLegend(companies: string[]) {
    this.legend = [];
    companies.forEach((s, i) => {
      this.legend.push({
        name: s,
        color: this.colorScheme.domain[i]
      });
    });
  }

  private initYear() {
    console.log(this.trend);
    this.xLabel = 'Years';
    const years: number[] = [];
    const companies: string[] = [];
    const cdatas: ChartData[] = [];
    this.trend.forEach(t => {
      let cd: ChartData = null;
      if (cdatas['' + t.id.year]) {
        cd = cdatas['' + t.id.year];
      } else {
        cd = new ChartData();
        cd.name = String(t.id.year);
        cd.series = [];
        cdatas[t.id.year] = cd;
        years.push(t.id.year);
      }
      if (companies.indexOf(t.id.company.name) < 0) {
        companies.push(t.id.company.name);
      }
      const cs: ChartSeries = new ChartSeries();
      cs.name = t.id.company.name;
      cs.value = t.count;
      cd.series.push(cs);
    });
    this.chart = [];
    years.sort((n1, n2) => n1 - n2);
    years.forEach(y => this.chart.push(cdatas[y]));

    this.createLegend(companies);
  }
}

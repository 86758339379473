<div>
  <div *ngIf="isAdministrator" layout-gt-xs="row">
    <div flex-gt-xs>
      <mat-card>
        <mat-card-content>
          <div class="form-group" layout="row" flex>
            <ng-template flex="50" tdLoading="loading.companies">
              <mat-form-field>
                <mat-select #companySelect placeholder="Select the company" (selectionChange)="selectCompany($event)" [(ngModel)]="company" name="company">
                  <mat-option *ngFor="let c of companies" [value]="c">
                    {{c.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </ng-template>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div layout-gt-xs="row">
    <div flex-gt-xs="50">
      <mat-card>
        <mat-card-title>Label Trend</mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content>
          <ng-template tdLoading="load.trend.chart">
            <div *ngIf="trendCluster">
                <gl-trend-cluster [trend]="trendCluster"></gl-trend-cluster>
              </div>
          </ng-template>
        </mat-card-content>
        <mat-divider></mat-divider>
      </mat-card>
    </div>
    <div flex-gt-xs="50">
      <mat-card>
        <mat-card-title>Installations Map</mat-card-title>
        <mat-divider></mat-divider>
        <mat-card-content>
          <ng-template tdLoading="load.country.map">
            <chart-map *ngIf="countryMapData" [data]="countryMapData"></chart-map>
          </ng-template>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div layout-gt-xs="row">
    <div flex-gt-xs="50">
      <mat-card>
        <div layout="row" layout-align="start center" class="push-left push-right">
          <span class="mat-card-title">Labels</span>
          <span flex></span>
          <td-search-box backIcon="arrow_back" placeholder="Search by label code" (searchDebounce)="filterLabels($event)" (search)="filterLabels($event)"
            (clear)="filterLabels('')">
          </td-search-box>
        </div>
        <mat-divider></mat-divider>
        <mat-card-content>
          <ng-template tdLoading="load.labels">
            <labels *ngIf="filteredLabelsData" [data]="filteredLabelsData"></labels>
          </ng-template>
        </mat-card-content>
      </mat-card>
    </div>
    <div flex-gt-xs="50">
      <compare-chart [company]="company"></compare-chart>
    </div>
  </div>
</div>

import { SnackService } from '../../../services/snack.service';
import { TrashService } from '../../../services/trash.service';
import { DownloadServiceService } from '../../../services/download-service.service';
import { MachineService } from 'app/services/machine.service';
import { Router } from '@angular/router';
import { Machine, Configuration, Installation } from 'app/models/machine';
import {Component, OnInit, Input, OnChanges, SimpleChanges, Injector} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';
import {LocationDialogComponent} from 'app/components/location-dialog/location-dialog.component';
import {Observable, of} from 'rxjs';
import {LoadingMode, LoadingType, TdLoadingService} from '@covalent/core/loading';
import {MatDialog} from '@angular/material/dialog';
import {TdDialogService} from '@covalent/core/dialogs';
import {IPageChangeEvent} from '@covalent/core/paging';

@Component({
  selector: 'machine-dashboard',
  templateUrl: './machine-dashboard.component.html',
  styleUrls: ['./machine-dashboard.component.scss']
})
export class MachineDashboardComponent implements OnInit, OnChanges {
  @Input() machine: Machine;
  @Input() revision: string;
  @Input() year: number;
  machineList: Machine[];
  configuration: Configuration;
  configurationsPage: Configuration[];
  installation: Installation;
  installations: Installation[];
  installationsPage: Installation[];
  configurationParams: {
    name: string | Observable<string>;
    unit: string | Observable<string>;
    value: string | number;
    key: string;
    type: string;
  }[];
  configurationParamsPage: {
    name: string | Observable<string>;
    unit: string | Observable<string>;
    value: string | number;
    key: string;
    type: string;
  }[];
  pageSize = 5;
  pageSizeHigh = 3;
  private _dialogService: TdDialogService;
  protected _loadingService: TdLoadingService;
  private matDialog: MatDialog;
  constructor(
    private router: Router,
    private machineService: MachineService,
    private _translateService: TranslateService,
    private ds: DownloadServiceService,
    private trashService: TrashService,
    private snackService: SnackService,
    private injector: Injector
  ) {
    _translateService.setDefaultLang('en');
    _translateService.use('en');
    this._loadingService = injector.get(TdLoadingService);
    this._dialogService = injector.get(TdDialogService);
    this.matDialog = injector.get(MatDialog);
    this._loadingService.create({
      name: 'dashboard.full',
      mode: LoadingMode.Indeterminate,
      type: LoadingType.Circular,
      color: 'accent'
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.machine) {
      console.log('Machine', this.machine);
      this.configurationsPage = this.machine.configuration.slice(
        0,
        this.pageSizeHigh
      );
      if (this.revision && this.year) {
        // tslint:disable-next-line:max-line-length
        this.selectConfiguration(
          this.machine.configuration.filter(c => {
            return c.revision === this.revision && c.year === +this.year;
          })[0]
        );
      }
    }
  }

  ngOnInit() {
    this._translateService.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this._translateService.use('en');
  }

  downloadFile(key: string) {
    this.ds.downloadParamFile(
      this.machine.id,
      this.configuration.revision,
      this.configuration.year,
      key
    );
  }

  loadConfiguration(c: Configuration) {
    this.selectConfiguration(c);
    window.history.pushState(
      'DashboardMachine',
      'Dashboard Machine',
      '/machines/' + this.machine.id + '/' + c.revision + '/' + c.year
    );
  }

  selectInstallation(i: Installation) {
    this.installation = i;
  }

  selectConfiguration(c: Configuration) {
    this.installation = null;
    if (!c) {
      return;
    }
    this.installations = c.installations;
    this.installationsPage = this.installations.slice(0, this.pageSizeHigh);
    this._loadingService.register('configuration.load');
    this.configuration = c;
    this.configurationParams = [];
    // c.files.find(f => f.params.indexOf('installedPower') >= 0).path
    this.initializeConfigurationParams();

    this.configurationParamsPage = this.configurationParams.slice(
      0,
      this.pageSize
    );
    console.log(this.configurationParams);
    console.log(this.configurationParamsPage);
    this._loadingService.resolve('configuration.load');
  }

  initializeConfigurationParams() {
    this.configureProcessDescriptorParams();

    this.configureConsumptionParams();

    this.configureWashingAgentsParams();

    this.configureChemicalProcessParams();

    this.configureCustomProcessParams();
  }

  private configureCustomProcessParams() {
    if (this.configuration.params.customProssesInput) {
      this.configuration.params.customProssesInput.forEach(cpi => {
        this.configurationParams.push({
          name: of(cpi.key),
          unit: of(cpi.um),
          value: cpi.value,
          type: null,
          key: 'customProcessInput'
        });
      });
    }
  }

  private configureChemicalProcessParams() {
    if (this.configuration.params.chemicalsProcess) {
      if (this.configuration.params.chemicalsProcess.salt) {
        this.configurationParams.push({
          name: this._translateService.get('PARAMS.SALT'),
          unit: this._translateService.get('PARAMS.SALT_UNIT'),
          value: this.configuration.params.chemicalsProcess.salt,
          type: null,
          key: 'salt'
        });
      }
      if (this.configuration.params.chemicalsProcess.inorganic) {
        this.configurationParams.push({
          name: this._translateService.get('PARAMS.INORGANIC'),
          unit: this._translateService.get('PARAMS.INORGANIC_UNIT'),
          value: this.configuration.params.chemicalsProcess.inorganic,
          type: null,
          key: 'inorganic'
        });
      }
      if (this.configuration.params.chemicalsProcess.organic) {
        this.configurationParams.push({
          name: this._translateService.get('PARAMS.ORGANIC'),
          unit: this._translateService.get('PARAMS.ORGANIC_UNIT'),
          value: this.configuration.params.chemicalsProcess.organic,
          type: null,
          key: 'organic'
        });
      }
    }
  }

  private configureWashingAgentsParams() {
    if (this.configuration.params.washingAgents) {
      if (this.configuration.params.washingAgents.auxiliaires) {
        this.configurationParams.push({
          name: this._translateService.get('PARAMS.AUXILIAIRES'),
          unit: this._translateService.get('PARAMS.AUXILIAIRES_UNIT'),
          value: this.configuration.params.washingAgents.auxiliaires,
          type: null,
          key: 'auxiliaires'
        });
      }
      if (this.configuration.params.washingAgents.bleaches) {
        this.configurationParams.push({
          name: this._translateService.get('PARAMS.BLEACHES'),
          unit: this._translateService.get('PARAMS.BLEACHES_UNIT'),
          value: this.configuration.params.washingAgents.bleaches,
          type: null,
          key: 'bleaches'
        });
      }
      if (this.configuration.params.washingAgents.buildersSoftenings) {
        this.configurationParams.push({
          name: this._translateService.get('PARAMS.BUILDERS_SOFTENINGS'),
          unit: this._translateService.get('PARAMS.BUILDERS_SOFTENINGS_UNIT'),
          value: this.configuration.params.washingAgents.buildersSoftenings,
          type: null,
          key: 'buildersSoftenings'
        });
      }
      if (this.configuration.params.washingAgents.tensidesSurfactants) {
        this.configurationParams.push({
          name: this._translateService.get('PARAMS.TENSIDES_SURFACTANTS'),
          unit: this._translateService.get('PARAMS.TENSIDES_SURFACTANTS_UNIT'),
          value: this.configuration.params.washingAgents.tensidesSurfactants,
          type: null,
          key: 'tensidesSurfactants'
        });
      }
    }
  }

  private configureConsumptionParams() {
    if (this.configuration.params.consumption) {
      this.configurationParams.push({
        name: this._translateService.get('PARAMS.POWER_CONSUMPTION'),
        unit: this._translateService.get('PARAMS.POWER_CONSUMPTION_UNIT'),
        value: this.configuration.params.consumption.powerConsumption,
        type: null,
        key: 'powerConsumption'
      });
      this.configurationParams.push({
        name: this._translateService.get('PARAMS.WATER'),
        unit: this._translateService.get('PARAMS.WATER_UNIT'),
        value: this.configuration.params.consumption.water,
        type: null,
        key: 'water'
      });
      if (this.configuration.params.consumption.compressedAir) {
        this.configurationParams.push({
          name: this._translateService.get('PARAMS.COMPRESSED_AIR'),
          unit: this._translateService.get('PARAMS.COMPRESSED_AIR_UNIT'),
          value: this.configuration.params.consumption.compressedAir,
          type: this.configuration.params.consumption.compressedAirType,
          key: 'compressedAir'
        });
      }
      if (this.configuration.params.consumption.naturalGas) {
        this.configurationParams.push({
          name: this._translateService.get('PARAMS.NATURAL_GAS'),
          unit: of(
            this.configuration.params.consumption.naturalGasUM + '/kg'
          ),
          value: this.configuration.params.consumption.naturalGas,
          type: null,
          key: 'naturalGas'
        });
      }
      if (this.configuration.params.consumption.steam) {
        this.configurationParams.push({
          name: this._translateService.get('PARAMS.STEAM'),
          unit: of(
            this.configuration.params.consumption.steamUM + '/kg'
          ),
          value: this.configuration.params.consumption.steam,
          type: this.configuration.params.consumption.steamType,
          key: 'steam'
        });
      }
    }
  }

  private configureProcessDescriptorParams() {
    if (this.configuration.params.processDescriptor) {
      this.configurationParams.push({
        name: this._translateService.get('PARAMS.INSTALLED_POWER'),
        unit: this._translateService.get('PARAMS.INSTALLED_POWER_UNIT'),
        value: this.configuration.params.processDescriptor.installedPower,
        type: null,
        key: 'installedPower'
      });
      this.configurationParams.push({
        name: this._translateService.get('PARAMS.PROCESS_EFFICIENCY'),
        unit: this._translateService.get('PARAMS.PROCESS_EFFICIENCY_UNIT'),
        value: this.configuration.params.processDescriptor.processEfficiency,
        type: null,
        key: 'processEfficiency'
      });
      this.configurationParams.push({
        name: this._translateService.get('PARAMS.SOUND_EMISSION'),
        unit: this._translateService.get('PARAMS.SOUND_EMISSION_UNIT'),
        value: this.configuration.params.processDescriptor.soundEmissions,
        type: null,
        key: 'soundEmissions'
      });
    }
  }

  changePageCP(evt: IPageChangeEvent) {
    this.configurationParamsPage = this.configurationParams.slice(
      (evt.page - 1) * evt.pageSize,
      evt.page * evt.pageSize
    );
  }

  changePageInstallations(evt: IPageChangeEvent) {
    this.installationsPage = this.installations.slice(
      (evt.page - 1) * evt.pageSize,
      evt.page * evt.pageSize
    );
  }

  changePageConfigurations(evt: IPageChangeEvent) {
    this.configurationsPage = this.machine.configuration.slice(
      (evt.page - 1) * evt.pageSize,
      evt.page * evt.pageSize
    );
  }

  selectMachine(evt: any) {
    this.configuration = null;
    this.configurationParamsPage = null;
    this.installationsPage = null;
    this.router.navigate(['/machines/', this.machine.id]);
  }

  newInstallation(machineId: string, revision: string, year: number) {
    this.matDialog
      .open(LocationDialogComponent)
      .afterClosed()
      .subscribe(
        res => {
          if (res) {
            this._loadingService.register('dashboard.full');
            let installation: Installation = new Installation();
            installation.location = res;
            this.machineService
              .buildTarga(machineId, revision, year, installation)
              .subscribe(
                i => {
                  installation = i;
                },
                err => {
                  this._loadingService.resolve('dashboard.full');
                  this.snackService.notifyErr(err);
                },
                () => {
                  // :id/green-label/:labelId
                  this._loadingService.resolve('dashboard.full');
                  this.router.navigate([
                    'machines',
                    machineId,
                    'green-label',
                    installation.targaCode
                  ]);
                }
              );
          }
        },
        err => {
          this._loadingService.resolve('dashboard.full');
          this.snackService.notifyErr(err);
        } // console.log(err)
      );
  }

  validateUrl(url: string): boolean {
    if (url) {
      return /^(ftp|http|https):\/\/[^ "]+$/.test(url);
      // /^((ftp|http|https):\/\/)?[^ "]+$/
    } else {
      return false;
    }
  }

  duplicateConfiguration() {
    console.log('dupliacete');
    this.router.navigate(['/machines/', this.machine.id, this.configuration.revision, this.configuration.year, 'clone']);
  }

  deleteConfiguration() {
    this._dialogService
      .openConfirm({
        message:
          'Are you sure you want to delete this configuration? All the related installation and labels will be removed!'
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this._loadingService.register('machine.load');
          // this.cs.delete(id).subscribe(() => {
          this.trashService
            .trashConfiguration(
              this.machine.id,
              this.configuration.revision,
              this.configuration.year
            )
            .subscribe(
              res => {
                this.configuration = null;
                this.selectMachine(null);
                this.snackService.notifyInfo('Configuration deleted');
              },
              err => {
                this.snackService.notifyErr(err);
              },
              () => {
                this._loadingService.resolve('machine.load');
              }
            );
        }
      });
  }

  deleteMachine() {
    this._dialogService
      .openConfirm({
        message:
          'Are you sure you want to delete this machine? All the related configurations and labels will be removed!'
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this._loadingService.register('machine.load');
          // this.cs.delete(id).subscribe(() => {
          this.trashService.trashMachine(this.machine.id).subscribe(
            res => {
              this.configuration = null;
              this.machine = null;
              this.snackService.notifyInfo('Machine deleted');
              this.router.navigate(['/machines/']);
            },
            err => {
              this.snackService.notifyErr(err);
            },
            () => {
              this._loadingService.resolve('machine.load');
            }
          );
        }
      });
  }

  deleteInstallation() {
    this._dialogService
      .openConfirm({
        message:
          'Are you sure you want to delete this installation? The label will be removed!'
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this._loadingService.register('machine.load');
          // this.cs.delete(id).subscribe(() => {
          this.trashService
            .trashInstallation(
              this.machine.id,
              this.configuration.revision,
              this.configuration.year,
              this.installation.targaCode
            )
            .subscribe(
              res => {
                this.selectMachine(null);
                this.snackService.notifyInfo('Installation deleted');
              },
              err => {
                this.snackService.notifyErr(err);
              },
              () => {
                this._loadingService.resolve('machine.load');
              }
            );
        }
      });
  }
}

import { TitleService } from 'app/services/title.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { Message } from 'app/models/message';
import { MessageService } from 'app/services/message.service';
import { SnackService } from 'app/services/snack.service';
import {TdLoadingService} from '@covalent/core/loading';

@Component({
  selector: 'app-adm-messages',
  templateUrl: './adm-messages.component.html',
  styleUrls: ['./adm-messages.component.css']
})
export class AdmMessagesComponent implements OnInit {
  messages: Message[];

  message: Message;

  stateNewMessage = false;

  constructor(
    private titleService: TitleService,
    private _loadingService: TdLoadingService,
    private messageService: MessageService,
    private _route: ActivatedRoute,
    private _router: Router,
    private snackService: SnackService
  ) {
    this.titleService.setTitle('Admin Messages');
  }

  ngOnInit() {
    this._loadingService.register('message.list');
    this.messageService.getAllMessages().subscribe(
      res => {
        this.messages = res;
      },
      err => this.snackService.notifyErr(err),
      () => this._loadingService.resolve('message.list')
    );
  }

  selectMessage(m: Message) {
    this.message = m;
    // this.location.replace("/messages/" + m.id);
    // this._router.
    window.history.pushState('Message', 'Message', '/adm/messages/' + m.id);
  }

  newMessage() {
    this.stateNewMessage = true;
  }
}

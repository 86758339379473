import { Component, OnInit } from '@angular/core';
import { User, Company } from 'app/models';
import { SnackService } from 'app/services/snack.service';
import { SecureStorageService } from 'app/services/secure-storage.service';
import { CompanyService } from 'app/services/company.service';
import { TitleService } from 'app/services/title.service';
import { MaintenanceService } from 'app/services/maintenance.service';
import { Batch } from 'app/models/batch';
import {TdLoadingService} from '@covalent/core/loading';

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {
    user: User;
    company: Company;
    companies: Company[];
    isAdministrator = false;
    batch: Batch;
    running = false;

    constructor(
        private snackService: SnackService,
        private secureStorage: SecureStorageService,
        private _loadingService: TdLoadingService,
        private companyService: CompanyService,
        private maintenanceService: MaintenanceService,
        private titleService: TitleService
    ) {
        this.titleService.setTitle('Maintenance');
    }

    ngOnInit() {
        this.user = JSON.parse(this.secureStorage.retrieve('user'));
        this.isAdministrator = this.user.role === 'administrator';
        if (this.isAdministrator) {
            this._loadingService.register('loading.companies');
            this.companyService
                .findAll()
                .subscribe(
                    res => (this.companies = res),
                    err => this.snackService.notifyErr(err),
                    () => this._loadingService.resolve('loading.companies')
                );
        }
        this.company = this.user.company;
    }

    startMonitor(batch: Batch) {
        this.batch = batch;
        if (batch && batch.completePercent < 100) {
            setTimeout(() => {
                this.maintenanceService.monitor(this.batch.id).subscribe(
                    res => {
                        this.startMonitor(res);
                    },
                    err => this.snackService.notifyErr(err)
                );
            }, 1000);
        } else {
            this.running = false;
            this.snackService.notifyInfo('Labels regeneration process terminated');
        }
    }

    rebuildLabelsByCompany() {
        this.running = true;
        this.maintenanceService.rebuildByCompany(this.company.id).subscribe(
            res => {
              this.checkRunningProcess(res);
            },
            err => this.snackService.notifyErr(err)
        );
    }

    fillInstallation() {
      this.running = true;
      this.maintenanceService.fillInstallation().subscribe(
          res => {
              if (res.running) {
                  this.snackService.notifyInfo('Filling installation process started succesfully');
                  this.startMonitor(res);
              } else {
                  this.running = false;
              }
          },
          err => this.snackService.notifyErr(err)
      );
    }

    rebuildLabels() {
        this.running = true;
        this.maintenanceService.rebuildAll().subscribe(
            res => {
                this.checkRunningProcess(res);
            },
            err => this.snackService.notifyErr(err)
        );
    }

  private checkRunningProcess(res: Batch) {
    if (res.running) {
      this.snackService.notifyInfo('Labels regeneration process started succesfully');
      this.startMonitor(res);
    } else {
      this.running = false;
    }
  }
}

import { User } from 'app/models';
import { SecureStorageService } from './../services/secure-storage.service';
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[hasRole]'
})
export class HasRoleDirective {

  user: User;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private secureStorage: SecureStorageService
  ) {
    this.user = JSON.parse(this.secureStorage.retrieve('user'));
  }

  hasRoles(roles: string[], userRole: string): boolean {
    return roles.indexOf(userRole) >= 0;
  }

  @Input() set hasRole(roles: string[]) {
    if (!this.user || !roles || roles.length === 0) {
      this.viewContainer.clear();
    } else {

      if (this.hasRoles(roles, this.user.role)) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    }
  }
}

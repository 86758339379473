import {
  Cap,
  ChemicalsProcess,
  Configuration,
  Consumption,
  CustomProssesInput,
  Files,
  Installation,
  Machine, Param,
  ProcessDescriptor,
  ProductCodes,
  Sub,
  WashingAgent,
  WorkCycle,
  WorkCyclePC
} from '../../models';
import {ProductCodesService} from '../../services/product-codes.service';
import {Directive, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {TdLoadingService} from '@covalent/core/loading';
import {SnackService} from '../../services/snack.service';
import {mergeMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {MachineService} from '../../services/machine.service';
import {Observable} from 'rxjs/internal/Observable';

export class ParamsCheck {
  installedPower: boolean;
  soundEmissions: boolean;
  water: boolean;
  powerConsumption: boolean;
  compressedAir: boolean;
  steam: boolean;
  naturalGas: boolean;
  auxiliaires: boolean;
  bleaches: boolean;
  buildersSoftenings: boolean;
  tensidesSurfactants: boolean;
  inorganic: boolean;
  organic: boolean;
  salt: boolean;
  customProcessInput: boolean;


}

@Directive()
// tslint:disable-next-line:directive-class-suffix
export class MachineFormBaseComponent {
  machine: Machine;
  configuration: Configuration;
  consumption: Consumption;
  washingAgent: WashingAgent;
  chemicalsProcess: ChemicalsProcess;
  processDescriptor: ProcessDescriptor;
  customProssesInputs: CustomProssesInput[];
  customProssesInput: CustomProssesInput;
  installation: Installation;

  productCodes: ProductCodes;
  cap: Cap;
  capSubs: Cap;
  sub: Sub;
  workCycle: WorkCyclePC;
  workCycleOther: string;
  @ViewChild('processDescriptorForm') processDescriptorForm: NgForm;
  @ViewChild('consumptionForm') consumptionForm: NgForm;
  @ViewChild('washingAgentForm') washingAgentForm: NgForm;
  @ViewChild('chemicalsProcessForm') chemicalsProcessForm: NgForm;
  @ViewChild('customProcessInputForm') customProcessInputForm: NgForm;
  @ViewChild('machineForm') machineForm: NgForm;

  currentYear = new Date().getFullYear();
  mode: 'newm' | 'newc' | 'clone' = 'newm'; // true = add new machine, false=new config
  uploadFiles: Files[] = [];
  istruzioniOperativeFile: any;

  constructor(protected productCodesService: ProductCodesService,
              protected _loadingService: TdLoadingService,
              protected snackService: SnackService,
              protected router: Router,
              protected machineService: MachineService) {
  }

  newMachine() {
    this.mode = 'newm';
    this.machine = new Machine();
    this.machine.id = null;
    this.configuration = new Configuration();
    this.processDescriptor = new ProcessDescriptor();
    this.processDescriptor.workCycle = new WorkCycle();
    this.consumption = new Consumption();
    this.washingAgent = new WashingAgent();
    this.chemicalsProcess = new ChemicalsProcess();
    this.customProssesInputs = [];
    this.installation = new Installation();
  }

  loadSubs(event) {
    if (this.cap.key) {
      this.productCodesService.getSubs(this.cap.key).subscribe(
        res => {
          this.capSubs = res.caps[0];
          this.workCycle = null;
        },
        err => console.log(err)
      );
    } else {
      this.capSubs = null;
      this.sub = null;
      this.workCycle = null;
    }
  }


  validateLocation() {
    if (this.installation.location && this.installation.location.id) {
      this.machineForm.controls['location'].setErrors(null);
      this.installation['valid'] = false;
    } else {
      this.machineForm.controls['location'].setErrors({notValid: true});
      this.installation['valid'] = true;
    }
  }


  isAlfaNumeric(ch) {
    return /^[\w]*(([\s\w.,\-]*[\w])*)$/.test(ch);
  }

  validateRevision() {
    if (!this.configuration.revision || !this.isAlfaNumeric(this.configuration.revision)) {
      this.machineForm.controls['revision'].setErrors({notValid: true});
      this.configuration['notValid'] = true;
      this.machineForm.control.setErrors({notValid: true});
    } else {
      this.machineForm.controls['revision'].setErrors(null);
      this.configuration['notValid'] = false;
    }
    if (!this.configuration.year || this.configuration.year < this.currentYear - 1 || this.configuration.year > this.currentYear) {
      this.machineForm.controls['year'].setErrors({notValid: true});
      this.configuration['notValid'] = true;
    } else {
      this.machineForm.controls['year'].setErrors(null);
      this.configuration['notValid'] = false;
    }
    this.validateRevisionForNewOrCloneConfiguration()
  }

  validateRevisionForNewOrCloneConfiguration() {
    if (this.mode === 'newc' || this.mode === 'clone') {
      if (this.configuration.revision && this.configuration.year) {
        this._loadingService.register('validateConfigurationData');
        if (this.machine.configuration &&
          this.machine.configuration.filter(
            co =>
              co.revision === this.configuration.revision &&
              co.year === this.configuration.year
          ).length > 0
        ) {
          this.machineForm.controls['revision'].setErrors({notUnique: true});
          this.machineForm.controls['year'].setErrors({notUnique: true});
          this.configuration['notUnique'] = true;
          this.machineForm.control.setErrors({notUnique: true});
          this.machineForm.control.markAsDirty();
        } else {
          this.machineForm.controls['revision'].setErrors(null);
          this.machineForm.controls['year'].setErrors(null);
          this.configuration['notUnique'] = false;
          this.machineForm.control.markAsDirty();
        }
        this._loadingService.resolve('validateConfigurationData');
      }
    }
  }

  uploadFile(event: File | FileList) {
    const p: ParamsCheck = new ParamsCheck();
    p.installedPower = false;
    p.soundEmissions = false;
    p.water = false;
    p.powerConsumption = false;
    p.compressedAir = false;
    p.steam = false;
    p.naturalGas = false;
    p.auxiliaires = false;
    p.bleaches = false;
    p.buildersSoftenings = false;
    p.tensidesSurfactants = false;
    p.inorganic = false;
    p.organic = false;
    p.salt = false;
    p.customProcessInput = false;

    if (event instanceof File) {
      const ext = event.name.substring(event.name.lastIndexOf('.'));
      if (ext.toLowerCase() !== '.pdf') {
        this.snackService.notifyErr('Only PDF Files are allowed!');
      } else {
        this.uploadFiles.push({
          file: event,
          params: Object.keys(p)
        });
      }
    } else {

      for (let i = 0; i < event.length; i++) {
        const file = event[i];
        const ext = file.name.substring(file.name.lastIndexOf('.'));
        if (ext.toLowerCase() !== '.pdf') {
          this.snackService.notifyErr('Only PDF Files are allowed!');
        } else {
          this.uploadFiles.push({
            file: file,
            params: Object.keys(p)
          });
        }
      }
    }
  }

  prepareConfiguration(): void {
    this.configuration.creationDate = new Date();
    this.configuration.params = new Param();
    this.configuration.params.chemicalsProcess = this.chemicalsProcess;
    this.configuration.params.consumption = this.consumption;
    this.configuration.params.customProssesInput = this.customProssesInputs;
    this.configuration.params.processDescriptor = this.processDescriptor;
    this.configuration.params.washingAgents = this.washingAgent;

    this.configuration.installations = [];
  }

  validatePDFFile(event: File, obj: any) {
    const ext = event.name.substring(event.name.lastIndexOf('.'));
    if (ext.toLowerCase() !== '.pdf') {
      this.istruzioniOperativeFile = null;
      this.processDescriptorForm.controls['istruzioniOperativeFile'].setErrors({
        incorrect: true
      });
      obj.valid = false;
      this.snackService.notifyErr('Only PDF Files are allowed!');
    } else {
      this.istruzioniOperativeFile = event;
      obj.valid = true;
    }
  }

  completeConfiguration(obs: Observable<any>) {
    obs.pipe(mergeMap(m => {
      this.machine = m;
      return this.machineService.buildTarga(
        m.id,
        this.configuration.revision,
        this.configuration.year,
        this.installation
      );
    }))
      .subscribe(
        i => {
          this.configuration.installations.push(i);
          this.installation = i;
        },
        err => console.log(err),
        () => {
          // :id/green-label/:labelId
          this._loadingService.resolve('label.generating');
          this.router.navigate([
            'machines',
            this.machine.id,
            'green-label',
            this.installation.targaCode
          ]);
        }
      );
  }

  saveNewMachine() {
    this._loadingService.register('label.generating');
    this.machine.creationDate = new Date();
    this.machine.configuration = [];
    this.prepareConfiguration();
    const obs$ = this.machineService
      .save(this.machine, this.configuration, this.uploadFiles, this.istruzioniOperativeFile);
    this.completeConfiguration(obs$);
  }
}

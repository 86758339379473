<div layout-gt-sm="row" tdMediaToggle="gt-xs" [mediaClasses]="['push-sm']">
  <div flex-gt-sm>
    <mat-card tdMediaToggle="gt-xs" [mediaClasses]="['push']">
      <mat-card-title>{{action | uppercase}} Event</mat-card-title>
      <mat-divider></mat-divider>
      <mat-card-content class="push-bottom-none">
        <form>
          <div layout="column" layout-margin>
            <mat-form-field flex="100" floatPlaceholder="auto">
              <input matInput name="title" type="text" [(ngModel)]="event.title" required placeholder="Title">
            </mat-form-field>
            <mat-form-field flex="100" floatPlaceholder="auto">
              <textarea matInput matTextareaAutosize [(ngModel)]="event.description" name="description" required placeholder="Description"></textarea>
            </mat-form-field>
            <div flex="50">
              <mat-form-field flex="50">
                <input matInput required name="start" [(ngModel)]="event.start" [matDatepicker]="pickerStart" placeholder="Start date">
                <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
              </mat-form-field>
              <mat-datepicker #pickerStart></mat-datepicker>
              <mat-form-field flex="20">
                <mat-select  [(ngModel)]="startTime" name="startTime" required placeholder="Start time">
                  <mat-option *ngFor="let t of times" [value]="t">
                    {{t}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div flex="50">
              <mat-form-field flex="50">
                <input matInput name="end" [(ngModel)]="event.end" [matDatepicker]="pickerEnd" placeholder="End date">
                <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
              </mat-form-field>
              <mat-datepicker #pickerEnd></mat-datepicker>
              <mat-form-field flex="20">
                <mat-select  [(ngModel)]="endTime" name="endTime" required placeholder="End time">
                  <mat-option *ngFor="let t of times" [value]="t">
                    {{t}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </form>
      </mat-card-content>
      <mat-card-actions layout="row" layout-align="end center">
        <button mat-button color="accent" class="text-upper" (click)="save()">Save</button>
        <button *ngIf="action=='edit'" mat-button color="accent" class="text-upper" (click)="delete()">Delete</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

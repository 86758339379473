import { Injectable } from '@angular/core';
import { BaseService } from 'app/services/base-service';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import {
  Machine,
  Configuration,
  Files,
  Installation
} from 'app/models/machine';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class MachineService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  get(id: string): Observable<Machine> {
    const pageUrl: string = environment.api_endpoint + 'machines/' + id;
    return this.http
      .get<Machine>(pageUrl)
      ;
  }
  getAllMachine(): Observable<Machine[]> {
    const pageUrl: string = environment.api_endpoint + 'machines/';
    return this.http
      .get<Machine[]>(pageUrl)
      ;
  }

  getAllMachineByCompany(companyId: string): Observable<Machine[]> {
    const pageUrl: string =
      environment.api_endpoint + 'machines/company/' + companyId;
    return this.http
      .get<Machine[]>(pageUrl)
      ;
  }

  deleteMachine(id: string) {
    const pageUrl: string = environment.api_endpoint + 'machines/' + id;
    return this.http
      .delete<Machine>(pageUrl)
      ;
  }

  deleteConfiguration(machineId: string, revision: string, year: number) {
    const pageUrl: string =
      environment.api_endpoint +
      'machines/' +
      machineId +
      '/' +
      encodeURI(revision) +
      '/' +
      year;
    return this.http
      .delete<Machine>(pageUrl)
      ;
  }

  // saveConfiguration(machine: Machine, configuration: Configuration,
  // files: { file: File, params: string[] }[], istruzioniOperativeFile: any): Observable<Machine> {
  saveConfiguration(
    machine: Machine,
    configuration: Configuration,
    files: Files[],
    istruzioniOperativeFile: any
  ): Observable<Machine> {
    const form = this.createFormConfiguration(configuration, files, istruzioniOperativeFile);
    const pageUrl: string =
      environment.api_endpoint + 'machines/' + machine.id + '/configuration';
    return this.http
      .post<Machine>(pageUrl, form)
      ;
  }

  private createFormConfiguration(configuration: Configuration, files: Files[], istruzioniOperativeFile: any) {
    const form = new FormData();
    configuration.files = [];
    files.forEach(f => {
      const _f: Files = new Files();
      _f.path = f.file.name;
      _f.params = [];
      for (const p in f.params) {
        if (f.params[p]) {
          _f.params.push(p);
        }
      }

      configuration.files.push(_f);
      form.append('files', f.file, f.file.name);
    });

    form.append(
      'istruzioniOperative',
      istruzioniOperativeFile,
      istruzioniOperativeFile.name
    );

    const c: Blob = this.buildBlob(configuration);
    form.append('configuration', c);
    return form;
  }

  cloneConfiguration(
    machine: Machine,
    rev: string,
    year: number,
    configuration: Configuration,
    files: Files[],
    istruzioniOperativeFile: any
  ): Observable<Machine> {
    console.log(
      'Cloning: ' +
        machine.id +
        ' - rev: ' +
        rev +
        '.' +
        year +
        ' - to rev: ' +
        configuration.revision +
        '.' +
        configuration.year
    );
    const form = new FormData();
    configuration.files = [];
    files.forEach(f => {
      const _f: Files = new Files();
      _f.params = [];
      for (const p in f.params) {
        if (f.params[p]) {
          _f.params.push(p);
        }
      }
      if (f.file) {
        _f.path = f.file.name;
        form.append('files', f.file, f.file.name);
      } else {
        _f.path = f.path;
      }
      configuration.files.push(_f);
    });

    if (istruzioniOperativeFile) {
      form.append(
        'istruzioniOperative',
        istruzioniOperativeFile,
        istruzioniOperativeFile.name
      );
    }
    const c: Blob = new Blob([JSON.stringify(configuration)], {
      type: 'application/json'
    });
    form.append('configuration', c);
    const pageUrl: string =
      environment.api_endpoint +
      'machines/' +
      machine.id +
      '/' +
      rev +
      '/' +
      year +
      '/clone';
    return this.http
      .post<Machine>(pageUrl, form)
      ;
  }

  save(
    machine: Machine,
    configuration: Configuration,
    files: Files[],
    istruzioniOperativeFile: any
  ): Observable<Machine> {
    const form = this.createFormConfiguration(configuration, files, istruzioniOperativeFile);

    const b = this.buildBlob(machine);
    form.append('machine', b);
    const pageUrl: string = environment.api_endpoint + 'machines/';
    return this.http.post<Machine>(pageUrl, form);
  }

  private buildBlob(element: any) {
    return new Blob([JSON.stringify(element)], {
      type: 'application/json'
    });
  }

  update(machine: Machine): Observable<Machine> {
    const pageUrl: string =
      environment.api_endpoint + 'machines/' + machine.id + '/edit/';
    return this.http
      .put<Machine>(pageUrl, machine)
      ;
  }

  buildTarga(
    machineId: string,
    revision: string,
    year: number,
    installation: Installation
  ): Observable<Installation> {
    const pageUrl: string =
      environment.api_endpoint +
      'machines/green-label/' +
      machineId +
      '/' +
      encodeURI(revision) +
      '/' +
      year;
    return this.http
      .post<Installation>(pageUrl, installation)
      ;
  }

  findTarga(machineId: string, labelId: string): Observable<Installation> {
    const pageUrl: string =
      environment.api_endpoint +
      'machines/' +
      machineId +
      '/green-label/' +
      labelId;
    return this.http
      .get<Installation>(pageUrl)
      ;
  }

  validateRevision(configuration: Configuration): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
}
